import React from 'react';
import './StartImport.css';
import { Link } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';
import { utils, writeFile } from 'xlsx'; // Correct import for XLSX

const StartImport = () => {
  const { t } = useTranslation();

  const sampleContactFile = [
    {
      FirstName: "Sandeep",
      LastName: "Mishra",
      Email: "sandeep.mishra@megthink.com",
      DOB: "15-09-1994",
      Gender: "Male",
      Company: "Megthink",
      AceeptMarketing: "Y",
      Phone: "8286318699",
      Address1: "Sohna",
      Address2: "Sohna",
      City: "Gurugram",
      Country: "India",
      CountryCode: "IN",
      ZipCode: "120018",
      Province: "Haryana",
      Province_code: "HR",
    },
    {
      FirstName: "Shobhit",
      LastName: "Kumar",
      Email: "shobhit.kumar@megthink.com",
      DOB: "01-01-2001",
      Gender: "Male",
      Company: "Megthink",
      AceeptMarketing: "Y",
      Phone: "8989898989",
      Address1: "Sohna",
      Address2: "Sohna",
      City: "Gurugram",
      Country: "India",
      CountryCode: "IN",
      ZipCode: "120018",
      Province: "Haryana",
      Province_code: "HR",
    },
  ]

  // H A N D L E   S A M P L E   C S V   F I L E   D O W N L O A D
  const handleContactsFileDownload = () => {
    const ws = utils.json_to_sheet(sampleContactFile);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'SampleContactsFileZumi');
    writeFile(wb, 'SampleContactsFileZumi.csv', { bookType: 'csv' });
  };


  return (
    <div className='startImportMain_cont'>
      <Breadcrumbs />
      <div className="importContactHeading_cont">
        <h3 style={{ textAlign: "center" }}>{t('startImportMainHead')}</h3>
      </div>
      <div className="startImportMain">
        <div className="importOneMain">
          <Link to='/home/contacts/import-contact/start-import/submit-contact' className='submitContactLink'>
            <div className="importCardOne_cont startImportCard" >
              <div className="importImgCont">
                <img className='importContactImg' src="//static.hsappstatic.net/ui-images/static-2.677/optimized/import-file.svg" alt="" />
              </div>
              <h4 className='importContactH5'>{t('startImportCardHead')}</h4>
              <p className='importContactPara'>{t('startImportCardPara')}</p>

            </div></Link>
        </div>
      </div>

      <div className="startImportContactHelp_cont">
        <div className="startImportHelpCont1">
          <h4>{t('startHelpHead')}</h4>
        </div>
        <div className="startImportHelpCont2">
          <div className="helpAnchorCont">
            <Link className='helpAnchor' to="/home/contacts/import-contact/start-import/import-guide">
              {t('startHelpLink1')}
              <LaunchIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
            </Link>
          </div>
          <div className="helpAnchorCont">
            <Link className='helpAnchor' onClick={handleContactsFileDownload}>
              {t('startHelpLink2')}
              <LaunchIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
            </Link>
          </div>
          <div className="helpAnchorCont">
            <Link className='helpAnchor' to="/home/contacts/import-contact/start-import/FAQs">{t('startHelpLink3')}</Link>
          </div>
        </div>

      </div>

    </div>
  )
}

export default StartImport;