import React, { useState, useEffect, useRef, useContext } from 'react'
import './Toolbar.css'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';

import { BrandLogoContext } from '../../Context/BrandLogoContext';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import dayjs from 'dayjs';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../../src/assets/user-profile.jpg';

import { routeConfig } from './routeconfig';
import { useLocation, Link } from 'react-router-dom';
import BuyCredits from '../BuyCredits/BuyCredits';
import { CreditsContext } from '../../Context/CreditsContext';
import { RoughStartDateContext } from '../../Context/RoughStartDateContext';
import { RoughEndDateContext } from '../../Context/RoughEndDateContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "15px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const Toolbar = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const { setRoughStartDateTime } = useContext(RoughStartDateContext);
  const { setRoughEndDateTime } = useContext(RoughEndDateContext);
  const authToken = localStorage.getItem('authToken');
  const currentPath = location.pathname;
  const pageName = routeConfig[currentPath]?.name || 'Not Found';

  const { brandLogo } = useContext(BrandLogoContext);
  const { credits } = useContext(CreditsContext);

  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isWalletDropdownOpen, setWalletDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setNotificationDropdownOpen] = useState(false);

  const walletDropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);


  const [openBuyCreditsModal, setOpenBuyCreditsModal] = React.useState(false);
  const handleOpenBuyCreditsModal = () => {
    setOpenBuyCreditsModal(true)
    toggleWalletDropdown();
  };
  const handleCloseBuyCreditsModal = () => setOpenBuyCreditsModal(false);

  const navigate = useNavigate();
  const [langName, setLangName] = useState('');


  const handleLangChange = (e) => {
    setLangName(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  // H A N D L E   L O G O U T
  const handleLogout = () => {
    if (authToken) {
      setRoughStartDateTime(dayjs().subtract(7, 'day'))
      setRoughEndDateTime(dayjs().subtract(1, 'day'))
      setTimeout(() => {
        localStorage.clear();
        navigate('/');
      }, 500);
    }
  }

  const toggleWalletDropdown = () => {
    setWalletDropdownOpen(!isWalletDropdownOpen);
  };

  const toggleNotificationDropdown = () => {
    setNotificationDropdownOpen(!isNotificationDropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
      setProfileDropdownOpen(false);
    }
    if (walletDropdownRef.current && !walletDropdownRef.current.contains(event.target)) {
      setWalletDropdownOpen(false);
    }
    if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
      setNotificationDropdownOpen(false);
    }
  };


  useEffect(() => {
    // Add event listener to document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="toolbarMain_cont">
      <div className="toolbarBrandMain_cont centerElm">
        <div className="toolBrandImg_cont">
          {brandLogo ? <img src={brandLogo} alt="brandImg" /> : <h1 className='toolbarBrandName'>ZUMI</h1>}
        </div>
      </div>
      <div className="toolsMain_cont">
        {/* <div className="localesMain_cont">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              name='locales'
              value={langName}
              onChange={handleLangChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected === '') {
                  return "English";
                }
                if (selected === 'en') {
                  return "English";
                }
                if (selected === "fr") {
                  return "French";
                }
                if (selected === "hi") {
                  return "Hindi";
                }
                return selected;
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">French</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
            </Select>
          </FormControl>
        </div> */}

        <div className="twallet_cont centerElm" onClick={toggleWalletDropdown}>
          <AccountBalanceWalletIcon className='tnotification_icon' style={{ color: "gray", height: 25, width: 25 }} />
          {isWalletDropdownOpen && (
            <div className="walletPopup_cont" ref={walletDropdownRef}>
              <p style={{ marginBottom: "30px" }}>Use your credit balance to engage in different kind of conversations with your customers</p>
              <p> <span style={{ color: "gray" }}>Free Service Conversations Left:</span> 1000</p>
              <p> <span style={{ color: "gray" }}>Credit Balance:</span> ${credits.toLocaleString('en-AU')} AUD</p>
              <div className="walletPopupBtnRow">
                <button className='btnNotFill' style={{ marginRight: "10px" }}>Pricing Overview</button>
                <button className="btnFill" onClick={handleOpenBuyCreditsModal}>Buy Credits</button>
              </div>
            </div>
          )}

          {/* M O D A L    F O R    B U Y   C R E D I T S */}
          <Modal
            className='templateStyleMobile'
            open={openBuyCreditsModal}
            onClose={handleCloseBuyCreditsModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <BuyCredits closeModal={handleCloseBuyCreditsModal}></BuyCredits>
            </Box>
          </Modal>
        </div>

        {/* <div className="tnotification_cont centerElm" onClick={toggleNotificationDropdown}>
          <NotificationsIcon className='tnotification_icon' style={{ color: "gray", height: 25, width: 25 }} />

          {isNotificationDropdownOpen && (
            <div className="notiPopup_cont centerElm" ref={notificationDropdownRef}>
              <p>Empty</p>
            </div>
          )}
        </div> */}

        <div className="tsignIn_cont centerElm" onClick={toggleProfileDropdown}>
          <img src={UserProfile} alt="profileIcon" className='userProfileImg' />

          {isProfileDropdownOpen && (
            <div className="dropdownMenu" ref={profileDropdownRef}>
              <Link to="/home/profile" className='tDropdownMenuLink'>
                <div className="profileIcon_cont"><PersonIcon /></div>
                <div className="tDropdownMenuName">Profile</div>
              </Link>
              <Link to="/home/my-account" className='tDropdownMenuLink'>
                <div className="profileIcon_cont"><AccountBoxIcon /></div>
                <div className="tDropdownMenuName">My Account</div>
              </Link>
              <hr className='tDropdownHR' />
              <div to="" className='tDropdownMenuLink logoutLinkCont' onClick={handleLogout}>
                <div className="profileIcon_cont"><LogoutIcon /></div>
                <div className="tDropdownMenuName">Logout</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Toolbar;