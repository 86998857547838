import React from 'react';
import './NoMarketplace.css';
import { Link } from 'react-router-dom';
import NoMarketplaceSvg from '../../../assets/noMarketplace.svg';

const NoMarketplace = () => {
  return (
    <div className="noMarketplace_cont">
      <div className="noMarketplaceHead_cont">
        <h2>No Marketplace Found</h2>
      </div>
      <div className="noMarketImg_cont">
        <img src={NoMarketplaceSvg} alt="No marketplace found" />
      </div>
      <p>
        Your dashboard is awaiting data from an integrated marketplace. To
        fully activate and customize your experience, please connect a
        marketplace. Once integrated, you’ll be able to track performance,
        analyze sales, and manage your business operations effectively, all
        in one place.
      </p>
      <div className="integrateNewBtn_cont">
        <Link className="btnFill" to="/home/new-marketplace">
          Add New Marketplace
        </Link>
      </div>
    </div>
  )
}

export default NoMarketplace;