import React, { useState, useContext, useEffect } from 'react'
import './Segment.css'

import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';

import DeleteIcon from '@mui/icons-material/Delete';

import CreateSegment from './CreateSegment/CreateSegment';
import ViewRecipients from '../../WhatsApp/CreateTemplate/MessageHistory/ViewRecipients/ViewRecipients';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';

import DataTable from 'react-data-table-component';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';
import { SegmentContext } from '../../../Context/SegmentContext';

import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import { Link } from 'react-router-dom';
import EditSegment from './EditSegment/EditSegment';


const createSegmentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 540,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const recipientStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 480,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Segment = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');

  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [segmentData, setSegmentData] = useState([]);
  const [updateSegments, setUpdateSegments] = useState();
  const [segmentContacts, setSegmentContacts] = useState([])
  const [segmentLoader, setSegmentLoader] = useState(true);

  const segment_URL = `${baseUrl}/zumi/api/getsegment`;
  const delete_URL = `${baseUrl}/segment/delete`;
  const [deleteId, setDeleteId] = useState();

  const [sortSegmentDirection, setSortSegmentDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  const [newSegmentOpen, setNewSegmentOpen] = React.useState(false);
  const handleNewSegmentOpen = () => setNewSegmentOpen(true);
  const handleNewSegmentClose = () => setNewSegmentOpen(false);

  const [editSegmentId, setEditSegmentId] = useState();
  const [editSegmentOpen, setEditSegmentOpen] = React.useState(false);
  const handleEditSegmentOpen = () => setEditSegmentOpen(true);
  const handleEditSegmentClose = () => setEditSegmentOpen(false);


  const [fetchRecipientsId, setFetchRecipientsId] = useState();
  const [recipientsOpen, setRecipientsOpen] = React.useState(false);
  const handleRecipientsOpen = () => setRecipientsOpen(true);
  const handleRecipientsClose = () => setRecipientsOpen(false);

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  const columns = [
    {
      name: t('segName'),
      // selector: row => row.segmentName,
      selector: row => <div className="sentSegName" onClick={() => fetchRecipients(row.segmentId)}>{row.segmentName}</div>,
      width: "200px"
    },
    {
      name: t('description'),
      selector: row => row.description
    },
    {
      name: t('createdDate'),
      // selector: row => row.created_date,
      selector: row => {
        const date = new Date(row.created_date);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
      width: "250px"
    },
    {
      name: t('actions'),
      selector: row => row.actions = <div className="actionsButtonCont">
        <div className="editBtn_cont" onClick={() => { editSegment(row.segmentId) }}>
          <EditIcon className='editIcon' style={{ height: '20px', width: '20px' }}></EditIcon>
        </div>
        <div className="deleteBtn_cont" onClick={() => { deleteItem(row.segmentId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
      </div>,
      width: "150px"
    }

  ];

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error)
        } finally {
          setSegmentLoader(false);
        }

      };
      fetchData();
    }
  }, [updateSegments]);

  const updateSegmentsData = (value) => {
    setUpdateSegments(value);
  }

  // S E T T I N G   S E G M E N T S   C O N T E X T

  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentData, setSegmentArray]);


  // S O R T   S E G M E N T S    D A T A 

  const handleSegmentSort = (column, sortSegmentDirection) => {
    setSortSegmentDirection(sortSegmentDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // S O R T   A N D   S E A R C H   S E G M E N T   D A T A 
  const filteredAndSortedSegmentData = React.useMemo(() => {
    const filteredData = segmentData.filter(item =>
      typeof item.segmentName === 'string' &&
      item.segmentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortSegmentDirection === 'asc') {
        return new Date(a.created_date) - new Date(b.created_date);
      } else {
        return new Date(b.created_date) - new Date(a.created_date);
      }
    });
  }, [segmentData, sortSegmentDirection, searchQuery]);

  // F E T C H    R E C I P I E N T
  const fetchRecipients = (id) => {
    setFetchRecipientsId(id);
    handleRecipientsOpen();
  }

  // E D I T   S E G M E N T
  const editSegment = (item) => {
    setEditSegmentId(item);
    handleEditSegmentOpen();
  }

  // D E L E T E  S E G M E N T
  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${delete_URL}?id=${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteId(id);
      setSegmentData(segmentData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };


  return (
    <div className='segmentMain_cont'>
      {/* <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            className='whatToolsLink'
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> Broadcast
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> Message History
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> Connect Account
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> Knowledge Base
          </Link>
        </div>
      </div> */}
      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search segments'
              onChange={handleSearch}
            />
            <Select
              size="small"
              className='sortTemplateInput'
              labelId="demo-simple-select-label"
              id="demo-select-small"
              value={sortSegmentDirection}
              onChange={e => handleSegmentSort(null, e.target.value)}
            >{
                sortItem.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                  )
                })
              }
            </Select>
          </div>
          <div className="templateRowBtn_cont">
            <button className='btnFill' onClick={handleNewSegmentOpen}>
              {t('createSegment')}
            </button>

            {/* M O D A L   F O R   C R E A T I N G    S E G M E N T */}
            <Modal
              className='templateStyleMobile'
              open={newSegmentOpen}
              onClose={handleNewSegmentClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={createSegmentStyle}>
                <CreateSegment updateSegmentsData={updateSegmentsData} closeModal={handleNewSegmentClose} />
              </Box>
            </Modal>

            {/* M O D A L   F O R    E D I T I N G   S E G M E N T */}
            <Modal
              className='templateStyleMobile'
              open={editSegmentOpen}
              onClose={handleEditSegmentClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={createSegmentStyle}>
                {/* <EditSegment updateSegmentsData={updateSegmentsData} segToBeUpdated={segToBeUpdated} closeModal={handleEditSegmentClose} /> */}
                <EditSegment updateSegmentsData={updateSegmentsData} editSegmentId={editSegmentId} closeModal={handleEditSegmentClose} />
              </Box>
            </Modal>

            {/* M O D A L    T O   V I E W    R E C I P I E N T S */}
            <Modal
              className='templateStyleMobile'
              open={recipientsOpen}
              onClose={handleRecipientsOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={recipientStyle}>
                <ViewRecipients fetchRecipientsId={fetchRecipientsId} closeModal={handleRecipientsClose} />
              </Box>
            </Modal>
          </div>

        </div>
      </div>


      <div className="presentSegmentMain_cont">
        <div className="presentSegments_cont">

          {segmentLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={tableStyles}
              data={filteredAndSortedSegmentData}
              defaultSortField="created_date"
              defaultSortAsc={sortSegmentDirection === 'asc'}
              pagination
            >
            </DataTable>
          )}
        </div>
      </div>
    </div>
  )
}

export default Segment;