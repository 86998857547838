import React, { useState } from 'react';
import './TodaysDealsPublic.css';
import WelcomeNavbar from '../WelcomeNavbar/WelcomeNavbar';


import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


import DataTable from 'react-data-table-component';
import tableStyles from '../../Sales/Revenue/TableStyles';
import { useTranslation } from 'react-i18next';
import LoginRequired from '../LoginRequired/LoginRequired';


const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 150,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const todaysDeals = [
  {
    id: 1,
    productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
    productPrice: "$ 20",
    quantity: "18",
    resellerName: "Zoe Marshall",
    todaysDeals: "1",
    priceDrop: "1",
    priceUp: "0",
    justLaunched: "1",

  },
  {
    id: 2,
    productInfo: "Mophie Snap Adapter Ring Kit, Black (Pack of 2)",
    productPrice: "$ 28",
    quantity: "15",
    resellerName: "Riley Anderson",
    todaysDeals: "1",
    priceDrop: "0",
    priceUp: "1",
    justLaunched: "0",
  },
  {
    id: 3,
    productInfo: "Pureflex+ MagSafe Compatible Phone Case for iPhone 16 Pro - Clear/Clear",
    productPrice: "$ 35",
    quantity: "20",
    resellerName: "Riley Anderson",
    todaysDeals: "1",
    priceDrop: "0",
    priceUp: "0",
    justLaunched: "1",
  },
  {
    id: 4,
    productInfo: "BodyGuardz Ace Pro Magsafe Case for iPhone 14+ Plus 6.7-Inch, White",
    productPrice: "$ 19",
    quantity: "30",
    resellerName: "Riley Anderson",
    todaysDeals: "1",
    priceDrop: "0",
    priceUp: "1",
    justLaunched: "1",
  },
  {
    id: 4,
    productInfo: "Maxguard Universal Magnetic Magsafe Car Air",
    productPrice: "$ 42",
    quantity: "8",
    resellerName: "Riley Anderson",
    todaysDeals: "1",
    priceDrop: "1",
    priceUp: "0",
    justLaunched: "1",
  },
]

const TodaysDealsPublic = () => {
  const { t } = useTranslation();

  const [dealsFilter, setDealsFilter] = useState({
    priceRange: "priceRange",
    conditions: "all",
    sortBy: "sortByPrice"
  })


  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setDealsFilter({ ...dealsFilter, [e.target.name]: e.target.value });
  }

  const columns = [
    {
      name: t('productInfo'),
      selector: row => row.productInfo,
      width: "450px"
    },
    {
      name: t(''),
      selector: row => <div className="todaysDealsTableIcon_cont">
        {row.todaysDeals === "1" ? <Tooltip
          arrow
          title="Today's Deals"
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <WhatshotIcon />
        </Tooltip> : ""}

        {row.priceDrop === "1" ? <Tooltip
          arrow
          title="Price Drop"
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <TrendingDownIcon />
        </Tooltip> : ""}
        {row.priceUp === "1" ? <Tooltip
          arrow
          title="Price Up"
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <TrendingUpIcon />
        </Tooltip> : ""}

        {row.justLaunched === "1" ? <Tooltip
          arrow
          title="Just Launched"
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <RocketLaunchIcon />
        </Tooltip> : ""}
      </div>,
      width: "180px"
    },
    {
      name: t('productPrice'),
      selector: row => row.productPrice,
      width: "140px"
    },
    {
      name: t('quantity'),
      selector: row => row.quantity,
      width: "120px"
    },
    {
      name: t('resellerName'),
      selector: row => row.resellerName,
      width: "150px"
    },
    {
      name: t(''),
      selector: row => <div className="dashLiveBtn_cont">
        <button className='btnFill dashLiveBtn' onClick={handleOpen}>Make Offer</button>
        <button className='btnFill dashLiveBtn' onClick={handleOpen} style={{ marginLeft: "8px" }}>Buy Now</button>
      </div>,
    },
  ];
  return (
    <div className='publicDealsMain'>
      <WelcomeNavbar />

      <div className="publicDealsSubCont" style={{ marginTop: "12vh" }}>
        <div className="todaysDeals_cont1" >
          <p>Today's Deals</p>
          <div className="todaysDealCont1Sub">

            <FormControl sx={{ m: 1, minWidth: "20%", borderRadius: "10px" }} size="small">
              <TextField
                sx={textFieldStyle}
                label="Search"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
              <Select
                sx={{ borderRadius: "10px" }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="priceRange"
                value={dealsFilter.priceRange}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="priceRange">Price Range</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
              <Select
                sx={{ borderRadius: "10px" }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="conditions"
                value={dealsFilter.conditions}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="all">All Conditions</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
              <Select
                sx={{ borderRadius: "10px" }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="sortBy"
                value={dealsFilter.sortBy}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="sortByPrice">Sort by Price</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>

            <div className="dealsInStockCont">
              <p>All</p>
              <FormControl>
                <Switch
                  // checked={checked}
                  // onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />

              </FormControl>
              <p>In Stock</p>
            </div>
          </div>
        </div>

        {/* M O D A L   F O R   L O G I N    R E Q U I R E D */}
        <Modal
          className='templateStyleMobile'
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <LoginRequired closeModal={handleClose}></LoginRequired>
          </Box>
        </Modal>

        <h3 style={{ marginTop: "20px" }}>Today's top deals for you</h3>
        <div className='tableMain_cont'>
          <div className='table_cont'>
            <DataTable
              columns={columns}
              customStyles={tableStyles}
              // noDataComponent={<NoDataComponent />}
              data={todaysDeals}
              // defaultSortField="created_DateTime"
              // defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TodaysDealsPublic;