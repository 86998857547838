import React, { createContext, useState } from 'react';

export const RevenueChartContext = createContext();

export const RevenueChartProvider = ({ children }) => {
  const [revenueChartArray, setRevenueChartArray] = useState([]);

  return (
    <RevenueChartContext.Provider value={{ revenueChartArray, setRevenueChartArray }}>
      {children}
    </RevenueChartContext.Provider>
  );
};
