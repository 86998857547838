import React, { useEffect } from 'react';
import './MakeOffer.css';

import { TextField } from '@mui/material';


const MakeOffer = ({ makeOfferElm, closeModal }) => {

  useEffect(() => {
    if (makeOfferElm) {
      console.log("Make offer element: ", makeOfferElm);
    }
  }, [makeOfferElm]);
  return (
    <div className='makeOfferMain'>
      <div className="makeOfferSub_cont">
        <div className="buyerCloseModal_cont">
          <div role="button" aria-label="Close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </div>
        </div>

        <div className="offerDetailsMain_cont">
          <div className="offerProductName_cont">
            <p>{makeOfferElm.brand}</p>
            <p className='offerPrice'>$ {makeOfferElm.price}</p>
          </div>

          <div className="offerFields_cont">
            <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
              <label htmlFor="contactPerson" className="welcomeFormLabel">Quantity  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                type='text'
                name="messageContent"
                id="outlined-basic"
                placeholder="Enter quantity"
                variant="outlined"
              // value={createBroadcastData.messageContent}
              // onChange={handleChange}
              />
            </div>

            <div className="createBroadcastInput_cont">
              <label htmlFor="contactPerson" className="welcomeFormLabel">Your Price  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                type='text'
                name="messageContent"
                id="outlined-basic"
                placeholder="Enter price"
                variant="outlined"
              // value={createBroadcastData.messageContent}
              // onChange={handleChange}
              />
            </div>

          </div>

          <hr style={{
            width: "100%", borderTop: "none", marginTop: "20px", textAlign: "center"
          }} />
          <div className="makeOfferBtn_cont">
            <button className='btnFill'>Make Offer</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default MakeOffer;