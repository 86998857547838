import React from 'react';
import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageNotFound from './components/PageNotFound/PageNotFound';

import Profile from './components/Profile/Profile';
import MyAccount from './components/MyAccount/MyAccount';
import Welcome from './components/Welcome/Welcome';
import Main from './components/Main/Main';
import Signup from './components/Welcome/Signup/Signup';
import Login from './components/Welcome/Login/Login';
import ForgotPass from './components/Welcome/ForgotPass/ForgotPass';
import TodaysDealsPublic from './components/Welcome/TodaysDealsPublic/TodaysDealsPublic';

import Dashboard from './components/Dashboard/Dashboard';
import Sales from './components/Sales/Sales';
import Products from './components/Products/Products';
import Reseller from './components/Reseller/Reseller';
import Buyer from './components/Buyer/Buyer';
import Consumer from './components/Consumer/Consumer';
import Shipping from './components/Shipping/Shipping';
import Whatsapp from './components/WhatsApp/Whatsapp';
import Sms from './components/Sms/Sms';
import Audience from './components/Audience/Audience';
import Dataset from './components/Audience/Datasets/Dataset';
import Integration from './components/Integration/Integration';
import Users from './components/Users/Users';

import ConnectAccount from './components/WhatsApp/ConnectAccount/ConnectAccount';
import ConnectStep1 from './components/WhatsApp/ConnectAccount/ConnectStep1/ConnectStep1';
import ConnectStep2 from './components/WhatsApp/ConnectAccount/ConnectStep2/ConnectStep2';
import AfterBroadcast from './components/WhatsApp/CreateTemplate/MessageHistory/MessageHistory';

import ImportContact from './components/Consumer/ImportContact/ImportContact';
import Segment from './components/Consumer/Segment/Segment';

import StartImport from './components/Consumer/ImportContact/StartImport/StartImport';
import SubmitContact from './components/Consumer/ImportContact/StartImport/SubmitContact/SubmitContact';

import HelpGetStart from './components/Consumer/ImportContact/HelpGetStart/HelpGetStart';
import ImportGuide from './components/Consumer/ImportContact/ImportGuide/ImportGuide';
import ContactFAQ from './components/Consumer/ImportContact/ContactFAQ/ContactFAQ';

import Revenue from './components/Sales/Revenue/Revenue';
import Profit from './components/Sales/Profit/Profit';
import BuyBox from './components/Sales/BuyBox/BuyBox';
import Orders from './components/Sales/Orders/Orders';

import Catalogs from './components/Products/Catalogs/Catalogs';
import Analytics from './components/Products/Analytics/Analytics';

import TodaysDeals from './components/Buyer/TodaysDeals/TodaysDeals';
import PriceDrop from './components/Buyer/PriceDrop/PriceDrop';
import NewLaunched from './components/Buyer/NewLaunched/NewLaunched';

import CreateBroadcast from './components/Reseller/CreateBroadcast/CreateBroadcast';
import ViewBroadcast from './components/Reseller/ViewBroadcast/ViewBroadcast';
import KnowledgeBased from './components/WhatsApp/KnowledgeBased/KnowledgeBased';
import Automation from './components/Automation/Automation';

import SearchProduct from './components/Reseller/SearchProduct/SearchProduct';
import ViewMarketplace from './components/Integration/ViewMarketplace/ViewMarketplace';
import NewMarketplace from './components/Integration/NewMarketplace/NewMarketplace';

import ProtectedRoute from './components/Toolbar/ProtectedRoute';
import AmazonAuthSuccess from './components/Integration/NewMarketplace/AmazoAuthSuccess/AmazonAuthSuccess';
import EbayAuthSuccess from './components/Integration/NewMarketplace/EbayAuthSuccess/EbayAuthSuccess';
import ShopifyAuthSuccess from './components/Integration/NewMarketplace/ShopifyAuthSuccess/ShopifyAuthSuccess';
import PaymentSuccess from './components/MyAccount/PaymentSuccess/PaymentSuccess';
import PaymentFailed from './components/MyAccount/PaymentFailed/PaymentFailed';
import Terms from './components/Terms/Terms';


function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/signin',
      element: <Login />,
    },
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPass />,
    },
    {
      path: '/todays-deals',
      element: <TodaysDealsPublic />,
    },
    {
      path: "/home",
      element: <Main />,
      children: [
        {
          path: '/home/dashboard',
          element: <ProtectedRoute element={<Dashboard />} requiredPrivilege="Dashboard" />,
        },
        {
          path: '/home/sales',
          element: <ProtectedRoute element={<Sales />} requiredPrivilege="Sales" />,
        },
        {
          path: '/home/products',
          element: <ProtectedRoute element={<Products />} requiredPrivilege="Products" />,
        },
        {
          path: '/home/reseller',
          element: <ProtectedRoute element={<Reseller />} requiredPrivilege="Reseller" />,
        },
        {
          path: '/home/buyer',
          element: <ProtectedRoute element={<Buyer />} requiredPrivilege="Deals" />,
        },
        {
          path: '/home/contacts',
          element: <ProtectedRoute element={<Consumer />} requiredPrivilege="Contacts" />,
        },
        {
          path: '/home/shipping',
          element: <ProtectedRoute element={<Shipping />} requiredPrivilege="Shipping" />,
        },
        {
          path: '/home/whatsapp',
          element: <ProtectedRoute element={<Whatsapp />} requiredPrivilege="Marketing" />,
        },
        {
          path: '/home/sms',
          element: <ProtectedRoute element={<Sms />} requiredPrivilege="Marketing" />,
        },
        {
          path: '/home/audience',
          element: <ProtectedRoute element={<Audience />} requiredPrivilege="Marketing" />,
        },
        {
          path: '/home/integration',
          element: <ProtectedRoute element={<Integration />} requiredPrivilege="Integration" />,
        },
        {
          path: '/home/users',
          element: <ProtectedRoute element={<Users />} requiredPrivilege="User Management" />,
        },

        //  R O U T E S   F O R   W H A T S A P P
        {
          path: '/home/whatsapp/message-history',
          element: <ProtectedRoute element={<AfterBroadcast />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account',
          element: <ProtectedRoute element={<ConnectAccount />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account/connect-step1',
          element: <ProtectedRoute element={<ConnectStep1 />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/connect-account/connect-step1/connect-step2',
          element: <ProtectedRoute element={<ConnectStep2 />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/knowledge',
          element: <ProtectedRoute element={<KnowledgeBased />} requiredPrivilege="WhatsApp" />,

        },
        {
          path: '/home/whatsapp/automations',
          element: <ProtectedRoute element={<Automation />} requiredPrivilege="WhatsApp" />,

        },

        //  R O U T E S   F O R   C O N T A C T
        {
          path: '/home/contacts/segment',
          element: <ProtectedRoute element={<Segment />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact',
          element: <ProtectedRoute element={<ImportContact />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import',
          element: <ProtectedRoute element={<StartImport />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/submit-contact',
          element: <ProtectedRoute element={<SubmitContact />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/help',
          element: <ProtectedRoute element={<HelpGetStart />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/import-guide',
          element: <ProtectedRoute element={<ImportGuide />} requiredPrivilege="Contacts" />,

        },
        {
          path: '/home/contacts/import-contact/start-import/FAQs',
          element: <ProtectedRoute element={<ContactFAQ />} requiredPrivilege="Contacts" />,

        },

        // R O U T E S   F O R   S A L E S
        {
          path: '/home/salesrevenue',
          element: <ProtectedRoute element={<Revenue />} requiredPrivilege="Sales" />,
        },
        {
          path: '/home/salesprofit',
          element: <ProtectedRoute element={<Profit />} requiredPrivilege="Sales" />,

        },
        {
          path: '/home/salesorders',
          element: <ProtectedRoute element={<Orders />} requiredPrivilege="Sales" />,

        },
        {
          path: '/home/salesbuybox',
          element: <ProtectedRoute element={<BuyBox />} requiredPrivilege="Sales" />,

        },

        // R O U T E S   F O R   P R O D U C T S 

        {
          path: '/home/product-catalogs',
          element: <ProtectedRoute element={<Catalogs />} requiredPrivilege="Products" />,

        },
        {
          path: '/home/price-analytics',
          element: <ProtectedRoute element={<Analytics />} requiredPrivilege="Products" />,

        },

        // R O U T E S   F O R   R E S E L L E R 
        {
          path: '/home/create-broadcast',
          element: <ProtectedRoute element={<CreateBroadcast />} requiredPrivilege="Reseller" />,

        },
        {
          path: '/home/broadcasts',
          element: <ProtectedRoute element={<ViewBroadcast />} requiredPrivilege="Reseller" />,

        },
        {
          path: '/home/search-product',
          element: <ProtectedRoute element={<SearchProduct />} requiredPrivilege="Reseller" />,
        },

        // R O U T E S   F O R   B U Y E R 

        {
          path: '/home/todays-deals',
          element: <ProtectedRoute element={<TodaysDeals />} requiredPrivilege="Deals" />,
        },
        {
          path: '/home/price-drop',
          element: <ProtectedRoute element={<PriceDrop />} requiredPrivilege="Deals" />,
        },
        {
          path: '/home/new-launched',
          element: <ProtectedRoute element={<NewLaunched />} requiredPrivilege="Deals" />,
        },


        // R O U T E S   F O R   I N T E G R A T I O N

        {
          path: '/home/view-marketplace',
          element: <ProtectedRoute element={<ViewMarketplace />} requiredPrivilege="Integration" />,

        },
        {
          path: '/home/new-marketplace',
          element: <ProtectedRoute element={<NewMarketplace />} requiredPrivilege="Integration" />,
        },
        {
          path: '/home/amazon-auth-success',
          element: <AmazonAuthSuccess />
        },
        {
          path: '/home/ebay-callback',
          element: <EbayAuthSuccess />
        },
        {
          path: '/home/shopify-auth-success',
          element: <ShopifyAuthSuccess />
        },

        // R O U T E S   F O R   T O O L B A R

        {
          path: '/home/profile',
          element: <Profile />,
        },
        {
          path: '/home/my-account',
          element: <MyAccount />,
        },

        // R O U T E S   F O R   P A Y M E N T S

        {
          path: '/home/payment-success',
          element: <PaymentSuccess />
        },
        {
          path: '/home/payment-failed',
          element: <PaymentFailed />
        },

        // R O U T E   F O R   T E R M S 

        {
          path: '/home/terms&conditions',
          element: <Terms />
        },

        // R O U T E    F O R    A U D I E N C E 

        {
          path: '/home/datasets',
          element: <Dataset />
        },
      ],

    },

    {
      path: '*',
      element: <PageNotFound />
    },
    {
      path: '/terms&conditions',
      element: <Terms />
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
