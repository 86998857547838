import React, { createContext, useState } from 'react';

export const AllowedPrivilegeContext = createContext();

export const AllowedPrivilegesProvider = ({ children }) => {
  const [allowedPrivileges, setAllowedPrivileges] = useState(true);

  return (
    <AllowedPrivilegeContext.Provider value={{ allowedPrivileges, setAllowedPrivileges }}>
      {children}
    </AllowedPrivilegeContext.Provider>
  );
};
