import React, { useState, useContext } from 'react'
import './Reseller.css';
import TemplateContext from '../../Context/TemplateContext';
const Reseller = () => {
  return (
    <div className='supplier_main'>
      <h1>Supplier</h1>
    </div>
  )
}

export default Reseller;