import React, { useContext } from 'react'
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { OrdersChartContext } from '../../../../Context/OrdersChartContext';

const OrdersChart = () => {
  const { ordersChartArray } = useContext(OrdersChartContext);

  const ordersData = {
    data: {
      labels: ordersChartArray.map((data) => data.date || "Label"),
      datasets: [
        {
          label: "Total Orders",
          data: ordersChartArray.map((data) => data.totalOrders),
          type: 'line',
          borderColor: 'rgb(14, 113, 195)',
          // backgroundColor: 'rgba(14, 113, 195, 0.1)', // Optional: Add background color for the line
          yAxisID: 'y2',
          fill: false,
          tension: 0.3 // Line tension
        },
        {
          label: "Total Paid Orders",
          data: ordersChartArray.map((data) => data.totalPaidOrders),
          type: 'line',
          borderColor: '#FA7070',
          yAxisID: 'y2',
          fill: false,
          tension: 0.3,
          borderWidth: 2,
          pointRadius: 4,
          pointBackgroundColor: '#FA7070' // Fixed color code
        },
        {
          label: 'Total Sales Value',
          data: ordersChartArray.map((data) => data.totalSalesValue),
          type: 'bar',
          backgroundColor: '#D1E9F6',
          borderColor: 'black',
          borderWidth: 0,
          borderRadius: 20,
          yAxisID: 'y1',
          stack: 'stack1', // Use stack grouping
        },
        {
          label: 'Total Paid Sales Value',
          data: ordersChartArray.map((data) => data.totalPaidSalesValue),
          type: 'bar',
          backgroundColor: '#E8C5E5',
          borderColor: 'black',
          borderWidth: 0,
          borderRadius: 20,
          yAxisID: 'y1',
          stack: 'stack1', // Use stack grouping
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y2: {
          id: 'y2',
          position: 'right',
          ticks: {
            stepSize: 5,
          },
          grid: {
            drawOnChartArea: false
          }
        },
        x: {
          grid: {
            display: false
          }
        },
        y1: {
          id: 'y1',
          position: 'left',
          ticks: {
            stepSize: 5,
          },
          grid: {
            drawOnChartArea: false
          },
          stacked: true // Enable stacking on y1 axis
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      elements: {
        line: {
          tension: 0.3
        }
      }
    }
  }



  return (
    <Line data={ordersData.data} options={ordersData.options} />
  )
}

export default OrdersChart;