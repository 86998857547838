// Breadcrumbs.js
import { useLocation, Link } from 'react-router-dom';
import { routeConfig } from './routeconfig';

const Breadcrumbs = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const breadcrumbs = routeConfig[currentPath]?.breadcrumbs || [];

  return (
    <nav aria-label="breadcrumb" style={{ marginBottom: "10px", fontSize: "14px" }}>
      {breadcrumbs.map((crumb, index) => (
        <span key={index}>
          {index > 0 && ' > '}
          {index < breadcrumbs.length - 1 ? (
            <Link to={crumb.path}>{crumb.name}</Link>
          ) : (
            <span>{crumb.name}</span>
          )}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
