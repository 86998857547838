import React, { createContext, useState } from 'react';

export const OrdersChartContext = createContext();

export const OrdersChartProvider = ({ children }) => {
  const [ordersChartArray, setOrdersChartArray] = useState([]);

  return (
    <OrdersChartContext.Provider value={{ ordersChartArray, setOrdersChartArray }}>
      {children}
    </OrdersChartContext.Provider>
  );
};
