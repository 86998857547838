import React, { createContext, useState } from 'react';

export const SelectedMarketplaceContext = createContext();

export const SelectedMarketplaceProvider = ({ children }) => {
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  return (
    <SelectedMarketplaceContext.Provider value={{ selectedMarketplace, setSelectedMarketplace }}>
      {children}
    </SelectedMarketplaceContext.Provider>
  );
};
