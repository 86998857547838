import React, { useState, useContext } from 'react';
import './BuyProduct.css';
import { TextField } from '@mui/material';
import { UserDetailsContext } from '../../../Context/UserDetailsContext';


const BuyProduct = ({ showAlert, closeModal }) => {

  const [loader, setLoader] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("authToken");
  const { userDetails } = useContext(UserDetailsContext);

  // const sendEmail_URL = `${baseUrl}/zumi/contactus`;
  const sendEmail_URL = `https://zumi.com.au/zumi/contactus`;

  const sendEmailDetails = {
    name: userDetails.contactPerson,
    email: userDetails.username,
    phone: userDetails.contactNumber,
  }

  // S U B M I T   F U N C T I O N 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      // Perform the fetch request
      const response = await fetch(sendEmail_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`, // Authorization header
        },

        body: JSON.stringify(sendEmailDetails)
      });

      if (response.ok) {
        showAlert("Email sent successfully.", '#d4f4e6', '#77d98b');
        setLoader(false);
      } else {
        setLoader(false);
        showAlert("Email not sent.", '#FFE4C0', '#FD5D5D');
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      showAlert("Email not sent.", '#FFE4C0', '#FD5D5D');
      console.error('Submission error:', error);
    } finally {
      setLoader(false);
      closeModal();
    }
  };

  return (
    <div className='buyProductMain'>
      <div className="buyProductSub_cont">
        <div className="buyProductSub_cont1">
          <h3>Important Information for Buyers:</h3>
          <h3>Thank you for choosing to purchase products through Zumi!</h3>

          <p>{"> "} All payments for products are made directly to the seller.</p>
          <p>{"> "} Zumi does not process or receive any payments for these transactions.</p>
          <p>{"> "} Once you confirm your order, the seller will contact you to finalize the payment and delivery details offline.</p>
          <p>{"> "} This system ensures a seamless and transparent connection between buyers and sellers.</p>
          <p>{"> "} If you have any concerns or questions, feel free to reach out to the seller directly or contact our support team for assistance.</p>

          <p style={{ fontSize: "13px", marginTop: "20px" }}>Thank you for trusting Zumi for your purchasing needs!</p>
        </div>
        <div className="buyProductSub_cont2">
          <div className="buyerCloseModal_cont">
            <div role="button" aria-label="Close" onClick={closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
          </div>
          <h3>Your product purchase request will be sent to the seller via email or WhatsApp, including the following details:</h3>
          <form className="buyProductForm">
            <TextField
              sx={{ marginTop: "15px" }}
              className='buyProductFields'
              id="outlined-basic"
              label="Username"
              variant="outlined"
              placeholder=''
              value={sendEmailDetails.name}
              InputLabelProps={{
                shrink: true, // Ensures the label does not overlap
              }}
            />
            <TextField
              sx={{ marginTop: "15px" }}
              className='buyProductFields'
              id="outlined-basic"
              label="Email"
              variant="outlined"
              placeholder=''
              value={sendEmailDetails.email}
              InputLabelProps={{
                shrink: true, // Ensures the label does not overlap
              }}
            />
            <TextField
              sx={{ marginTop: "15px" }}
              className='buyProductFields'
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              placeholder=''
              value={sendEmailDetails.phone}
              InputLabelProps={{
                shrink: true, // Ensures the label does not overlap
              }}
            />
            <p>I want to Buy! Please contact me:</p>
            <div className="buyProductBtn_cont">
              <button
                className="btnFill"
                onClick={handleSubmit}
                style={{
                  border: loader ? "1px solid #EEEEEE" : "#17c1e8",
                  backgroundColor: loader ? '#EEEEEE' : '#17c1e8',
                  color: loader ? 'gray' : '#fff',
                }}
              >{loader ? <div className='loader'></div> : "Send"}</button>
            </div>

          </form>
        </div>
      </div>


    </div>
  )
}

export default BuyProduct;
