import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';

export const RoughStartDateContext = createContext();

export const RoughStartDateProvider = ({ children }) => {
  const [roughStartDateTime, setRoughStartDateTime] = useState(dayjs().subtract(7, 'day'));

  return (
    <RoughStartDateContext.Provider value={{ roughStartDateTime, setRoughStartDateTime }}>
      {children}
    </RoughStartDateContext.Provider>
  );
};
