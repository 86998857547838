import React, { createContext, useState } from 'react';

export const UnitsSoldContext = createContext();

export const UnitsSoldProvider = ({ children }) => {
  const [unitsSoldArray, setUnitsSoldArray] = useState([]);

  return (
    <UnitsSoldContext.Provider value={{ unitsSoldArray, setUnitsSoldArray }}>
      {children}
    </UnitsSoldContext.Provider>
  );
};
