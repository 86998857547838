import React, { createContext, useState } from 'react';

export const MarketplaceLoaderContext = createContext();

export const MarketplaceLoaderProvider = ({ children }) => {
  const [marketplaceLoader, setMarketplaceLoader] = useState(true);

  return (
    <MarketplaceLoaderContext.Provider value={{ marketplaceLoader, setMarketplaceLoader }}>
      {children}
    </MarketplaceLoaderContext.Provider>
  );
};
