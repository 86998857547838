export const routeConfig = {
  '/': {
    name: 'Welcome',
    breadcrumbs: [
      { path: '/', name: 'Welcome' }
    ]
  },
  '/signin': {
    name: 'Sign In',
    breadcrumbs: [
      { path: '/', name: 'Welcome' },
      { path: '/signin', name: 'Sign In' }
    ]
  },
  '/signup': {
    name: 'Sign Up',
    breadcrumbs: [
      { path: '/', name: 'Welcome' },
      { path: '/signup', name: 'Sign Up' }
    ]
  },


  // S I D E B A R


  '/home': {
    name: 'Home',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' }
    ]
  },
  '/home/dashboard': {
    name: 'Dashboard',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/dashboard', name: 'Dashboard' }
    ]
  },
  '/home/sales': {
    name: 'Sales',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/sales', name: 'Sales' }
    ]
  },
  '/home/products': {
    name: 'Products',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/products', name: 'Products' }
    ]
  },
  '/home/supplier': {
    name: 'Supplier',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/supplier', name: 'Supplier' }
    ]
  },
  '/home/contacts': {
    name: 'Contacts',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' }
    ]
  },
  '/home/shipping': {
    name: 'Shipping',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/shipping', name: 'Shipping' }
    ]
  },
  '/home/whatsapp': {
    name: 'Whatsapp',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/whatsapp', name: 'Whatsapp' }
    ]
  },
  '/home/integration': {
    name: 'Integration',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/integration', name: 'Integration' }
    ]
  },


  // W H A T S A P P 


  '/home/whatsapp/message-history': {
    name: 'Message History',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/whatsapp', name: 'Whatsapp' },
      { path: '/home/whatsapp/message-history', name: 'Message History' },
    ]
  },
  '/home/whatsapp/connect-account': {
    name: 'Connect Account',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/whatsapp', name: 'Whatsapp' },
      { path: '/home/whatsapp/connect-account', name: 'Connect Account' },
    ]
  },
  '/home/whatsapp/connect-account/connect-step1': {
    name: 'Connect Step 1',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/whatsapp', name: 'Whatsapp' },
      { path: '/home/whatsapp/connect-account', name: 'Connect Account' },
      { path: '/home/whatsapp/connect-account/connect-step1', name: 'Connect Step 1' },
    ]
  },
  '/home/whatsapp/connect-account/connect-step1/connect-step2': {
    name: 'Connect Step 2',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/whatsapp', name: 'Whatsapp' },
      { path: '/home/whatsapp/connect-account', name: 'Connect Account' },
      { path: '/home/whatsapp/connect-account/connect-step1', name: 'Connect Step 1' },
      { path: '/home/whatsapp/connect-account/connect-step1/connect-step2', name: 'Connect Step 2' },
    ]
  },


  // C O N T A C T S


  '/home/contacts/segment': {
    name: 'Segments',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/segment', name: 'Segments' }
    ]
  },
  '/home/contacts/import-contact': {
    name: 'Import Contacts',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' }
    ]
  },
  '/home/contacts/import-contact/start-import': {
    name: 'Start Import',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' },
      { path: '/home/contacts/import-contact/start-import', name: 'Start Import' }
    ]
  },
  '/home/contacts/import-contact/start-import/submit-contact': {
    name: 'Submit Contact',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' },
      { path: '/home/contacts/import-contact/start-import', name: 'Start Import' },
      { path: '/home/contacts/import-contact/start-import/submit-contact', name: 'Submit Contact' }
    ]
  },
  '/home/contacts/import-contact/help': {
    name: 'Help',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' },
      { path: '/home/contacts/import-contact/help', name: 'Help' }
    ]
  },
  '/home/contacts/import-contact/start-import/import-guide': {
    name: 'Import Guide',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' },
      { path: '/home/contacts/import-contact/start-import', name: 'Start Import' },
      { path: '/home/contacts/import-contact/start-import/import-guide', name: 'Import Guide' },
    ]
  },
  '/home/contacts/import-contact/start-import/FAQs': {
    name: 'FAQs',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/contacts', name: 'Contacts' },
      { path: '/home/contacts/import-contact', name: 'Import Contacts' },
      { path: '/home/contacts/import-contact/start-import', name: 'Start Import' },
      { path: '/home/contacts/import-contact/start-import/FAQs', name: 'FAQs' },
    ]
  },


  //  S A L E S 

  '/home/salesrevenue': {
    name: 'Revenue',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/salesrevenue', name: 'Revenue' }
    ]
  },
  '/home/salesprofit': {
    name: 'Profit',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/salesprofit', name: 'Profit' }
    ]
  },
  '/home/salesorders': {
    name: 'Orders',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/salesorders', name: 'Orders' }
    ]
  },
  '/home/salesbuybox': {
    name: 'Buybox',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/salesbuybox', name: 'Buybox' }
    ]
  },


  //  P R O D U C T S

  '/home/product-catalogs': {
    name: 'Catalogs',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/product-catalogs', name: 'Catalogs' }
    ]
  },
  '/home/price-analytics': {
    name: 'Analytics',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/price-analytics', name: 'Analytics' }
    ]
  },


  //  S U P P L I E R 

  '/home/create-broadcast': {
    name: 'Create Broadcast',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/create-broadcast', name: 'Create Broadcast' }
    ]
  },
  '/home/broadcasts': {
    name: 'Broadcasts',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/view-broadcast', name: 'Broadcasts' }
    ]
  },
  '/home/search-product': {
    name: 'Search Product',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/search-product', name: 'Search Product' }
    ]
  },

  //  I N T E G R A T I O N 

  '/home/view-marketplace': {
    name: 'Marketplace',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/view-marketplace', name: 'Marketplace' }
    ]
  },
  '/home/new-marketplace': {
    name: 'New Marketplace',
    breadcrumbs: [
      { path: '/home/dashboard', name: 'Home' },
      { path: '/home/new-marketplace', name: 'New Marketplace' }
    ]
  },









};
