import React, { useEffect, useState } from 'react';
import './Buyer.css'
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotificationsIcon from '@mui/icons-material/Notifications';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataTable from 'react-data-table-component';
import tableStyles from '../Sales/Revenue/TableStyles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BuyerProduct from './BuyProduct/BuyProduct';
import MakeOffer from './MakeOffer/MakeOffer';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const makeOfferStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const Buyer = () => {
  const { t } = useTranslation();

  const userId = localStorage.getItem('userId');

  const [activeTab, setActiveTab] = useState('home');
  const handleTabClick = (tab) => setActiveTab(tab); // Set the active tab
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [makeOfferElm, setMakeOfferElm] = useState();

  const [makeOfferOpen, setMakeOfferOpen] = useState(false);
  const handleMakeOfferOpen = (row) => {
    setMakeOfferElm(row);
    setMakeOfferOpen(true);
  }
  const handleMakeOfferClose = () => setMakeOfferOpen(false);

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const liveOffers = [
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
    {
      id: 1,
      productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
      productPrice: "$ 49",
      offerPrice: "$ 40",
      available: "In transit 160",
      quantity: "18",
    },
  ]

  const columns = [
    {
      name: t('productInfo'),
      selector: row => row.productInfo,
      width: "350px"

    },
    {
      name: t('productPrice'),
      selector: row => row.productPrice,
      width: "120px"

    },
    {
      name: t('offerPrice'),
      selector: row => row.offerPrice,
      width: "120px"

    },
    {
      name: t('available'),
      selector: row => row.available,
      width: "120px"

    },
    {
      name: t('quantity'),
      selector: row => row.quantity,
      width: "100px"

    },
    {
      name: t(''),
      selector: row => <div className="dashLiveBtn_cont">
        <button className='btnFill dashLiveBtn' onClick={handleMakeOfferOpen}>Make Offer</button>
        <button className='btnFill dashLiveBtn' onClick={handleOpen} style={{ marginLeft: "8px" }}>Buy Now</button>
        <div className="dashLiveNotify_cont" style={{ marginLeft: "8px" }}>
          <NotificationsIcon />
        </div>
      </div>,
    },
  ];

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer  
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };


  return (
    <div className='buyerMain'>

      {/* Alert display */}
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      {
        userId && (
          <div className="whatToolMain_cont">
            <div className="whatToolsBtns_cont">
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'home' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/buyer"
              >
                <HomeIcon style={{ marginRight: "8px" }} /> {t('home')}
              </Link>
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'todaysDeals' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/todays-deals"
              >
                <WhatshotIcon style={{ marginRight: "8px" }} /> {t('todaysDeals')}
              </Link>
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'priceDrop' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/price-drop"
              >
                <TrendingDownIcon style={{ marginRight: "8px" }} /> {t('priceDrop')}
              </Link>
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'newLaunched' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/new-launched"
              >
                <RocketLaunchIcon style={{ marginRight: "8px" }} /> {t('newLaunched')}
              </Link>
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'justSold' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/buyer"
              >
                <CallMadeIcon style={{ marginRight: "8px" }} /> {t('justSold')}
              </Link>
              <Link
                // className='whatToolsLink'
                className={`whatToolsLink ${activeTab === 'liveOffers' ? 'whatsapptabActive' : ''}`}
                onClick={() => handleTabClick('material')}
                to="/home/buyer"
              >
                <LocalOfferIcon style={{ marginRight: "8px" }} /> {t('liveOffers')}
              </Link>
            </div>
          </div>
        )
      }

      {/* M O D A L   T O   B U Y    P R O D U C T */}
      <Modal
        className='templateStyleMobile'
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BuyerProduct showAlert={showAlert} closeModal={handleClose}></BuyerProduct>
        </Box>
      </Modal>


      {/* M O D A L   T O   M A K E    O F F E R */}
      <Modal
        className='templateStyleMobile'
        open={makeOfferOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={makeOfferStyle}>
          <MakeOffer showAlert={showAlert} makeOfferElm={makeOfferElm} closeModal={handleMakeOfferClose}></MakeOffer>
        </Box>
      </Modal>

      <div className="buyerRow1">
        <div className="buyerRow1Card">
          <div className="buyerCardSub_cont" style={{ background: "#d4f6ed" }}>
            <div className="buyerCardSub_cont1">
              <div className="buyerCardIcon_cont">
                <WhatshotIcon sx={{ fontSize: "40px" }} />
              </div>
              <div className="buyerCardData_cont">
                <div className="buyerCardData centerElm">
                  <h3>50</h3>
                </div>
              </div>
            </div>
            <div className="buyerCardSub_cont2 centerElm">
              <p>{t('todaysDeals')}</p>
            </div>
          </div>
          <div className="buyerCardBtn_cont">
            <Link className='buyerCardBtn' to="/home/todays-deals">View all</Link>
          </div>
        </div>
        <div className="buyerRow1Card">
          <div className="buyerCardSub_cont" style={{ background: "#e3dbfa" }}>
            <div className="buyerCardSub_cont1">
              <div className="buyerCardIcon_cont">
                <TrendingDownIcon sx={{ fontSize: "40px" }} />
              </div>
              <div className="buyerCardData_cont">
                <div className="buyerCardData centerElm">
                  <h3>232</h3>
                </div>
              </div>
            </div>
            <div className="buyerCardSub_cont2 centerElm">
              <p>{t('priceDrop')}</p>
            </div>
          </div>
          <div className="buyerCardBtn_cont">
            <Link className='buyerCardBtn' to="/home/price-drop">View all</Link>
          </div>
        </div>
        <div className="buyerRow1Card">
          <div className="buyerCardSub_cont" style={{ background: "#ffe1cc" }}>
            <div className="buyerCardSub_cont1">
              <div className="buyerCardIcon_cont">
                <RocketLaunchIcon sx={{ fontSize: "40px" }} />
              </div>
              <div className="buyerCardData_cont">
                <div className="buyerCardData centerElm">
                  <h3>311</h3>
                </div>
              </div>
            </div>
            <div className="buyerCardSub_cont2 centerElm">
              <p>{t('newLaunched')}</p>
            </div>
          </div>
          <div className="buyerCardBtn_cont">
            <Link className='buyerCardBtn' to="">View all</Link>
          </div>
        </div>
        <div className="buyerRow1Card">
          <div className="buyerCardSub_cont" style={{ background: "#FFCCE1" }}>
            <div className="buyerCardSub_cont1">
              <div className="buyerCardIcon_cont">
                <CallMadeIcon sx={{ fontSize: "40px" }} />
              </div>
              <div className="buyerCardData_cont">
                <div className="buyerCardData centerElm">
                  <h3>29</h3>
                </div>
              </div>
            </div>
            <div className="buyerCardSub_cont2 centerElm">
              <p>{t('justSold')}</p>
            </div>
          </div>
          <div className="buyerCardBtn_cont">
            <Link className='buyerCardBtn' to="">View all</Link>
          </div>
        </div>
        <div className="buyerRow1Card" style={{ marginRight: "0px" }}>
          <div className="buyerCardSub_cont" style={{ background: "#D9EAFD" }}>
            <div className="buyerCardSub_cont1">
              <div className="buyerCardIcon_cont">
                <LocalOfferIcon sx={{ fontSize: "40px" }} />
              </div>
              <div className="buyerCardData_cont">
                <div className="buyerCardData centerElm">
                  <h3>28</h3>
                </div>
              </div>
            </div>
            <div className="buyerCardSub_cont2 centerElm">
              <p>{t('liveOffers')}</p>
            </div>
          </div>
          <div className="buyerCardBtn_cont">
            <Link className='buyerCardBtn' to="">View all</Link>
          </div>
        </div>

      </div>

      {/* <div className="buyerRow2">
        <div className="buyerRow2Card" style={{ background: '#33554c' }}>
          <p>{t('todaysDeals')}</p>
          <h2>50</h2>
        </div>
        <div className="buyerRow2Card" style={{ background: '#e00d11' }}>
          <p>{t('priceDrop')}</p>
          <h2>232</h2>
        </div>
        <div className="buyerRow2Card" style={{ background: '#0dca30' }}>
          <p>{t('newLaunched')}</p>
          <h2>311</h2>
        </div>
        <div className="buyerRow2Card" style={{ background: '#361fe3' }}>
          <p>{t('justSold')}</p>
          <h2>28</h2>
        </div>
        <div className="buyerRow2Card" style={{ background: '#6a0073', marginRight: "0px" }}>
          <p>{t('liveOffers')}</p>
          <h2>29</h2>
        </div>
      </div> */}

      <div className="dashLiveOfferMain_cont">
        <h3>Recommended LIVE offers for you</h3>

        <div className='tableMain_cont'>
          <div className='table_cont'>
            {/* {templateLoader ? (
              <ContactTableLoader />
            ) : (
              <DataTable
                columns={columns}
                customStyles={templateTableStyles}
                noDataComponent={<NoDataComponent />}
                data={filteredAndSortedTemplateData}
                defaultSortField="created_DateTime"
                defaultSortAsc={sortTemplateDirection === 'asc'}
                pagination
              />
            )} */}
            <DataTable
              columns={columns}
              customStyles={tableStyles}
              data={liveOffers}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buyer;