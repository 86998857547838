import React, { createContext, useState } from 'react';

export const SegmentContext = createContext();

export const SegmentProvider = ({ children }) => {
  const [segmentArray, setSegmentArray] = useState([]);

  return (
    <SegmentContext.Provider value={{ segmentArray, setSegmentArray }}>
      {children}
    </SegmentContext.Provider>
  );
};
