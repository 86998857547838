import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next/i18n';
import { RoughStartDateProvider } from './Context/RoughStartDateContext';
import { RoughEndDateProvider } from './Context/RoughEndDateContext';
import { MarketplaceProvider } from './Context/MarketplaceContext';
import { SelectedMarketplaceProvider } from './Context/SelectedMarketplaceContext';
import { SegmentProvider } from './Context/SegmentContext';
import { TemplateProvider } from './Context/TemplateContext';
import { TotalSalesProvider } from './Context/TotalSalesContext';
import { UnitsSoldProvider } from './Context/UnitsSoldContext';
import { ProfitProvider } from './Context/ProfitContext';

import { RevenueChartProvider } from './Context/RevenueChartContext';
import { ProfitChartProvider } from './Context/ProfitChartContext';
import { OrdersChartProvider } from './Context/OrdersChartContext';

import { AllowedPrivilegesProvider } from './Context/AllowedPrivilegesContext';
import { CurrencyProvider } from './Context/CurrencyContext';
import { CreditsProvider } from './Context/CreditsContext';
import { BrandLogoProvider } from './Context/BrandLogoContext';
import { SubscriptionPlanProvider } from './Context/SubscriptionPlanContext';
import { MarketplaceLoaderProvider } from './Context/MarketplaceLoaderContext';

import { UserDetailsProvider } from './Context/UserDetailsContext';

import { RefreshFunProvider } from './Context/RefreshFunContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserDetailsProvider>
      <RefreshFunProvider>
        <BrandLogoProvider>
          <SubscriptionPlanProvider>
            <CreditsProvider>
              <CurrencyProvider>
                <AllowedPrivilegesProvider>
                  <RoughStartDateProvider>
                    <RoughEndDateProvider>
                      <MarketplaceLoaderProvider>
                        <MarketplaceProvider>
                          <SelectedMarketplaceProvider>
                            <SegmentProvider>
                              <TemplateProvider>
                                <TotalSalesProvider>
                                  <UnitsSoldProvider>
                                    <ProfitProvider>
                                      <RevenueChartProvider>
                                        <ProfitChartProvider>
                                          <OrdersChartProvider>
                                            <App />
                                          </OrdersChartProvider>
                                        </ProfitChartProvider>
                                      </RevenueChartProvider>
                                    </ProfitProvider>
                                  </UnitsSoldProvider>
                                </TotalSalesProvider>
                              </TemplateProvider>
                            </SegmentProvider>
                          </SelectedMarketplaceProvider>
                        </MarketplaceProvider>
                      </MarketplaceLoaderProvider>
                    </RoughEndDateProvider>
                  </RoughStartDateProvider>
                </AllowedPrivilegesProvider>
              </CurrencyProvider>
            </CreditsProvider>
          </SubscriptionPlanProvider>
        </BrandLogoProvider>
      </RefreshFunProvider>
    </UserDetailsProvider>
  </React.StrictMode>
);

reportWebVitals();
