import React, { createContext, useState } from 'react';

export const ProfitContext = createContext();

export const ProfitProvider = ({ children }) => {
  const [profitArray, setProfitArray] = useState([]);

  return (
    <ProfitContext.Provider value={{ profitArray, setProfitArray }}>
      {children}
    </ProfitContext.Provider>
  );
};
