import React, { useState, useEffect, useContext } from 'react';
import './BuyCredits.css';
import { useTranslation } from 'react-i18next';
import Credits from '../../../src/assets/creditSvg.svg';
import { TextField } from '@mui/material';
import { CurrencyContext } from '../../Context/CurrencyContext';


const BuyCredits = ({ closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { currency, setCurrency } = useContext(CurrencyContext);

  const [buyCreditAmount, setBuyCreditAmount] = useState(500);
  const authToken = localStorage.getItem('authToken');

  const [eway_url, setEway_url] = useState("");
  const [ewayLoader, setEwayLoader] = useState(false);

  const [payment, setPaymentFrom] = useState(
    {
      paymentFrom: "BuyCredits",
      credits: "",
      available_credits: "",
    })

  const handleCreditChange = (e) => {
    const value = e.target.value;
    setBuyCreditAmount(value);
  };

  const handleEwayPayment = (amount) => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/payment/request-access-code?totalAmount=${amount}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          setEwayLoader(true);
          if (buyCreditAmount) {
            setPaymentFrom(prevState => {
              const updatedState = { ...prevState, credits: buyCreditAmount, available_credits: buyCreditAmount };
              localStorage.setItem("paymentFrom", JSON.stringify(updatedState));  // Store the updated state
              return updatedState;
            });
          }
          const result = await response.text();
          setEway_url(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }

  // R E D I R E C T    T O   E W A Y
  useEffect(() => {
    if (eway_url) {
      setEwayLoader(true);
      window.location.href = eway_url;
    }
  }, [eway_url]);

  const isButtonDisabled = buyCreditAmount < 500 || isNaN(buyCreditAmount);

  return (
    <div className='buyCreditsMain_cont'>
      <div className="buyCreditsHeader centerElm">
        <div className="buyCreditsHeaderSub">
          <div className="buyCreditsHeaderSvg centerElm">
            <img src={Credits} alt="" />
          </div>
          <div className="buyCreditsHead">
            <p>Buy More Credits</p>
          </div>
        </div>
      </div>
      <div className="buyCreditsSub_cont">
        <p className='m-b-10'>Choose Amount: </p>
        {currency === "AUD" && (
          <div className="creditSelectRow m-b-20">
            <div className="priceCredit centerElm" onClick={() => setBuyCreditAmount(500)}>
              $ 500 AUD
            </div>
            <div className="priceCredit centerElm" onClick={() => setBuyCreditAmount(1000)}>
              $ 1000 AUD
            </div>
            <div className="priceCredit centerElm" onClick={() => setBuyCreditAmount(3000)}>
              $ 3000 AUD
            </div>
            <div className="priceCredit centerElm" onClick={() => setBuyCreditAmount(5000)}>
              $ 5000 AUD
            </div>
          </div>
        )}

        <p className='m-b-10'>Enter Amount: <span>(Minimum amount: $ 500 AUD)</span></p>
        <TextField
          className='buyCreditInput'
          InputLabelProps={{ style: { display: 'none' } }}
          inputProps={{ autoComplete: 'off' }}
          type='number'
          name="buyCredit"
          id="outlined-basic"
          placeholder="$500 "
          variant="outlined"
          value={buyCreditAmount}
          onChange={handleCreditChange}
        />

        <div className="buyCreditBtn_cont">
          <button
            className="btnFill"
            disabled={isButtonDisabled}
            style={{
              border: isButtonDisabled ? "1px solid #EEEEEE" : "#17c1e8",
              backgroundColor: isButtonDisabled ? '#EEEEEE' : '#17c1e8',
              color: isButtonDisabled ? 'gray' : '#fff',
            }}
            onClick={() => handleEwayPayment(buyCreditAmount)}
          >
            {ewayLoader ? <div className='loaderWhite'></div> : "Buy Credits"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BuyCredits;
