import React, { createContext, useState } from 'react';

export const MarketplaceContext = createContext();

export const MarketplaceProvider = ({ children }) => {
  const [marketplaceArray, setMarketplaceArray] = useState([]);

  return (
    <MarketplaceContext.Provider value={{ marketplaceArray, setMarketplaceArray }}>
      {children}
    </MarketplaceContext.Provider>
  );
};
