import React, { useEffect } from 'react';
import './DatasetInfo.css';
import { useTranslation } from 'react-i18next';


const DatasetInfo = ({ transactions, closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className='datasetInfo_main'>
      <div className="newDatasetHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('audienceInfo')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="datasetDetails_cont">
        <p>Total transactions: <span>{(transactions ? transactions : "...")}</span></p>
        <p>Charge per transactions: <span>20</span></p>
        <p>Total Charge: <span>{transactions * 20}</span></p>
      </div>
    </div>
  )
}

export default DatasetInfo;
