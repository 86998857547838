import React, { useState, useEffect, useContext } from 'react';
import './Sms.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataTable from 'react-data-table-component';
import { SegmentContext } from '../../Context/SegmentContext';

import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';

import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';
import CreateSms from './CreateSms/CreateSms';
import ScheduleTask from './ScheduleTask/ScheduleTask';
import TaskView from './TaskView/TaskView';

const createSmsStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Sms = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const [smsLoader, setsmsLoader] = useState(true);
  const [updateSms, setUpdateSms] = useState();

  const segment_URL = `${baseUrl}/zumi/api/getsegment`;
  const cli_URL = `${baseUrl}/airkommWebPortal/api/getAvailableCli?userId=${userId}`;
  const smsc_URL = `${baseUrl}/airkommWebPortal/api/getAvailableSMSC?userId=${userId}`;
  const smsCampaign_URL = `${baseUrl}/airkommWebPortal/api/getcampaigns?userId=${userId}`;

  const [segmentData, setSegmentData] = useState([]);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [cli, setCli] = useState([]);
  const [smsc, setSmsc] = useState([]);

  const [smsCampaigns, setSmsCampaigns] = useState([])

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [scheduleTaskOpen, setScheduleTaskOpen] = React.useState(false);
  const handleScheduleTaskOpen = (id) => setScheduleTaskOpen(true);
  const handleScheduleTaskClose = () => setScheduleTaskOpen(false);

  const [taskViewOpen, setTaskViewOpen] = React.useState(false);
  const handleTaskViewOpen = (id) => setTaskViewOpen(true);
  const handleTaskViewClose = () => setTaskViewOpen(false);

  const [viewTaskId, setViewTaskId] = useState();

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const [sortTemplateDirection, setSortTemplateDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCampId, setSelectedCampId] = useState();


  const openScheduleTask = (campId) => {
    setSelectedCampId(campId)
    handleScheduleTaskOpen();
  }

  const openTaskView = (campId) => {
    setViewTaskId(campId);
    handleTaskViewOpen();
  }

  const smsTableStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        color: "rgb(14, 113, 195)",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "24px",
      },
    },
    headCells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        // border: "1px solid red",
        padding: "10px",
        fontSize: "0.9rem",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  const columns = [
    {
      name: t('srNo'),
      selector: (row, rowIndex) => rowIndex + 1,
      width: "80px",
    },
    {
      name: t('campaignName'),
      selector: row => row.campaignName,
      width: "200px"
    },
    {
      name: t('dateCreated'),
      selector: row => {
        const date = new Date(row.createdDateTime);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
      width: "200px",
    },
    {
      name: t('campaignPeriod'),
      selector: row => {
        const startDate = new Date(row.scheduleDate);
        const endDate = new Date(row.scheduleEndDate);

        // Format both the start and end dates similarly to dateCreated
        const formattedStartDate = startDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          // hour: '2-digit',
          // minute: '2-digit',
          hour12: true
        });

        const formattedEndDate = endDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          // hour: '2-digit',
          // minute: '2-digit',
          hour12: true
        });

        return `${formattedStartDate} - ${formattedEndDate}`;
      },
      width: "240px",
    },
    {
      name: t('scheduleTask'),
      selector: row =>
        <button className='btnFill scheduleTaskBtn' onClick={() => { openScheduleTask(row.smscampaignId) }}>Schedule Task</button>,
      width: "150px",
    },
    {
      name: t('taskDetails'),
      selector: row => <button className='btnFill taskDetailsView' onClick={() => { openTaskView(row.smscampaignId) }}>View</button>,
      width: "150px",
    },
  ];

  const updateSmsData = (value) => {
    setUpdateSms(value);
  }

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(4);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {

      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 
  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);


  // F E T C H   C L I
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(cli_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setCli(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // F E T C H   S M S C
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(smsc_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSmsc(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // F E T C H   S M S    C A M P A I G N S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(smsCampaign_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSmsCampaigns(result.smsCampaign);
          setsmsLoader(false);

        } catch (error) {
          console.log(error);
          setsmsLoader(false);
        }
      };
      fetchData();
    }
  }, [updateSms]);


  const handleTemplateSort = (column, sortTemplateDirection) => {
    setSortTemplateDirection(sortTemplateDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAndSortedTemplateData = React.useMemo(() => {
    const filteredData = smsCampaigns.filter(item =>
      item.campaignName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortTemplateDirection === 'asc') {
        return new Date(a.createdDateTime) - new Date(b.createdDateTime);
      } else {
        return new Date(b.createdDateTime) - new Date(a.createdDateTime);
      }
    });
  }, [smsCampaigns, sortTemplateDirection, searchQuery]);

  return (
    <div className='smsMain_cont'>
      {/* <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            className='whatToolsLink'
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> Broadcast
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> Message History
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> Connect Account
          </Link>
          <Link
            className='whatToolsLink'
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> Knowledge Base
          </Link>
        </div>
      </div> */}

      {/* Alert display */}
      {/* {alert.message && (
        <div style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          padding: '10px 20px',
          backgroundColor: alert.color,
          color: 'black',
          borderRadius: '10px',
          border: `2px solid ${alert.borderColor}`,
          zIndex: 9999
        }}>
          {alert.message} <span>({alertTimer})</span>
        </div>
      )} */}

      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}


      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search Campaigns'
              onChange={handleSearch}
            />
            <Select
              size="small"
              className='sortTemplateInput'
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sortTemplateDirection}
              onChange={e => handleTemplateSort(null, e.target.value)}
            >
              {
                sortItem.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                  )
                })
              }
            </Select>
          </div>

          <div className="templateRowBtn_cont">
            <button className='btnFill' onClick={handleOpen}>
              {t('smsCampaign')}
            </button>

            {/* M O D A L   T O   C R E A T E   S M S   C A M P A I G N */}
            <Modal
              className='templateStyleMobile'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={createSmsStyle}>
                <CreateSms btnText="Submit" cli={cli} smsc={smsc} updateSmsData={updateSmsData} showAlert={showAlert} closeModal={handleClose}></CreateSms>
              </Box>
            </Modal>


            {/* M O D A L   F O R   S C H E D U L E  */}
            <Modal
              className='templateStyleMobile'
              open={scheduleTaskOpen}
              onClose={handleScheduleTaskClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={createSmsStyle}>
                <ScheduleTask btnText="Submit" cli={cli} smsc={smsc} showAlert={showAlert} selectedCampId={selectedCampId} closeModal={handleScheduleTaskClose}></ScheduleTask>
              </Box>
            </Modal>


            {/* M O D A L   F O R   T A S K   V I E W  */}
            <Modal
              className='templateStyleMobile'
              open={taskViewOpen}
              onClose={handleTaskViewClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={createSmsStyle}>
                <TaskView btnText="Submit" viewTaskId={viewTaskId} closeModal={handleTaskViewClose}></TaskView>
              </Box>
            </Modal>
          </div>
        </div>
      </div>


      <div className='tableMain_cont'>
        <div className='table_cont'>

          {smsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={smsTableStyles}
              data={filteredAndSortedTemplateData}
              defaultSortField="createdDateTime"
              defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Sms;