import React, { useState, useEffect } from 'react'
import './Dataset.css';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import GroupsIcon from '@mui/icons-material/Groups';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DatasetInfo from '../DatasetInfo/DatasetInfo';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const templateTableStyles = {
  headRow: {
    style: {
      position: "sticky",
      top: 0,
      color: "rgb(14, 113, 195)",
      width: "100%",
      fontSize: "1rem",
      lineHeight: "24px",
    },
  },
  headCells: {
    style: {
      // border: "1px solid blue",
      padding: "10px",
      textAlign: "left",
    },
  },
  cells: {
    style: {
      // border: "1px solid red",
      padding: "10px",
      fontSize: "0.9rem",
      color: "black",
      lineHeight: "25px",
    },
  }
}

const Dataset = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const datasets_URL = `https://3pvwejju4h.execute-api.us-east-1.amazonaws.com/zumi/getaudience`;
  const transactionCount_URL = `https://3pvwejju4h.execute-api.us-east-1.amazonaws.com/zumi/myaudiencecount`;

  const [activeTab, setActiveTab] = useState('datasets');
  const [datasetLoader, setDatasetLoader] = useState(true);
  const [datasets, setDatasets] = useState([]);

  const [transactions, setTransactions] = useState();

  const [open, setOpen] = React.useState(false);

  const handleOpen = (queryId) => {
    setOpen(true);
    fetchTransactions(queryId);
  };

  const handleClose = () => setOpen(false);

  const columns = [
    {
      name: t('datasetName'),
      selector: row => <div className='datasetName_cont' onClick={() => handleOpen(row.queryId)} style={{ cursor: "pointer" }}>{row.datasetName}</div>,
      width: "150px",
    },
    {
      name: t('description'),
      selector: row => row.description,
      width: "300px",
    },
    {
      name: t('suburb'),
      selector: row => row.suburb,
      width: "150px",
    },
    {
      name: t('state'),
      selector: row => row.state,
      width: "120px",
    },
    {
      name: t('ageRange'),
      selector: row => row.ageRangeFrom + "-" + row.ageRangeTo,
      width: "120px",
    },
    {
      name: t('gender'),
      selector: row => row.gender,
    },
    {
      name: t('voter'),
      selector: row => row.voters ? "Yes" : "No",
    },
  ];

  // F E T C H    A U D I E N C E
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        const postData = {
          "createdByUserId": userId
        }
        try {
          const response = await fetch(datasets_URL, {
            method: 'POST',
            headers: {
              // 'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();

          setDatasets(result.dataSets);
        } catch (error) {
          console.log(error);
        } finally {
          setDatasetLoader(false);
        }
      };
      fetchData();
    }
  }, []);

  // F E T C H    T R A N S A C T I O N    C O U N T


  const fetchTransactions = async (queryId) => {
    const postData = {
      "queryId": queryId
    }
    try {
      const response = await fetch(transactionCount_URL, {
        method: 'POST',
        headers: {
          // 'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      setTransactions(result.dataSets[0].count);

    } catch (error) {
      console.log(error);
    } finally {
      setDatasetLoader(false);
    }
  };

  useEffect(() => {
    if (transactions) {
      console.log("Number of transactions: ", transactions);
    }
  }, [transactions]);


  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  return (
    <div className='datasets_main'>
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            className={`whatToolsLink ${activeTab === 'audience' ? 'audienceTabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/audience"
          >
            <GroupsIcon style={{ marginRight: "8px" }} /> {t('audience')}
          </Link>
          <Link
            className={`whatToolsLink ${activeTab === 'datasets' ? 'audienceTabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/datasets"
          >
            <Diversity2Icon style={{ marginRight: "8px" }} /> {t('myAudience')}
          </Link>
        </div>
      </div>

      {/* M O D A L   F O R   D A T A S E T   I N F O */}
      <Modal
        className='templateStyleMobile'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DatasetInfo transactions={transactions} closeModal={handleClose}></DatasetInfo>
        </Box>
      </Modal>

      <div className='tableMain_cont'>
        <div className='table_cont'>
          {datasetLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={templateTableStyles}
              data={datasets}
              defaultSortField="created_DateTime"
              // defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Dataset;