import React, { useState, useEffect, useContext } from 'react';
import './Profit.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ProfitChart from './ProfitChart/ProfitChart';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { ProfitChartContext } from '../../../Context/ProfitChartContext';
import { SelectedMarketplaceContext } from '../../../Context/SelectedMarketplaceContext';
import TimelineIcon from '@mui/icons-material/Timeline';
import { RoughStartDateContext } from '../../../Context/RoughStartDateContext';
import { RoughEndDateContext } from '../../../Context/RoughEndDateContext';


const Profit = () => {

  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const { roughStartDateTime, setRoughStartDateTime } = useContext(RoughStartDateContext);
  const { roughEndDateTime, setRoughEndDateTime } = useContext(RoughEndDateContext);
  const [combinedDateTime, setCombinedDateTime] = useState(null);

  const [profitData, setProfitData] = useState([]);

  const [totalQuantity, setTotalQuantity] = useState();
  const [totalPercentage, setTotalPercentage] = useState();
  const [profitLoader, setProfitLoader] = useState(true);
  const [profitChartLoader, setProfitChartLoader] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { profitChartArray, setProfitChartArray } = useContext(ProfitChartContext);

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "130px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "130px",
    },
    {
      name: t("orderId"),
      selector: row => row.orderId,
      width: "200px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",
    },
    {
      name: t("totalSale"),
      selector: row => row.revenue,
      width: "120px",
      sortable: true,
    },
    {
      name: t("cogs"),
      selector: row => row.cogs,
      width: "70px",
    },
    {
      name: t("operatingExp"),
      selector: row => row.operatingExpenses,
      width: "90px",
    },
    {
      name: t("promotion"),
      selector: row => row.promotion,
      width: "90px",
    },
    {
      name: t("other"),
      selector: row => row.other,
      width: "90px",
    },
    {
      name: t("netProfit"),
      selector: row => row.netProfit,
      width: "120px",
      sortable: true,
    },
    {
      name: t("unitsShipped"),
      selector: row => row.unitShipped,
      width: "90px",
    },
    {
      name: t("refund"),
      selector: row => row.refundRate,
      width: "90px",
    },

  ];

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDateTime) {
      const formattedStartDate = roughStartDateTime.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDateTime) {
      const formattedEndDate = roughEndDateTime.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDateTime, roughStartDateTime]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDateTime(startDate + " - " + endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (combinedDateTime) {
      console.log("Combined date: ", combinedDateTime)
    }
  }, [combinedDateTime]);

  const profitTable_URL = `${baseUrl}/zumi/api/salesprofit?dateRange=${combinedDateTime}`;
  const profitChart_URL = `${baseUrl}/zumi/api/sales/profitchart?dateRange=${combinedDateTime}`;

  // F E T C H    P R O F I T    T A B L E   D A T A
  useEffect(() => {
    setProfitLoader(true);
    if (combinedDateTime) {
      const fetchData = async () => {
        try {
          const response = await fetch(profitTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setProfitLoader(false);
          setProfitData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setProfitLoader(false);
        }
      };
      fetchData();
    }
  }, [combinedDateTime, selectedMarketplace]);

  // F E T C H    P R O F I T    C H A R T   D A T A
  useEffect(() => {
    setProfitChartLoader(true);
    if (combinedDateTime) {
      const fetchData = async () => {
        try {
          const response = await fetch(profitChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            setProfitChartLoader(false);
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setProfitChartLoader(false);
          setProfitChartArray(result);
        } catch (error) {
          console.log(error);
        } finally {
          setProfitChartLoader(false);
        }
      };
      fetchData();
    }
  }, [combinedDateTime, selectedMarketplace]);

  // F U N C T I O N   T O   C A L C U L A T E
  useEffect(() => {
    if (profitChartArray) {
      console.log("Profit chart array: ", profitChartArray)
      const quantitySum = profitChartArray.reduce((acc, item) => acc + (item.quantity || 0), 0);
      setTotalQuantity(quantitySum);
      const percentageSum = profitChartArray.reduce((acc, item) => acc + (item.percentage || 0), 0);
      const roundedPercentageSum = parseFloat(percentageSum.toFixed(3));
      setTotalPercentage(roundedPercentageSum);
      // const weightedPercentageSum = profitChartArray.reduce((acc, item) => acc + (item.percentage * item.quantity || 0), 0);
      // const totalQuantity = profitChartArray.reduce((acc, item) => acc + (item.quantity || 0), 0);
      // const weightedAveragePercentage = totalQuantity > 0 ? weightedPercentageSum / totalQuantity : 0;
      // setTotalPercentage(parseFloat(weightedAveragePercentage.toFixed(3)));
    }
  }, [profitChartArray, combinedDateTime]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDateTime}
              onChange={setRoughStartDateTime}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDateTime}
              disabled={!roughStartDateTime}
              onChange={setRoughEndDateTime}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="revenueMainChartMain_cont">
        <div className="revenueMainChart_cont">
          <div className="dcont_heading">
            <span>{t("salesProfitChartHead")}</span>
            <h5>
              Products: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalQuantity}</span>
              <span>{`   `}</span>
              GB Percentage: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalPercentage} {"%"}</span>
            </h5>
          </div>
          <div className="revenueMainChart">
            {/* <ProfitChart /> */}
            {profitChartLoader ? (
              <p>Loading...</p>
            ) : (
              <ProfitChart />
            )}
          </div>
          <div className="revenueLegendMain_cont">
            <div className="revenueLegendSub_cont">
              <div className="legend1_cont">
                <div className="legend1">

                </div>
                <div className="legendName">
                  <p>
                    {t("profitLegend1")}
                  </p>
                </div>
              </div>
              <div className="legend2_cont">
                <div className="legend2">
                  <TimelineIcon style={{ height: "2rem", width: "2rem", color: "rgb(14, 113, 195)" }} />
                </div>
                <div className="legendName">
                  <p>
                    {t("profitLegend2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {profitLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={profitData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Profit;