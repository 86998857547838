import React, { useState, useContext, useEffect } from 'react';
import './ViewMarketplace.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import { MarketplaceContext } from '../../../Context/MarketplaceContext';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ViewMarketplace = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const marketplace_URL = `${baseUrl}/zumi/api/marketplaces`;
  const deleteMarket_URL = `${baseUrl}/zumi/api/deletemarketplaces`;
  const editMarket_URL = `${baseUrl}/zumi/api/marketplaces`;

  const [deleteMarketId, setDeleteMarketId] = useState();
  const { marketplaceArray, setMarketplaceArray } = useContext(MarketplaceContext);
  const [viewMarketloader, setViewMarketLoader] = useState(true);
  const [editingMarket, setEditingMarket] = useState(null);
  const [newNickname, setNewNickname] = useState('');

  const [nickNameUpdated, setNickNameUpdated] = useState(false);

  const columns = [
    {
      name: t('nickname'),
      selector: row => (
        <div className="marketNickname_cont">
          <div className="marketNickname">
            {editingMarket?.channelId === row.channelId ? (
              <input
                type="text"
                value={newNickname}
                onChange={(e) => setNewNickname(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, row.channelId)}
                autoFocus
              />
            ) : (
              row.nickname
            )}
          </div>
          <div className="marketEdit_cont">
            <EditIcon
              style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              onClick={() => handleEditClick(row.channelId, row.nickname)}
            />
          </div>
        </div>
      ),
      width: '280px',
    },
    {
      name: t('sellerId'),
      selector: row => row.sellerId,
      // width: "170px",
    },
    {
      name: t('marketplace'),
      selector: row => row.title,
      width: "180px",
    },
    {
      name: t('status'),
      selector: row => row.isActive === 1 ? 'Active' : 'Inactive',
      width: "100px",
    },
    {
      name: t('lastUpdated'),
      selector: row => row.lastsyncdate,
      // width: "170px",
    },
    {
      name: t('action'),
      selector: row => < div className="actionsButtonsCont" >
        <div className="deleteBtn_cont" onClick={() => { deleteMarket(row.channelId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
      </div>,
      width: "100px",
    }
  ];

  // F E T C H   M A R K E T P L A C E S
  useEffect(() => {
    fetch(marketplace_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          setViewMarketLoader(false);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setViewMarketLoader(false);
        setMarketplaceArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken, deleteMarketId, nickNameUpdated]);

  // D E L E T E   M A R K E T
  const deleteMarket = async (id) => {
    const deleteMarketJson = JSON.stringify({ channelId: id })
    try {
      const response = await fetch(deleteMarket_URL, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: deleteMarketJson,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      else {
        setDeleteMarketId(id)
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  useEffect(() => {
    if (marketplaceArray) {
      setViewMarketLoader(false);
    }
  }, [marketplaceArray]);

  // H A N D L E   E D I T   B U T T O N   C L I C K
  const handleEditClick = (channelId, currentNickname) => {
    setEditingMarket({ channelId, nickname: currentNickname });
    setNewNickname(currentNickname);
  };

  // E N T E R    T O    S A V E   N I C K N A M E
  const handleKeyPress = (e, channelId) => {
    if (e.key === 'Enter') {
      updateNickname(channelId, newNickname);
    }
  };

  // U P D A T E   N I C K N A M E
  const updateNickname = async (channelId, nickname) => {
    const payload = { channelId, nickname };
    try {
      const response = await fetch(editMarket_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        setEditingMarket(null);
        setNewNickname('');
        setNickNameUpdated(true);
      }
    } catch (error) {
      console.error('Error updating nickname:', error);
    }
  };


  return (
    <div className='viewMarketMain_cont'>
      <Breadcrumbs />
      <div className="marketplaceTableMain_cont">
        <div className='revenueTable_cont'>
          {viewMarketloader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={marketplaceArray}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewMarketplace;