import React, { useState, useEffect, useContext } from 'react';
import './Revenue.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from './TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import RevenueChart from './RevenueChart/RevenueChart';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { RevenueChartContext } from '../../../Context/RevenueChartContext';
import { SelectedMarketplaceContext } from '../../../Context/SelectedMarketplaceContext';
import { RoughStartDateContext } from '../../../Context/RoughStartDateContext';
import { RoughEndDateContext } from '../../../Context/RoughEndDateContext';

const Revenue = () => {
  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const { roughStartDateTime, setRoughStartDateTime } = useContext(RoughStartDateContext);
  const { roughEndDateTime, setRoughEndDateTime } = useContext(RoughEndDateContext);
  const [combinedDateTime, setCombinedDateTime] = useState(null);

  const [revenueData, setRevenueData] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState();
  const [totalValue, setTotalValue] = useState();
  const [revnueLoader, setRevenueLoader] = useState(true);
  const [revnueChartLoader, setRevenueChartLoader] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { revenueChartArray, setRevenueChartArray } = useContext(RevenueChartContext);

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "130px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "130px",
    },
    {
      name: t("orderId"),
      selector: row => row.orderId,
      width: "200px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",
    },
    {
      name: t("totalSale"),
      selector: row => row.revenue,
      width: "120px",
      sortable: true,
    },
    {
      name: t("cogs"),
      selector: row => row.cogs,
      width: "70px",
    },
    {
      name: t("operatingExp"),
      selector: row => row.operatingExpenses,
      width: "90px",
    },
    {
      name: t("promotion"),
      selector: row => row.promotion,
      width: "90px",
    },
    {
      name: t("other"),
      selector: row => row.other,
      width: "90px",
    },
    {
      name: t("netProfit"),
      selector: row => row.netProfit,
      width: "120px",
      sortable: true,
    },
    {
      name: t("unitsShipped"),
      selector: row => row.unitShipped,
      width: "90px",
    },
    {
      name: t("refund"),
      selector: row => row.refundRate,
      width: "90px",

    },
  ];

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDateTime) {
      const formattedStartDate = roughStartDateTime.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDateTime) {
      const formattedEndDate = roughEndDateTime.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDateTime, roughStartDateTime]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDateTime(startDate + " - " + endDate);
    }
  }, [startDate, endDate]);

  const revenueTable_URL = `${baseUrl}/zumi/api/salesrevenue?dateRange=${combinedDateTime}`;
  const revenueChart_URL = `${baseUrl}/zumi/api/sales/revenuchart?dateRange=${combinedDateTime}`;

  // F E T C H    R E V E N U E    T A B L E   D A T A
  useEffect(() => {
    setRevenueLoader(true);
    if (combinedDateTime) {
      const fetchData = async () => {
        try {
          const response = await fetch(revenueTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            setRevenueLoader(false);
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueLoader(false);
          setRevenueData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setRevenueLoader(false);
        }
      };
      fetchData();
    }
  }, [combinedDateTime, selectedMarketplace]);

  // F E T C H    R E V E N U E    C H A R T   D A T A
  useEffect(() => {
    setRevenueChartLoader(true);
    if (combinedDateTime) {
      const fetchData = async () => {
        try {
          const response = await fetch(revenueChart_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            setRevenueChartLoader(false);
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRevenueChartLoader(false);
          setRevenueChartArray(result);
        } catch (error) {
          console.log(error);
        } finally {
          setRevenueChartLoader(false);
        }
      };
      fetchData();
    }
  }, [combinedDateTime, selectedMarketplace]);

  // F U N C T I O N   T O   C A L C U L A T E
  useEffect(() => {
    if (revenueChartArray) {
      const quantitySum = revenueChartArray.reduce((acc, item) => acc + (item.quantity || 0), 0);
      setTotalQuantity(quantitySum);
      const valueSum = revenueChartArray.reduce((acc, item) => acc + (item.value || 0), 0);
      const roundedValueSum = parseFloat(valueSum.toFixed(3));
      setTotalValue(roundedValueSum);
    }
  }, [revenueChartArray, combinedDateTime]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={roughStartDateTime}
              onChange={setRoughStartDateTime}
              maxDate={dayjs()}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDateTime}
              disabled={!roughStartDateTime}
              onChange={setRoughEndDateTime}
              maxDate={dayjs()}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="revenueMainChartMain_cont">
        <div className="revenueMainChart_cont">
          <div className="dcont_heading">
            <span>{t("revenueChartHead")}</span>
            <h5>
              {t("quantity")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalQuantity}</span>
              {t("value")}: <span className="firstrowdata" style={{ marginRight: "20px" }}>{totalValue} {"AUD"}</span>
            </h5>
          </div>
          <div className="revenueMainChart">
            {revnueChartLoader ? (
              <p>Loading...</p>
            ) : (
              <RevenueChart />
            )}
          </div>
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {revnueLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={revenueData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
