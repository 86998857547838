import React, { useEffect, useState } from 'react';
import './EditUser.css';

import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

const EditUser = ({ roles, userToBeUpdated, updateUsersData, showAlert, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const editUser_URL = `${baseUrl}/zumi/api/users/register`;
  const [updateUsers, setUpdateUsers] = useState(false);
  // const [activeTab, setActiveTab] = useState(userToBeUpdated.status);
  const [editUser, setEditUser] = useState({
    userId: userToBeUpdated.userId,
    contactPerson: userToBeUpdated.contactPerson,
    emailId: userToBeUpdated.emailId,
    contactNumber: userToBeUpdated.contactNumber,
    roleId: userToBeUpdated.roleId,
    status: userToBeUpdated.status,
    newPassword: ""
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditUser((prev) => ({ ...prev, [name]: value }));
    // console.log("EditUser after change:", { ...editUser, [name]: value }); // Log after change
  };

  // H A N D L E   S U B M I T
  const handleEditUser = async (e) => {
    e.preventDefault();
    // console.log("Edit user details :", editUser);

    try {
      const response = await fetch(editUser_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editUser),
      });
      if (response.ok) {
        setUpdateUsers(!updateUsers);
        updateUsersData(updateUsers);
        closeModal();
        showAlert("User edited successfully.", '#d4f4e6', '#77d98b');

      } else {
        closeModal();
        showAlert("User not edited.", '#FFE4C0', '#FD5D5D');
      }
    } catch (error) {
      console.error('Error:', error);
      closeModal();
      showAlert("User not edited due to an error.", '#FFE4C0', '#FD5D5D');
    }
    finally {
      setUpdateUsers(!updateUsers);
      setEditUser({
        userId: "",
        contactPerson: "",
        emailId: "",
        contactNumber: "",
        roleId: "",
        status: "",
        newPassword: "",
      })
      closeModal();
    }
  };

  const mandatoryError = !editUser.userId || !editUser.contactPerson || !editUser.emailId || !editUser.contactNumber || !editUser.roleId || !editUser.status;


  return (
    <div className='editUserMain_cont'>
      <div className="newRoleHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('editUser')}
          </span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="editUserFormCont">
        <div className="newUserRow">
          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="firstName" className="welcomeFormLabel">{t('firstName')}  <span className="asterisk"></span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="contactPerson"
              id="outlined-basic"
              placeholder="First Name"
              variant="outlined"
              value={editUser.contactPerson}
              onChange={handleChange}
            />
          </div>

          <div className="newUserFormInput_cont">
            <label htmlFor="email" className="welcomeFormLabel">{t('email')}  <span className="asterisk"></span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='email'
              name="emailId"
              id="outlined-basic"
              disabled
              placeholder="Enter Email"
              variant="outlined"
              value={editUser.emailId}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* R O W 2 */}
        <div className="newUserRow">

          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="phone" className="welcomeFormLabel">{t('phone')}  <span className="asterisk"></span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='number'
              name="contactNumber"
              id="outlined-basic"
              placeholder="Enter phone number"
              variant="outlined"
              value={editUser.contactNumber}
              onChange={handleChange}
            />
          </div>
          <div className="newUserFormInput_cont">
            <label htmlFor="password" className="welcomeFormLabel">{t('role')}  <span className="asterisk"></span></label>
            <Select
              style={{ borderRadius: '10px' }}
              className='regionSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="roleId"
              value={editUser.roleId}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{t('selectRole')}</em>;
                }

                const selectedRole = roles.find(item => item.roleId === selected);
                return selectedRole ? selectedRole.roleName : <em>{t('selectRole')}</em>;
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set your desired height
                    overflowY: 'auto',
                  },
                },
              }}
            >

              {roles.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.roleId}>{item.roleName}</MenuItem>
                )
              })}
            </Select>
          </div>
        </div>

        {/* R O W 3 */}
        <div className="newUserRow">

          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="role" className="welcomeFormLabel">{t('status')}  <span className="asterisk"></span></label>
            <Select
              style={{ borderRadius: '10px' }}
              className='regionSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={editUser.status}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected === 1) {
                  return "Active";
                }
                if (selected === 0) {
                  return "Inactive";
                }
                return selected;
              }}
            >

              <MenuItem value={1}>{t('active')}</MenuItem>
              <MenuItem value={0}>{t('inactive')}</MenuItem>
            </Select>
          </div>

          <div className="newUserFormInput_cont">
            <label htmlFor="newPass" className="welcomeFormLabel">{t('createNewPass')}  <span className="asterisk"></span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="newPassword"
              id="outlined-basic"
              placeholder="New password"
              variant="outlined"
              value={editUser.newPassword}
              onChange={handleChange}
            />
          </div>

        </div>
        <div className="updateUserBtn_cont">
          <button className='btnFill' onClick={handleEditUser}>{t('updateUser')}</button>
          {/* {mandatoryError && (
            <p style={{ color: 'red', fontSize: "12px", }}>Please fill the mandatory fields.</p>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default EditUser;