import React, { useContext, useEffect, useState } from "react";
import './CreateTemplate.css';
import propTypes from 'prop-types';
import whatsappScreen from '../../../assets/whatsappScreen.png';
import whatsappBusiness from '../../../assets/whatsappBusiness.jpg';
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import ImageIcon from '@mui/icons-material/Image';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { SegmentContext } from "../../../Context/SegmentContext";
import { TemplateContext } from "../../../Context/TemplateContext";


const CreateTemplate = ({ updateTemplateData, closeModal, broadcastTemplateId, templateData }) => {

  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userId = localStorage.getItem("userId");

  const createTemplate_URL = `${baseUrl}/whatsapp/template`
  const { segmentArray } = useContext(SegmentContext);
  const { templateArray } = useContext(TemplateContext);
  const [updateTemplates, setUpdateTemplates] = useState(false);


  const [dialCode, setDialCode] = useState(91);
  const [phone, setPhone] = useState();
  const [spaceError, setSpaceError] = useState('');
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [msisdnError, setMsisdnError] = useState(false);

  const [buttonType, setButtonType] = useState("None");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageTypeError, setImageTypeError] = useState('');
  const [imageSizeError, setImageSizeError] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoTypeError, setVideoTypeError] = useState('');
  const [videoSizeError, setVideoSizeError] = useState('');
  const [videoSrc, setVideoSrc] = useState(null);
  // const [selectedHeaderFormat, setSelectedHeaderFormat] = useState('');
  const [disableInputField, setDisabledInputField] = useState(false);
  const [selectedSegmentId, setSelectedSegmentId] = useState('');
  const [addVariableVisibility, setAddVariableVisibility] = useState(false);
  const [createVariableContVisibility, setCreateVariableContVisibility] = useState(false);
  const [broadcastTemplateID, setBroadcastTemplateID] = useState(broadcastTemplateId);
  const displayBroadcastElm = templateData.find(item => item.messageId === broadcastTemplateID);


  const [headerCount, setHeaderCount] = useState(0);
  const [messageBodyCount, setMessageBodyCoun] = useState(0);
  const [footerCount, setFooterCount] = useState(0);
  const [msisdnTextCount, setMsisdnTextCount] = useState(0);
  const [msisdnCount, setMsisdnCount] = useState(0);
  const [websiteTextCount, setWebsiteTextCount] = useState(0);
  const [websiteUrlCount, setWebsiteUrlCount] = useState(0);
  const [quickReply1Count, setQuickReply1Count] = useState(0);
  const [quickReply2Count, setQuickReply2Count] = useState(0);
  const [quickReply3Count, setQuickReply3Count] = useState(0);

  const [inputData, setInputData] = useState({
    templateName: '',
    category: '',
    language: '',
    headerformat: '',
    header: '',
    variable1: '',
    variable2: '',
    variable3: '',
    messageBody: '',
    footer: '',
    msisdn_text: '',
    msisdn: '',
    website_text: '',
    website_URL: '',
    quickReply1: '',
    quickReply2: '',
    quickReply3: '',
    userID: userId
  });

  const [languageItems, setLanguageItems] = useState([
    { id: 1, name: 'English', value: 'en' },
    { id: 2, name: 'English (US)', value: 'en_US' },
    { id: 3, name: 'English (UK)', value: 'en_UK' },
  ]);

  const [categoryItems, setCategoryItems] = useState([
    { id: 1, name: 'Marketing', value: 'Marketing' },
    // { id: 2, name: 'Utility', value: 'Utility' },
  ]);

  const [headerFormats, setHeaderFormats] = useState([
    { id: 1, name: t('text'), value: 'text' },
    { id: 2, name: t('image'), value: 'image' },
    { id: 3, name: t('video'), value: 'video' },
  ]);

  const toggleVariableVisibility = () => {
    setAddVariableVisibility(!addVariableVisibility);
  }

  // F U N C T I O N   T O   A P P E N D   V A R I A B L E S 
  const appendToMessage = (variable) => {
    const textarea = document.querySelector('.templateMsgBody textarea'); // Adjust for TextField
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const newMessageBody =
      inputData.messageBody.slice(0, start) +
      variable +
      inputData.messageBody.slice(end);

    setInputData({ ...inputData, messageBody: newMessageBody });

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = start + variable.length;
      textarea.focus();
    }, 0);
  };

  // H A N D L E   C H A N G E 

  const handleChange = (e) => {
    if (broadcastTemplateID)
      return;
    let value = e.target.value;
    const name = e.target.name;

    // checking template name already exists or not
    const nameExists = templateArray.some(item => item.templateName === value);
    if (nameExists) {
      setNameError(t('nameError'));
    } else {
      setNameError('');
    }
    // Validate templateName with no space and no capital letters
    if (name === 'templateName') {
      value = value.replace(/[-\s]+/g, '_');
      value = value.toLowerCase();
    }

    // Validate header length if the field is 'header'
    if (name === 'header') {
      if (value.length > t("maxHeaderLen")) {
        return;
      }
      setHeaderCount(value.length);
    }
    // Validate footer length if the field is 'footer'
    if (name === 'footer') {
      if (value.length > t("maxFooterLen")) {
        return;
      }
      setFooterCount(value.length);
    }
    // Validate message body length if the field is 'messageBody'
    if (name === 'messageBody') {
      if (value.length > t("maxMessageLen")) {
        return;
      }
      setMessageBodyCoun(value.length);
    }
    // Validate msisdn label length if the field is 'msisdn_text'
    if (name === 'msisdn_text') {
      if (value.length > t("maxWebsiteLabelLen")) {
        return;
      }
      setMsisdnTextCount(value.length);
    }
    // Validate websit label length if the field is 'website_text'
    if (name === 'website_text') {
      if (value.length > t("maxWebsiteLabelLen")) {
        return;
      }
      setWebsiteTextCount(value.length);
    }
    // Validate websit URL length if the field is 'website_URL'
    if (name === 'website_URL') {
      if (value.length > t("maxWebsiteUrlLen")) {
        return;
      }
      setWebsiteUrlCount(value.length);
    }
    // Validate quick reply 1 length if the field is 'quickReply1'
    if (name === 'quickReply1') {
      if (value.length > t("maxQuickReplyLen")) {
        return;
      }
      setQuickReply1Count(value.length);
    }
    // Validate quick reply 2 length if the field is 'quickReply2'
    if (name === 'quickReply2') {
      if (value.length > t("maxQuickReplyLen")) {
        return;
      }
      setQuickReply2Count(value.length);
    }
    // Validate quick reply 3 length if the field is 'quickReply3'
    if (name === 'quickReply3') {
      if (value.length > t("maxQuickReplyLen")) {
        return;
      }
      setQuickReply3Count(value.length);
    }


    // SETTING INPUT DATA
    setInputData({ ...inputData, [name]: value })
  }

  // M S I S D N   H A N D L E R 
  const handlePhoneChange = (e) => {
    let value = e.target.value;

    let phoneValue = value.replace(/\D/g, '');

    // Check if phone number is within the allowed length
    if (phoneValue.length <= t("maxMsisdnLen")) {
      setPhone(phoneValue);
      setMsisdnCount(phoneValue.length);
      setInputData({
        ...inputData,
        msisdn: dialCode + phoneValue
      });
    }

  }

  // I M A G E   H A N D L E R 
  const selectedImageHandler = (event) => {
    const image = event.target.files[0];
    console.log(image.type);

    if (image) {
      // Define allowed image types
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      // Define the maximum allowed size (in bytes)
      const maxSize = t("maxImageHeaderSize") * 1024 * 1024; // 30 MB

      if (validTypes.includes(image.type)) {
        if (image.size <= maxSize) {
          setSelectedImage(image);
          setImageTypeError('');

          const reader = new FileReader();

          reader.onloadend = () => {
            setImageSrc(reader.result);
          };

          reader.readAsDataURL(image);
          setImageTypeError('');
          setImageSizeError('');
        } else {
          setImageSrc(null);
          setSelectedImage(null);
          setImageSizeError(t("imageSizeError"));
        }
      } else {
        setImageSrc(null);
        setSelectedImage(null);
        setImageTypeError(t("imageError"));
      }
    } else {
      setSelectedImage(null);
      setImageSrc(null);
      setImageTypeError("");
      setImageSizeError("");
    }
  };

  // V I D E O   H A N D L E R 
  const selectedVideoHandler = (event) => {
    const video = event.target.files[0];

    if (video) {
      // Check if the file type is MP4
      if (video.type !== 'video/mp4') {
        // Set error state if not MP4
        setVideoTypeError('Video should be MP4.');
        setSelectedVideo(null);
        setSelectedImage(null);
        setVideoSrc(null);
      } else if (video.size > t("maxVideoHeaderSize") * 1024 * 1024) {
        setVideoSizeError('Videp should be less than 30 MB.');
        setSelectedVideo(null);
        setSelectedImage(null);
        setVideoSrc(null);
      } else {
        // Clear any previous errors
        setVideoSizeError(null);
        setVideoTypeError(null);

        // Create a URL for the video file
        const url = URL.createObjectURL(video);
        setSelectedVideo(video);
        setVideoSrc(url);
        setInputData({
          ...inputData,
          header: ''
        });

        // Clean up the URL object when the component unmounts
        return () => URL.revokeObjectURL(url);
      }
    } else {
      // Handle case where no file is selected
      setSelectedVideo(null);
      setSelectedImage(null);
      setVideoSrc(null);
    }
  };

  // D I A L   C O D E    H A N D L E R 
  const handleDialCodeChange = (e) => {
    setDialCode(e.target.value);
  }

  // S E G M E N T   I D   C H A N G E 
  const handleSegmentIdChange = (e) => {
    setSelectedSegmentId(e.target.value);
  }

  // Validation for header
  useEffect(() => {
    if (inputData.header) {
      setSelectedImage(null);
      setSelectedVideo(null);
      setImageSrc(null);
      setVideoSrc(null);
    }
  }, [inputData])

  // Validation for image
  useEffect(() => {
    if (imageSrc) {
      setVideoSrc(null);
      setInputData({
        ...inputData,
        header: ''
      })
    }
  }, [imageSrc, inputData]);

  // Validation for video
  useEffect(() => {
    if (videoSrc) {
      setImageSrc(null);
      setInputData({
        ...inputData,
        header: ''
      })
    }
  }, [videoSrc, inputData]);


  const handleButtonTypeChange = (e) => {
    setButtonType(e.target.value);
  }

  useEffect(() => {
    if (buttonType === "callToAction") {
      setInputData({
        ...inputData,
        quickReply1: "",
        quickReply2: "",
        quickReply3: ""
      })
    }
    if (buttonType === "quickReply") {
      setInputData({
        ...inputData,
        msisdn_text: '',
        msisdn: '',
        website_text: '',
        website_URL: '',
      });
      setPhone();
    }
  }, [inputData, buttonType])


  //  S E T    M A N D A T O R Y    E R R O R 
  const mandatoryError = !inputData.templateName || !inputData.category || !inputData.language || !inputData.messageBody;

  //  S E T   B R O A D C A S T   T E M P L A T E 
  useEffect(() => {

    if (broadcastTemplateID === null) {
      setInputData(
        {
          templateName: '',
          category: '',
          language: '',
          headerformat: 'text',
          header: '',
          variable1: '',
          variable2: '',
          variable3: '',
          messageBody: '',
          footer: '',
          msisdn_text: '',
          msisdn: '',
          website_text: '',
          website_URL: '',
          quickReply1: '',
          quickReply2: '',
          quickReply3: '',
          userID: userId
        }
      );
    }
    else {
      setDisabledInputField(true);
      setInputData(
        {
          templateName: displayBroadcastElm.templateName,
          category: displayBroadcastElm.category,
          language: displayBroadcastElm.language,
          headerformat: displayBroadcastElm.headerformat,
          header: displayBroadcastElm.header,
          variable1: displayBroadcastElm.variable1,
          variable2: displayBroadcastElm.variable2,
          variable3: displayBroadcastElm.variable3,
          messageBody: displayBroadcastElm.messageBody,
          footer: displayBroadcastElm.footer,
          msisdn_text: displayBroadcastElm.msisdn_text,
          msisdn: displayBroadcastElm.msisdn,
          website_text: displayBroadcastElm.website_text,
          website_URL: displayBroadcastElm.website_URL,
          quickReply1: displayBroadcastElm.quickReply1,
          quickReply2: displayBroadcastElm.quickReply2,
          quickReply3: displayBroadcastElm.quickReply3,
        }
      );
    }
  }, [])

  // C R E A T E    T E M P L A T E 

  const formData = new FormData();
  const onSubmit = (e) => {
    e.preventDefault();
    const jsonData = JSON.stringify(inputData);

    formData.append('jsonData', jsonData);
    formData.append('image', selectedImage);
    formData.append('video', selectedVideo);

    for (let entry of formData.entries()) {
      console.log(entry[0] + ': ' + entry[1]);
    }

    fetch(createTemplate_URL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
      body: formData,
    }).then(response => {
      if (response.ok) {
        setUpdateTemplates(!updateTemplates);
        updateTemplateData(updateTemplates);
      } else {
        // Handle different status codes
        let message = `Error: ${response.status} ${response.statusText}`;
        alert(message);
        throw new Error(message);
      }
    })

    setInputData({
      templateName: '',
      category: '',
      language: '',
      headerformat: '',
      header: '',
      variable1: '',
      variable2: '',
      variable3: '',
      messageBody: '',
      footer: '',
      msisdn_text: '',
      msisdn: '',
      website_text: '',
      website_URL: '',
      quickReply1: '',
      quickReply2: '',
      quickReply3: '',
    })
    closeModal();
  }

  // B R O A D C A S T   M E S S A G E 
  const broadCastMessage = (e) => {
    // e.preventDefault();
    fetch(`${baseUrl}/whatsapp/updatetemplate?messageId=${broadcastTemplateID}&segmentId=${selectedSegmentId}`, {
      method: 'POST',
    }).then(response => {
      if (response.ok) {
        alert(t("messageSentAlert"));
        // return response.json(); 
      } else {
        // Handle different status codes
        let message = `Error: ${response.status} ${response.statusText}`;
        alert(message);
        throw new Error(message); // Optionally throw an error to stop further processing
      }
    })
    closeModal();
  }


  //  C U R R E N T   D A T E   A N D   T I M E 
  var showDate = new Date();
  var displayTime = showDate.getHours() + ":" + showDate.getMinutes();


  return (
    <div className='newTemplate_cont'>
      <div className="newTemplateHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('createTemplateHeader')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div className="mainContent_cont">

        {/* ------------------- */}

        <div className="content_cont">

          {/* ------------------- */}

          <div className="tcl_cont">

            {/* T E M P L A T E   N A M E */}

            <div className="templateMain_cont">
              <div className="templateName_cont">{t('templateName')} <span className="asterisk">*</span></div>
              <div className="templateNameMob_cont broadcastHeading_cont">
                <h5><b>{t('templateName')}</b></h5>
              </div>
              <div className="templateInput_cont">
                <TextField
                  name="templateName"
                  value={inputData.templateName}
                  onChange={handleChange}
                  id="outlined-basic"
                  placeholder={t('templatePlaceholder')}
                  variant="outlined"
                  disabled={disableInputField}
                />
              </div>
              <div className="templateError_cont">
                {spaceError && <p style={{ color: 'red', fontSize: "12px" }}>{spaceError}</p>}
                {hasCapitalLetter && <p style={{ color: 'red', fontSize: "12px" }}>{t('capitalNot')}</p>}
                {nameError && <p style={{ color: 'red', fontSize: "12px" }}>{nameError}</p>}
              </div>

            </div>

            {/* S E L E C T   C A T E G O R Y */}

            <div className="templateMain_cont">
              <div className="templateName_cont">{t('category')} <span className="asterisk">*</span></div>
              <div className="templateNameMob_cont broadcastHeading_cont">
                <h5><b>{t('templateName')}</b></h5>
              </div>
              <div className="templateInput_cont">
                <Select
                  fullWidth
                  className='categorySelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='category'
                  value={inputData.category}
                  onChange={handleChange}
                  disabled={disableInputField}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t('categoryPlaceholder')}</em>;
                    }
                    if (selected === "Marketing") {
                      return "Marketing"
                    }
                    if (selected === "MARKETING") {
                      return "Marketing"
                    }
                    if (selected === "Utility") {
                      return "Utility"
                    }
                    if (selected === "UTILITY") {
                      return "Utility"
                    }
                  }}
                >
                  {categoryItems.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="templateError_cont">
                {/* {spaceError && <p style={{ color: 'red', fontSize: "12px" }}>{spaceError}</p>}
                {hasCapitalLetter && <p style={{ color: 'red', fontSize: "12px" }}>Capital letters are not allowed.</p>} */}
              </div>

            </div>

            {/* S E L E C T   L A N G U A G E   */}

            <div className="templateMain_cont">
              <div className="templateName_cont">{t('language')} <span className="asterisk">*</span></div>
              <div className="templateNameMob_cont broadcastHeading_cont">
                <h5><b>{t('templateName')}</b></h5>
              </div>
              <div className="templateInput_cont">
                <Select
                  fullWidth
                  className='languageSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='language'
                  value={inputData.language}
                  onChange={handleChange}
                  disabled={disableInputField}

                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t('languagePlaceholder')}</em>;
                    }
                    if (selected === "en") {
                      return "English"
                    }
                    if (selected === "en_US") {
                      return "English (US)"
                    }
                    if (selected === "en_UK") {
                      return "English (UK)"
                    }

                  }}
                >
                  {languageItems.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="templateError_cont">
                {/* {spaceError && <p style={{ color: 'red', fontSize: "12px" }}>{spaceError}</p>}
                {hasCapitalLetter && <p style={{ color: 'red', fontSize: "12px" }}>Capital letters are not allowed.</p>} */}
              </div>
            </div>
          </div>

          {/* ------------------- */}

          <div className="headerMain_cont">
            <div className="broadcastHeading_cont">
              <h4><b>{t('broadcastTitle')}</b> <span className="optionalSpan">({t('optional')})</span></h4>
              <p className="ptag">{t('headerPara')}</p>
            </div>

            <div className="selectHeaderType_cont">
              <Select
                className="headerFormatSelect"
                name='headerformat'
                value={inputData.headerformat}
                onChange={handleChange}
                disabled={disableInputField}
              >
                {
                  headerFormats.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>

            {inputData.headerformat === 'text' && <div className="textHeader_cont">
              <TextField
                fullWidth
                name="header"
                value={inputData.header}
                onChange={handleChange}
                id="outlined-basic"
                placeholder={t('headerPlaceholder')}
                variant="outlined"
                disabled={disableInputField}
              />
              <div className="spanCountCont">
                <p style={{ fontSize: "12px" }}>{headerCount}/{t("maxHeaderLen")}</p>
              </div>
            </div>}

            {inputData.headerformat === 'image' &&
              <div className="imgHeader_cont">
                <h5>{t('imageHeaderHeading')}</h5>
                <p className="ptag">{t('imageHeaderPara')}</p>

                <div className="uploadInput_cont uploadImgBtn_cont">
                  <input
                    type='file'
                    accept=".jpg, jpeg, .png"
                    className='uploadFileBtn'
                    name="imageFile"
                    id="uploadBtn"
                    onChange={selectedImageHandler}
                  />
                  <label htmlFor="uploadBtn" className='uploadImgLabel'>
                    <ImageIcon style={{ marginRight: "5px" }} />
                    {t('selectImgBtn')}
                  </label>
                  <div className="fileName_cont" id="fileName_cont">
                    {selectedImage ? (
                      <p><span className='fileName'>{selectedImage.name}</span> </p>
                    ) : <p className='nofile'>{t("noFileSelected")}</p>}
                  </div>

                  {imageTypeError && <p style={{ color: "red", fontSize: "13px" }}>{imageTypeError}</p>}
                  {imageSizeError && <p style={{ color: "red", fontSize: "13px" }}>{imageSizeError}</p>}
                </div>
              </div>}

            {inputData.headerformat === 'video' &&
              <div className="videoHeader_cont">
                <h5>{t('imageHeaderHeading')}</h5>
                <p className="ptag">{t('imageHeaderPara')}</p>

                <div className="uploadInput_cont uploadImgBtn_cont">
                  <input type='file' accept=".mp4" className='uploadFileBtn' name="videoFile" id="uploadBtn" onChange={selectedVideoHandler} />
                  <label htmlFor="uploadBtn" className='uploadImgLabel'><PlayCircleIcon style={{ marginRight: "5px" }} />{t('selectVideoBtn')}</label>
                  <div className="fileName_cont" id="fileName_cont">
                    {selectedVideo ? (
                      <p><span className='fileName'>{selectedVideo.name}</span> </p>
                    ) : <p className='nofile'>{t("noFileSelected")}</p>}
                  </div>

                  {videoTypeError && <p style={{ color: "red", fontSize: "13px" }}>{videoTypeError}</p>}
                  {videoSizeError && <p style={{ color: "red", fontSize: "13px" }}>{videoSizeError}</p>}
                </div>
              </div>}
          </div>


          {/* ------------------- */}


          <div className="bodyMain_cont">
            <div className="broadcastHeading_cont">
              <h4><b>{t('messageBody')}</b> <span className="asterisk">*</span></h4>
              <p className="ptag">{t('messageBodyPara')}</p>
            </div>

            <div className="addVariable_cont">
              <div className="addVariableBtns_cont">
                <button className="btnNotFill" target="_self" onClick={toggleVariableVisibility}>Add Variable</button>
                {/* <button className="btnNotFill" target="_self" onClick={() => { setCreateVariableContVisibility(true) }} style={{ marginLeft: "20px" }}>Create Variable</button> */}
              </div>

              {addVariableVisibility &&
                <div className="addVariableCont">
                  <div className="variableCont" onClick={() => appendToMessage('{{name}}')}>name</div>
                  <div className="variableCont" onClick={() => appendToMessage('{{phone}}')}>phone</div>
                </div>
              }

              {createVariableContVisibility &&
                <div className="createVariableCont">
                  <div className="createVarCont">
                    <div className="createVarHead">
                      <h5>Variable:</h5>
                    </div>
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      id="outlined-size-small"
                      placeholder="Enter variable name..."
                      value=""
                      size="small"
                    />
                  </div>
                  <div className="createVarCont">
                    <div className="createVarHead">
                      <h5>Value: </h5>
                    </div>
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      id="outlined-size-small"
                      placeholder="Enter variable value..."
                      value=""
                      size="small"
                    />
                  </div>
                  <div className="createVarBtnCont">
                    <button className="btnNotFill">Create Variable</button>
                  </div>
                </div>
              }
            </div>

            <div className="templateMsgMain_cont">
              <div className="editor_cont"></div>
              <div className="templateMsg_cont">
                <TextField
                  multiline
                  fullWidth
                  rows={6}
                  variant="outlined"
                  type="text"
                  className="templateMsgBody"
                  name="messageBody"
                  onChange={handleChange}
                  value={inputData.messageBody}
                  placeholder={t('messageBodyPlaceholder')}
                  disabled={disableInputField}
                />

                {/* <textarea type="text" rows="8" className="templateMsgInput" name="messageBody" onChange={handleChange} value={inputData.messageBody} placeholder={t('messageBodyPlaceholder')} disabled={disableInputField} /> */}
                <div className="spanCountCont">
                  <p style={{ fontSize: "12px" }}>{messageBodyCount}/{t("maxMessageLen")}</p>
                </div>
              </div>
            </div>

          </div>


          {/* ------------------- */}


          <div className="templateFooterMain_cont">
            <div className="broadcastHeading_cont">
              <h4><b>{t('footer')}</b> <span className="optionalSpan">({t('optional')})</span></h4>
              <p className="ptag">{t('footerPara')}</p>
            </div>
            <div className="templateFooter_cont">
              <TextField
                fullWidth
                InputLabelProps={{ style: { display: 'none' } }}
                name="footer"
                value={inputData.footer}
                onChange={handleChange}
                id="outlined-basic"
                placeholder={t('footerPlaceholder')}
                variant="outlined"
                disabled={disableInputField}
              />
              <div className="spanCountCont">
                <p style={{ fontSize: "12px" }}>{footerCount}/{t("maxFooterLen")}</p>
              </div>
            </div>
          </div>


          {/* ------------------- */}


          <div className="buttonsMain_cont">
            <div className="broadcastHeading_cont">
              <h4><b>{t('buttons')}</b> <span className="optionalSpan">({t('optional')})</span></h4>
              <p className="ptag">{t('buttonsPara')}</p>
            </div>

            <div className="buttons_cont">
              <div className="callToAction_cont">
                <div className="callMobileLabel_cont">
                  <h5>{t('addBtnCall')}</h5>
                </div>

                <div className="callInputs_cont">

                  <Box
                    className="buttonLabelBox"
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="msisdn_text"
                      value={inputData.msisdn_text}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('addBtnPlaceholder')}
                      variant="outlined"
                      disabled={disableInputField}
                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{msisdnTextCount}/{t("maxWebsiteLabelLen")}</p>
                    </div>
                  </Box>


                  <div className="dialNumberCont">
                    <FormControl className="dialFormControl">
                      <Select
                        className="dialSelect"
                        name='dialCode'
                        value={dialCode}
                        onChange={handleDialCodeChange}
                        disabled={disableInputField || !inputData.msisdn_text}

                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === 91) {
                            return "IN";
                          }
                          if (selected === 91) {
                            return "IN";
                          }
                          if (selected === 61) {
                            return "AUS";
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value={91}>IN</MenuItem>
                        <MenuItem value={61}>AUS</MenuItem>

                      </Select>
                    </FormControl>

                    <Box
                      className="mobileNumberBox"
                      component="form"
                      sx={{
                        '& > :not(style)': { width: '100%', background: "#fff" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="number"
                        InputLabelProps={{ style: { display: 'none' } }}
                        name="msisdn"
                        // value={phone}
                        value={!broadcastTemplateId ? phone : inputData.msisdn}
                        onChange={handlePhoneChange}
                        id="outlined-basic"
                        placeholder={t('addMsisdnPlaceholder')}
                        variant="outlined"
                        disabled={disableInputField || !inputData.msisdn_text}
                      />
                      <div className="spanCountCont">
                        <p style={{ fontSize: "12px" }}>{msisdnCount}/{t("maxMsisdnLen")}</p>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>

              <div className="callToAction_cont">
                <div className="websiteLabel_cont">
                  <h5>{t('addBtnWeb')}</h5>
                </div>
                <div className="websiteInputs_cont">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="website_text"
                      value={inputData.website_text}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('addWebPlaceholder')}
                      variant="outlined"
                      disabled={disableInputField}

                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{websiteTextCount}/{t("maxWebsiteLabelLen")}</p>
                    </div>
                  </Box>
                  <Box
                    className="websiteUrlBox"
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="website_URL"
                      value={inputData.website_URL}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('addWebUrlPlaceholder')}
                      variant="outlined"
                      disabled={disableInputField || !inputData.website_text}
                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{websiteUrlCount}/{t("maxWebsiteUrlLen")}</p>
                    </div>
                  </Box>
                </div>
              </div>


              <div className="quickReplyCont">
                <div className="quickReplyLabel_cont">
                  <h5>{t('addBtnQuick')}</h5>
                </div>
                <div className="quickReplyInputs_cont">

                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="quickReply1"
                      value={inputData.quickReply1}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('quickReply1')}
                      variant="outlined"
                      disabled={disableInputField}
                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{quickReply1Count}/{t("maxQuickReplyLen")}</p>
                    </div>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="quickReply2"
                      value={inputData.quickReply2}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('quickReply2')}
                      variant="outlined"
                      disabled={disableInputField}
                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{quickReply2Count}/{t("maxQuickReplyLen")}</p>
                    </div>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%', background: "#fff" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="quickReply3"
                      value={inputData.quickReply3}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder={t('quickReply3')}
                      variant="outlined"
                      disabled={disableInputField}

                    />
                    <div className="spanCountCont">
                      <p style={{ fontSize: "12px" }}>{quickReply3Count}/{t("maxQuickReplyLen")}</p>
                    </div>
                  </Box>
                </div>

              </div>
            </div>
          </div>


          {/* S E G M E N T S    F O R    B R O A D C A S T I N G  */}

          {
            broadcastTemplateID &&
            <div className="segmentListMain_cont">
              <div className="broadcastSeg_cont1">
                <div className="broadcastHeading_cont">
                  <h4><b>Select Segment</b></h4>
                  <p className="ptag">{t('selectSegmentPara')}</p>
                </div>
                <div className="segmentDropdown_cont">
                  <FormControl className="selectSegmentForm">
                    <Autocomplete
                      options={segmentArray}
                      getOptionLabel={(option) => option.segmentName}
                      value={segmentArray.find(segment => segment.segmentId === selectedSegmentId) || null}
                      onChange={(event, newValue) => {
                        handleSegmentIdChange({
                          target: { name: 'segmentNames', value: newValue ? newValue.segmentId : '' }
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={t("selectSegmentPlaceholder")}
                          style={{ borderRadius: '10px' }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.segmentId === value.segmentId}
                      style={{ width: '100%' }} // Full width for better styling
                    />
                  </FormControl>
                </div>
              </div>

              <div className="broadcastOr_cont centerElm">
                <p className="broadcastOr">OR</p>
              </div>

              <div className="broadcastSeg_cont2">
                <div className="broadcastHeading_cont">
                  <h4><b>Select Audience</b></h4>
                  <p className="ptag">{t('selectAudiencePara')}</p>
                </div>
                <div className="segmentDropdown_cont">
                  <FormControl className="selectSegmentForm">

                    <Autocomplete
                      options={segmentArray}
                      getOptionLabel={(option) => option.segmentName}
                      value={segmentArray.find(segment => segment.segmentId === selectedSegmentId) || null}
                      onChange={(event, newValue) => {
                        handleSegmentIdChange({
                          target: { name: 'segmentNames', value: newValue ? newValue.segmentId : '' }
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Audience"
                          style={{ borderRadius: '10px' }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.segmentId === value.segmentId}
                      style={{ width: '100%' }} // Full width for better styling
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          }


          {/* S U B M I T   B U T T O N S */}
          <div className="submitBtn_cont">
            <div className="submitErrorCont">

              {nameError && <p style={{ color: 'red', fontSize: "12px", marginRight: "10px" }}>{nameError}</p>}
              {spaceError && <p style={{ color: 'red', fontSize: "12px", marginRight: "10px" }}>{spaceError}</p>}
              {hasCapitalLetter &&
                <p style={{ color: 'red', fontSize: "12px", marginRight: "10px" }}>{t('capitalNot')}</p>
              }

              {mandatoryError && (
                <p style={{ color: 'red', fontSize: "12px" }}>{t('mandatory')}</p>
              )}

            </div>
            {
              broadcastTemplateID && <Link to='/home/whatsapp/message-history' className="btnFill" style={{ textDecoration: "none" }} onClick={broadCastMessage}>{t('send')}</Link>
            }
            {
              !broadcastTemplateID && <button className="btnFill" disabled={!inputData.templateName || !inputData.category || !inputData.language || !inputData.messageBody || spaceError || hasCapitalLetter || nameError} target="" onClick={onSubmit}>{t('submit')}</button>
            }
          </div>
        </div >



        {/* O U T P U T   P R E V I E W  C O N T A I N E R */}

        <div className="mainOutput_cont" >
          <img src={whatsappScreen} height="100%" width="85%" alt="" />
          <div className="secOutputMain_cont">
            <div className="svgCont">
              <img src={whatsappBusiness} alt="" />
            </div>
            <div className="output_cont">

              {!selectedImage && <div className="outputTitleCont">
                {inputData.header}
              </div>
              }

              {/* IMAGE OUTPUT PREVIEW */}
              {imageSrc && (
                <img
                  src={imageSrc}
                  // src={formData.image}
                  alt="Image Preview"
                  style={{ maxWidth: '100%', maxHeight: '400px', display: 'block' }}
                />
              )}
              {/* VIDEO OUTPUT PREVIEW */}
              {videoSrc && (
                <video
                  controls
                  src={videoSrc}
                  style={{ maxWidth: '100%', maxHeight: '400px', display: 'block' }}
                >
                  Your browser does not support the video tag.
                </video>
              )}

              <p className="outputMsgSpan">{inputData.messageBody}</p>
              <p className="footerspan">{inputData.footer}</p>

              <div className="currentTime_cont">
                <p className="currentTime">{displayTime}</p>
              </div>

              {/* CALL TEXT PREVIEW */}

              {inputData.msisdn_text && (<div className="outputMobile_cont">
                <div className="outputSvg_cont">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>
                </div>
                <p className="outputButtonText">{inputData.msisdn_text}</p>
              </div>)}

              {/* WEBSITE TEXT PREVIEW */}

              {inputData.website_text && (<div className="outputWebsite_cont">
                <div className="outputSvg_cont">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                    <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                  </svg>
                </div>
                <p className="outputButtonText">{inputData.website_text}</p>
              </div>)}
            </div>

            {/* QUICK REPLY 1 PREVIEW */}
            {inputData.quickReply1 && (<div className="outputQuickReply outputQuickReply1">
              <p className="outputButtonText">{inputData.quickReply1}</p>
            </div>)}

            {/* QUICK REPLY 1 PREVIEW */}
            {inputData.quickReply2 && (<div className="outputQuickReply outputQuickReply2">
              <p className="outputButtonText">{inputData.quickReply2}</p>
            </div>)}

            {/* QUICK REPLY 1 PREVIEW */}
            {inputData.quickReply3 && (<div className="outputQuickReply outputQuickReply3">
              <p className="outputButtonText">{inputData.quickReply3}</p>
            </div>)}
          </div>
        </div >
      </div >
    </div >
  )
}
CreateTemplate.propTypes = {
  closeModal: propTypes.func,
};

export default CreateTemplate;