import React, { useState, useEffect } from 'react';
import './Audience.css';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import GroupsIcon from '@mui/icons-material/Groups';
import Diversity2Icon from '@mui/icons-material/Diversity2';

import CreateDataset from './CreateDataset/CreateDataset';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 540,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const templateTableStyles = {
  headRow: {
    style: {
      position: "sticky",
      top: 0,
      color: "rgb(14, 113, 195)",
      width: "100%",
      fontSize: "1rem",
      lineHeight: "24px",
    },
  },
  headCells: {
    style: {
      // border: "1px solid blue",
      padding: "10px",
      textAlign: "left",
    },
  },
  cells: {
    style: {
      // border: "1px solid red",
      padding: "10px",
      fontSize: "0.9rem",
      color: "black",
      lineHeight: "25px",
    },
  }
}


const Audience = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const audience_URL = `https://3pvwejju4h.execute-api.us-east-1.amazonaws.com/zumi/data-set`;

  const [activeTab, setActiveTab] = useState('audience');
  const [audienceLoader, setAudienceLoader] = useState(true);
  const [audienceData, setAudienceData] = useState([]);
  const [searchAudienceQuery, setSearchAudienceQuery] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const columns = [
    {
      name: t('id'),
      selector: row => row.id,
      width: "50px",
    },
    {
      name: t('urn'),
      selector: row => row.urn,
      width: "150px",
    },
    {
      name: t('firstName'),
      selector: row => row.firstName,
      width: "150px",
    },
    {
      name: t('lastName'),
      selector: row => row.surName,
      width: "150px",
    },
    {
      name: t('phone'),
      selector: row => "xxxxx" + row.msisdn.slice(5, 10),
      width: "150px",
    },
    {
      name: t('suburb'),
      selector: row => row.suburb,
      width: "150px",
    },
    {
      name: t('state'),
      selector: row => row.state,
      width: "120px",
    },
    {
      name: t('ageRange'),
      selector: row => row.ageRange,
      width: "150px",
    },
    {
      name: t('gender'),
      selector: row => row.gender,
    },
    {
      name: t('voter'),
      selector: row => row.voters ? "Yes" : "No",
    },

  ];

  // F E T C H    A U D I E N C E
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        const postData = {
          "createdByUserId": userId
        }
        try {
          const response = await fetch(audience_URL, {
            method: 'POST',
            headers: {
              // 'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();

          setAudienceData(result.dataSets);
        } catch (error) {
          console.log(error);
        } finally {
          setAudienceLoader(false);
        }
      };
      fetchData();
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  // H A N D L E   A U D I E N C E   S E A R C H
  const handleAudienceSearch = (e) => {
    setSearchAudienceQuery(e.target.value);
  };

  // S E A R C H   A U D I E N C E   D A T A 
  const filteredAudienceData = React.useMemo(() => {
    const filteredAudienceData = audienceData.filter(item =>
      typeof item.firstName === 'string' &&
      item.firstName.toLowerCase().includes(searchAudienceQuery.toLowerCase())
    );

    return filteredAudienceData;
  }, [audienceData, searchAudienceQuery]);

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };



  return (
    <div className='audience_main'>
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            className={`whatToolsLink ${activeTab === 'audience' ? 'audienceTabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/audience"
          >
            <GroupsIcon style={{ marginRight: "8px" }} /> {t('audience')}
          </Link>
          <Link
            className={`whatToolsLink ${activeTab === 'datasets' ? 'audienceTabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/datasets"
          >
            <Diversity2Icon style={{ marginRight: "8px" }} /> {t('myAudience')}
          </Link>
        </div>
      </div>

      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search Audience'
              onChange={handleAudienceSearch}
            />
            {/* <Select
              size="small"
              className='sortTemplateInput'
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sortTemplateDirection}
              onChange={e => handleTemplateSort(null, e.target.value)}
            >
              {
                sortItem.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                  )
                })
              }
            </Select> */}
          </div>

          <div className="templateRowBtn_cont">
            <button className='btnFill' onClick={handleOpen}>
              {t('createAudience')}
            </button>

            {/* M O D A L   T O   C R E A T E   D A T A S E T */}
            <Modal
              className='templateStyleMobile'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateDataset btnText="Submit" showAlert={showAlert} closeModal={handleClose}></CreateDataset>
              </Box>
            </Modal>

          </div>
        </div>
      </div>


      <div className='tableMain_cont'>
        <div className='table_cont'>
          {audienceLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={filteredAudienceData}
              customStyles={templateTableStyles}
              defaultSortField="created_DateTime"
              pagination
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default Audience;