import React, { useState } from 'react';
import './PriceDrop.css';

import { Link } from 'react-router-dom';

import BuyProduct from '../BuyProduct/BuyProduct';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import DataTable from 'react-data-table-component';
import tableStyles from '../../Sales/Revenue/TableStyles';
import { useTranslation } from 'react-i18next';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const priceDrop = [
  {
    id: 1,
    productInfo: "Gear4 D3O Milan SNAP (MagSafe) - iPhone 13",
    productPrice: "$ 20",
    quantity: "18",
    resellerName: "Zoe Marshall",
    priceDrop: "1",

  },
  {
    id: 2,
    productInfo: "Mophie Snap Adapter Ring Kit, Black (Pack of 2)",
    productPrice: "$ 28",
    quantity: "15",
    resellerName: "Riley Anderson",
    priceDrop: "1",
  },
  {
    id: 3,
    productInfo: "Zagg Iridescent Phone Case for iPhone 14 Plus",
    productPrice: "$ 35",
    quantity: "20",
    resellerName: "Riley Anderson",
    priceDrop: "1",
  },
  {
    id: 4,
    productInfo: "UAG Plyo Case for Samsung Galaxy Z Fold 5",
    productPrice: "$ 19",
    quantity: "30",
    resellerName: "Riley Anderson",
    priceDrop: "1",
  },
  {
    id: 4,
    productInfo: "Maxguard Universal Magnetic Magsafe Car Air",
    productPrice: "$ 42",
    quantity: "8",
    resellerName: "Riley Anderson",
    priceDrop: "1",
  },
]

const PriceDrop = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('priceDrop');
  const handleTabClick = (tab) => setActiveTab(tab); // Set the active tab

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dealsFilter, setDealsFilter] = useState({
    priceRange: "priceRange",
    conditions: "all",
    sortBy: "sortByPrice"
  })

  const columns = [
    {
      name: t('productInfo'),
      selector: row => row.productInfo,
      width: "350px"
    },
    {
      name: t(''),
      selector: row => <div className="todaysDealsTableIcon_cont">

        {row.priceDrop === "1" ? <Tooltip
          arrow
          title="Price Drop"
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <TrendingDownIcon />
        </Tooltip> : ""}
      </div>,
      width: "160px"
    },
    {
      name: t('productPrice'),
      selector: row => row.productPrice,
      width: "120px"
    },
    {
      name: t('quantity'),
      selector: row => row.quantity,
      width: "100px"
    },
    {
      name: t('resellerName'),
      selector: row => row.resellerName,
      width: "130px"
    },
    {
      name: t(''),
      selector: row => <div className="dashLiveBtn_cont">
        <button className='btnFill dashLiveBtn'>Make Offer</button>
        <button className='btnFill dashLiveBtn' onClick={handleOpen} style={{ marginLeft: "8px" }}>Buy Now</button>
      </div>,
    },
  ];

  const handleChange = (e) => {
    setDealsFilter({ ...dealsFilter, [e.target.name]: e.target.value });
  }

  return (
    <div className='priceDropMain'>

      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'home' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/buyer"
          >
            <HomeIcon style={{ marginRight: "8px" }} /> {t('home')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'todaysDeals' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/todays-deals"
          >
            <WhatshotIcon style={{ marginRight: "8px" }} /> {t('todaysDeals')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'priceDrop' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/price-drop"
          >
            <TrendingDownIcon style={{ marginRight: "8px" }} /> {t('priceDrop')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'new-launched' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/new-launched"
          >
            <RocketLaunchIcon style={{ marginRight: "8px" }} /> {t('newLaunched')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'justSold' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/just-sold"
          >
            <CallMadeIcon style={{ marginRight: "8px" }} /> {t('justSold')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'liveOffers' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/live-offers"
          >
            <LocalOfferIcon style={{ marginRight: "8px" }} /> {t('liveOffers')}
          </Link>
        </div>
      </div>

      {/* M O D A L   T O   B U Y    P R O D U C T */}
      <Modal
        className='templateStyleMobile'
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BuyProduct closeModal={handleClose}></BuyProduct>
        </Box>
      </Modal>

      <div className="todaysDeals_cont1">
        <p>Price Drop</p>
        <div className="todaysDealCont1Sub">
          <FormControl sx={{ m: 1, minWidth: "20%", borderRadius: "10px" }} size="small">
            <TextField
              sx={textFieldStyle}
              label="Search"
              id="outlined-size-small"
              size="small"
            />
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
            <Select
              sx={{ borderRadius: "10px" }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              name="priceRange"
              value={dealsFilter.priceRange}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="priceRange">Price Range</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
            <Select
              sx={{ borderRadius: "10px" }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              name="conditions"
              value={dealsFilter.conditions}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="all">All Conditions</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
            <Select
              sx={{ borderRadius: "10px" }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              name="sortBy"
              value={dealsFilter.sortBy}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="sortByPrice">Sort by Price</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <div className="dealsInStockCont">
            <p>All</p>
            <FormControl>
              <Switch
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />

            </FormControl>
            <p>In Stock</p>
          </div>
        </div>
      </div>
      <h3 style={{ marginTop: "20px" }}>Price dropped for you</h3>

      <div className='tableMain_cont'>
        <div className='table_cont'>
          <DataTable
            columns={columns}
            customStyles={tableStyles}
            // noDataComponent={<NoDataComponent />}
            data={priceDrop}
            // defaultSortField="created_DateTime"
            // defaultSortAsc={sortTemplateDirection === 'asc'}
            pagination
          />
        </div>
      </div>
    </div>
  )
}

export default PriceDrop;