import React, { createContext, useState } from 'react';

export const ProfitChartContext = createContext();

export const ProfitChartProvider = ({ children }) => {
  const [profitChartArray, setProfitChartArray] = useState([]);

  return (
    <ProfitChartContext.Provider value={{ profitChartArray, setProfitChartArray }}>
      {children}
    </ProfitChartContext.Provider>
  );
};
