import React, { useState, useEffect, useContext, useRef } from 'react';
import './EditSegment.css';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { SegmentContext } from '../../../../Context/SegmentContext';

const EditSegment = ({ editSegmentId, updateSegmentsData, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const { segmentArray } = useContext(SegmentContext);

  const [nameError, setNameError] = useState(false);
  const editSegment_URL = `${baseUrl}/zumi/api/createsegment`;
  const [updateSegments, setUpdateSegments] = useState(false);
  const [segToBeUpdated, setSegToBeUpdated] = useState();

  const endOfListRef = useRef(null);

  const filterItems = [
    { id: 1, value: "Name", name: 'Name' },
    { id: 2, value: "Phone", name: 'Phone' },
    { id: 3, value: "Country", name: 'Country' },
    { id: 4, value: "City", name: 'City' },
    { id: 5, value: "ZipCode", name: 'Zipcode' },
  ];

  const operationItems = {
    Name: [
      { id: 1, value: "=", name: "Equals" },
      { id: 2, value: "like", name: "Like" },
    ],
    Phone: [
      { id: 3, value: "=", name: "Equals" },
    ],
    City: [
      { id: 4, value: "=", name: "Equals" },
    ],
    ZipCode: [
      { id: 5, value: "=", name: "Equals" },
    ],
    Country: [
      { id: 6, value: "=", name: "Equals" },
    ],
  };

  const [updatedSeg, setUpdatedSeg] = useState({
    segmentId: "",
    segmentName: "",
    description: "",
    criteria: [
      { column: '', value: '', operation: '' } // Initialize with empty values
    ],
  });

  // G E T   D A T A   F O R    S E G M E N T   T O   B E    U P D A T E D

  useEffect(() => {
    if (editSegmentId) {
      const getSegDetails_URL = `${baseUrl}/zumi/api/segmentdetails/${editSegmentId}`;

      const fetchSegmentDetails = async () => {
        try {
          const response = await fetch(getSegDetails_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error('Network response was not ok');
          }

          const result = await response.json();
          setSegToBeUpdated(result);
        } catch (error) {
          console.error('Fetch error:', error);
        }
      };

      fetchSegmentDetails();
    }
  }, [editSegmentId, authToken, baseUrl]);


  // S E T   D A T A   F O R    S E G M E N T   T O   B E    U P D A T E D
  useEffect(() => {
    if (segToBeUpdated) {
      console.log("Segment to be updated: ", segToBeUpdated)
      setUpdatedSeg({
        segmentId: segToBeUpdated.segmentId || '',
        segmentName: segToBeUpdated.segmentName || '',
        description: segToBeUpdated.description || '',
        criteria: (segToBeUpdated.criteria || []).map(criterion => ({
          column: criterion.column || '',
          value: criterion.value || '',
          // operation: criterion.operation || '',
          operation: criterion.operation === '=' ? 'Equals' : criterion.operation || '', // Replace "=" with "Equals"
        })),
      });
    } else {
      // Optionally reset the state if segToBeUpdated is null
      setUpdatedSeg({
        segmentName: "",
        description: "",
        criteria: [{ column: '', value: '', operation: '' }],
      });
    }
  }, [segToBeUpdated]);

  const handleSegmentNameChange = (e) => {
    const value = e.target.value.replace(/[-\s]+/g, '_').toLowerCase();
    const nameExists = segmentArray.some(item => item.segmentName === value);
    setNameError(nameExists ? 'Segment name already exists' : '');
    setUpdatedSeg(prev => ({ ...prev, segmentName: value }));
  };

  const handleDescriptionChange = (e) => {
    setUpdatedSeg(prev => ({ ...prev, description: e.target.value }));
  };

  // const handleCriteriaChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedCriteria = updatedSeg.criteria.map((criterion, i) =>
  //     i === index ? { ...criterion, [name]: value } : criterion
  //   );
  //   setUpdatedSeg(prev => ({ ...prev, criteria: updatedCriteria }));
  // };

  const handleCriteriaChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCriteria = updatedSeg.criteria.map((criterion, i) => {
      if (i === index) {
        return { ...criterion, [name]: value };
      }
      return criterion;
    });

    setUpdatedSeg(prev => ({
      ...prev,
      criteria: updatedCriteria,
    }));

    // If the value is cleared, reset the operation
    if (name === 'value' && value === '') {
      updatedCriteria[index].operation = ''; // Clear operation if value is empty
      setUpdatedSeg(prev => ({
        ...prev,
        criteria: updatedCriteria,
      }));
    }
  };

  const handleAddCriteria = () => {
    if (updatedSeg.criteria.length < 4) {
      setUpdatedSeg(prev => ({
        ...prev,
        criteria: [...prev.criteria, { column: '', value: '', operation: '' }],
      }));
    }
  };

  const handleRemoveCriteria = (index) => {
    // Filter out the criterion at the specified index
    const updatedCriteria = updatedSeg.criteria.filter((_, i) => i !== index);
    setUpdatedSeg(prev => ({ ...prev, criteria: updatedCriteria }));
  };

  useEffect(() => {
    if (endOfListRef.current) {
      endOfListRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [updatedSeg.criteria]);

  const mandatoryError = !updatedSeg.segmentName || !updatedSeg.description;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(editSegment_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedSeg),
      });
      if (response.ok) {
        console.log('Segment updated successfully');
        setUpdateSegments(!updateSegments);
      } else {
        console.error('Failed to update segment');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      updateSegmentsData(updateSegments);
      setUpdateSegments(!updateSegments);
      setUpdatedSeg({
        segmentName: '',
        description: '',
        criteria: [{ column: '', value: '', operation: '' }],
      });
      closeModal();
    }
  };

  return (
    <div className='editSegMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>{t('editSegment')}</span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="createSegmentMain_cont">
        <div className="segmentNameInput_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('segmentName')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'
              component="form"
              sx={{ '& > :not(style)': { width: '100%', background: "#fff" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                autoFocus
                variant="outlined"
                name="segmentName"
                placeholder={t('segmentPlaceholder')}
                value={updatedSeg.segmentName}
                onChange={handleSegmentNameChange}
                id="outlined-basic"
              />
            </Box>
          </div>
        </div>

        <div className="segmentDesc_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('description')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'
              component="form"
              sx={{ '& > :not(style)': { width: '100%', background: "#fff" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="description"
                id="outlined-basic"
                label=""
                placeholder="Write description..."
                variant="outlined"
                value={updatedSeg.description}
                onChange={handleDescriptionChange}
              />
            </Box>
          </div>
        </div>

        <div className="segmentInputsMain_cont">
          {updatedSeg.criteria.map((criterion, index) => (
            <div key={index} className="criteria_row">
              <div className="removeCriteria_cont">
                <CloseIcon className="removeCriteriaSvg" onClick={() => handleRemoveCriteria(index)} />
              </div>
              <div className="criteriaRow_cont">
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">{t('filter')}</div>
                  <div className="segmentInput_cont">
                    <Select
                      className='filterSelect'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="column"
                      value={criterion.column}
                      onChange={(e) => handleCriteriaChange(index, e)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Filter</em>;
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                      }}
                    >
                      {filterItems.map(item => (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">{t('value')}</div>
                  <div className="segmentInput_cont">
                    <TextField
                      style={{ width: "100%" }}
                      InputLabelProps={{ style: { display: 'none' } }}
                      name="value"
                      id="outlined-basic"
                      label=""
                      placeholder="Value"
                      disabled={!criterion.column}  // Disable if value is empty

                      variant="outlined"
                      value={criterion.value}
                      onChange={(e) => handleCriteriaChange(index, e)}
                    />
                  </div>
                </div>
                <div className="criteriaRowCont">
                  <div className="uploadHeading_cont">{t('action')}</div>
                  <div className="segmentInput_cont">
                    <Select
                      className='filterSelect'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="operation"
                      value={criterion.operation}
                      onChange={(e) => handleCriteriaChange(index, e)}
                      disabled={!criterion.value}  // Disable if value is empty
                      // displayEmpty
                      // renderValue={(selected) => selected || <em>Select Action</em>}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Action</em>;
                        }
                        if (selected === "=") {
                          return "Equals";
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                      }}
                    >
                      {(operationItems[criterion.column])?.map(item => (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

            </div>
          ))}

          <div className="addCriteria_cont">
            {updatedSeg.criteria.length < 4 && (
              <button
                type="button"
                className='btnNotFill'
                onClick={handleAddCriteria}
              >
                {t('addCriteria')}
              </button>
            )}
          </div>

          <div className="createSegmentBtn_cont">
            {mandatoryError && (
              <p style={{ color: 'red', fontSize: "12px" }}>Please fill the mandatory fields.</p>
            )}
            <button className='btnFill' disabled={!updatedSeg.segmentName || nameError} onClick={handleSubmit}>
              {t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSegment;


