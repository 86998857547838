import React, { useContext, useEffect } from 'react';
import './Main.css';
import SideBar from '../SideBar/SideBar';
import Toolbar from '../Toolbar/Toolbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { BrandLogoContext } from '../../Context/BrandLogoContext';
import { CreditsContext } from '../../Context/CreditsContext';
import { SubscriptionPlanContext } from '../../Context/SubscriptionPlanContext';
import { MarketplaceContext } from '../../Context/MarketplaceContext';
import { MarketplaceLoaderContext } from '../../Context/MarketplaceLoaderContext';
import { UserDetailsContext } from '../../Context/UserDetailsContext';
import { RefreshFunContext } from '../../Context/RefreshFunContext';

const Main = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const logo_url = process.env.REACT_APP_LOGO_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const brand_logo = localStorage.getItem('brandLogo');

  const userProfile_URL = `${baseUrl}/zumi/api/users/profile`;
  const marketplace_URL = `${baseUrl}/zumi/api/marketplaces`;
  const brandLogo_URL = `${logo_url}/static/logo/${brand_logo}`;
  const creditBalance_URL = `${baseUrl}/zumi/api/credits/available/${userId}`;
  const subscriptionPlan_URL = `${baseUrl}/zumi/api/subscriptions/${userId}`;

  const { setMarketplaceArray } = useContext(MarketplaceContext);
  const { setBrandLogo } = useContext(BrandLogoContext);
  const { setCredits } = useContext(CreditsContext);
  const { setSubscriptionPlan } = useContext(SubscriptionPlanContext);
  const { setMarketplaceLoader } = useContext(MarketplaceLoaderContext);
  const { setUserDetails } = useContext(UserDetailsContext);
  const { refreshAuthToken } = useContext(RefreshFunContext);



  useEffect(() => {
    console.log("UseEffect for refresh token is called.")
    // Start token refresh interval
    const interval = setInterval(refreshAuthToken, 30 * 1000); // Check every minute

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [])

  // F E T C H   U S E R    D E T A I L S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(userProfile_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setUserDetails(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);


  // F E T C H   M A R K E T P L A C E S
  useEffect(() => {
    fetch(marketplace_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMarketplaceArray(data);
        setMarketplaceLoader(false);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setMarketplaceLoader(false);
      });
  }, [authToken]);


  // F E T C H    B R A N D   L O G O
  useEffect(() => {
    fetch(brandLogo_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then(data => {
        setBrandLogo(data.url);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // F E T C H   C R E D I T   B A L A N C E
  useEffect(() => {
    fetch(creditBalance_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // setCreditBalanceData(data);
        setCredits(data)
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // F E T C H   S U B S C R I P T I O N   P L A N
  useEffect(() => {
    fetch(subscriptionPlan_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // setCreditBalanceData(data);
        setSubscriptionPlan(data)
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken]);


  // U S E R    L O G O U T    A U T O M A T I C A L L Y
  // useEffect(() => {
  //   let timer;

  //   const resetTimer = () => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       localStorage.clear();
  //       navigate('/'); // Navigate to home page
  //     }, 15 * 60 * 1000); // 15 minutes
  //   };

  //   // Set the initial timer
  //   resetTimer();

  //   // Event listeners for user activity
  //   const events = ['click', 'keydown', 'mousemove', 'scroll'];
  //   events.forEach(event => {
  //     window.addEventListener(event, resetTimer);
  //   });

  //   // Cleanup function
  //   return () => {
  //     clearTimeout(timer);
  //     events.forEach(event => {
  //       window.removeEventListener(event, resetTimer);
  //     });
  //   };
  // }, [navigate]);


  // R E T U R N   I F   N O T   A U T H T O K E N
  if (!authToken) {
    return null;
  }


  return (
    <div id='main_cont'>
      <div className="MaintoolbarCont">
        <Toolbar />
      </div>
      <div className="MainMainCont">
        <div className="MainSideBarCont">
          <SideBar />
        </div>
        <div className="MainContentCont">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
