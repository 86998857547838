import React, { createContext, useState } from 'react';


// Create the context
export const RefreshFunContext = createContext();
export const RefreshFunProvider = ({ children }) => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [refreshMessage, setRefreshMessage] = useState("");

  const refreshAuthToken = async () => {
    const currentTime = Date.now();
    const authToken = localStorage.getItem('authToken');
    const storedRefreshTime = localStorage.getItem('refreshTime');

    if (currentTime >= storedRefreshTime) {
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        // console.error('No refresh token found');
        logout();
      }
      const refresh_URL = `${baseUrl}/zumi/api/auth/refresh-token`;
      try {
        const response = await fetch(refresh_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refreshToken }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        else {
          const data = await response.json();

          // Store the new tokens in localStorage
          localStorage.setItem('authToken', data.token);

          // Update refresh time
          const newRefreshTime = Date.now() + 8 * 60 * 1000; // Adjust as needed
          localStorage.setItem('refreshTime', newRefreshTime);
          console.log('Tokens refreshed successfully');
        }
      } catch (err) {
        console.error('Error refreshing token:', err);
        logout();
      }
    }
  };
  return (
    <RefreshFunContext.Provider value={{ refreshAuthToken, refreshMessage }}>
      {children}
    </RefreshFunContext.Provider>
  );
};

// H A N D L E   L O G O U T
const logout = () => {
  localStorage.clear();
  window.location.href = '/signin';
}
