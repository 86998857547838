import React, { useEffect, useState } from 'react';
import './Login.css';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate, Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WelcomeNavbar from '../WelcomeNavbar/WelcomeNavbar';
import WelcomeFooter from '../WelcomeFooter/WelcomeFooter';

// import { RefreshFunContext } from '../../../Context/RefreshFunContext';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [incorrectAlert, setIncorrectAlert] = useState(false);
  const [emptyUserPassAlert, setEmptyUserPassAlert] = useState(false);
  const [loginSuccessAlert, setLoginSuccessAlert] = useState(false);
  const [userInactiveAlert, setUserInactiveAlert] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  })

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (loginDetails.username === "" || loginDetails.password === "") {
      setIncorrectAlert(false);
    }
  }, [loginDetails]);

  // H A N D L E   P A S S W O R D   V I S I B I L I T Y
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  // F U N C T I O N   F O R   L O G I N
  const handleLogin = async (e) => {
    e.preventDefault();
    const login_URL = `${baseUrl}/zumi/api/auth/login`;

    const fromTodaysDeals = localStorage.getItem('fromTodaysDeals');

    // Set loader
    setLoginLoader(true);
    if (loginDetails.username === "" || loginDetails.password === "") {
      setEmptyUserPassAlert(true);
      setLoginLoader(false);
      return;
    }

    const loginDetailsJson = JSON.stringify(loginDetails);
    try {
      const response = await fetch(login_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: loginDetailsJson,
      });

      // Check if response is not OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();
      setLoginSuccessAlert(true);

      localStorage.setItem('authToken', data.token);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('brandLogo', data.brand_logo);
      localStorage.setItem('activeMarketplaceId', data.channelId);
      localStorage.setItem('username', data.username);
      localStorage.setItem('allowedPrivileges', JSON.stringify(data.privileges));


      // STORE REFRESH TIME
      const refreshTime = Date.now() + 8 * 60 * 1000;
      localStorage.setItem('refreshTime', refreshTime);

      // Define mapping of privileges to paths
      const privilegePaths = {
        "Dashboard": "/home/dashboard",
        "Sales": "/home/salesrevenue",
        "Products": "/home/product-catalogs",
        "Reseller": "/home/create-broadcast",
        "Deals": "/home/buyer",
        "Contacts": "/home/contacts",
        "Marketing": "/home/whatsapp",
        "Integration": "/home/view-marketplace",
        "User Management": "/home/users",
      };

      // Get the first privilege
      const firstPrivilege = data.privileges[0]?.privilegeName;

      if (fromTodaysDeals) {
        navigate('/home/todays-deals');
      }
      else {
        // Navigate to the path associated with the first privilege
        if (firstPrivilege && privilegePaths[firstPrivilege]) {
          navigate(privilegePaths[firstPrivilege]);
        }
        else {
          navigate('/home/dashboard');
          setUserInactiveAlert(false);
        }
      }

      // Navigate to the path associated with the first privilege
      // if (firstPrivilege && privilegePaths[firstPrivilege]) {
      //   navigate(privilegePaths[firstPrivilege]);
      // }
      // else {
      //   navigate('/home/dashboard');
      //   setUserInactiveAlert(false);
      // }
    } catch (err) {
      console.error('Error posting data:', err);
      setIncorrectAlert(true);
      setEmptyUserPassAlert(false);
    } finally {
      setLoginLoader(false);
    }
  };

  return (
    <div className="loginMain_cont">
      <WelcomeNavbar />
      <div className="loginCardMain_cont">
        <div className="loginWelcomeHead_cont">
          <h2>Welcome Back!</h2>
          <p>Please enter your details.</p>
        </div>

        <form className="loginForm_cont">
          <div className="formInput_cont">
            <label htmlFor="username" className="welcomeFormLabel">Email <span className="asterisk">*</span></label>
            <TextField
              type='email'
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              name="username"
              id="username"
              placeholder="Enter your email"
              variant="outlined"
              sx={textFieldStyle}
              value={loginDetails.username}
              onChange={handleChange}
            />
          </div>
          <div className="formInput_cont">
            <label htmlFor="password" className="welcomeFormLabel">Password  <span className="asterisk">*</span></label>
            <TextField
              // type='password'
              type={showPassword ? 'text' : 'password'}
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              name="password"
              id="password"
              placeholder="Enter password"
              variant="outlined"
              sx={textFieldStyle}
              value={loginDetails.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="forgotPara_cont">
            <Link to="/forgot-password"><strong>Forgot Password</strong></Link>
          </div>
          <div className="formInput_cont loginBtn_cont">
            <button className='btnFill loginBtn' onClick={handleLogin}>Sign In</button>
            {loginLoader && <div className="loader"></div>}
          </div>
          <div className="loginSignupPara_cont">
            <p>Don't have an account yet? <Link to="/signup"><strong>Sign Up</strong></Link></p>
          </div>
          <div className="loginAlert_cont">
            {emptyUserPassAlert &&
              <Alert severity="warning" className='loginAlert' id='incorrectAlert'>Enter username and password.</Alert>
            }
            {incorrectAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>Incorrect username or password.</Alert>
            }
            {loginSuccessAlert &&
              <Alert severity="success" className='loginAlert' id='incorrectAlert'>Login Successfull</Alert>
            }
            {userInactiveAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>User is Inactive</Alert>
            }
          </div>
        </form>
      </div>
      <WelcomeFooter />
    </div>

  )
}

export default Login;

