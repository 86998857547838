import React, { useState, useEffect } from 'react';
import './Users.css';

import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import NewUser from './NewUser/NewUser';
import NewRole from './NewRole/NewRole';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import questionSvg from '../../assets/noMarketplace.svg';

import EditUser from './EditUser/EditUser';
import EditRole from './EditRole/EditRole';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "15px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Users = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [activeTab, setActiveTab] = useState('users');
  const userList_URL = `${baseUrl}/zumi/api/users`;
  const roleList_URL = `${baseUrl}/zumi/api/roles`;
  const deleteRole_URL = `${baseUrl}/zumi/api/roles`;
  const [deleteRoleId, setDeleteRoleId] = useState();

  const [openUsersModal, setOpenUsersModal] = React.useState(false);
  const handleOpenUsersModal = () => setOpenUsersModal(true);
  const handleCloseUsersModal = () => setOpenUsersModal(false);
  const [updateUsers, setUpdateUsers] = useState();
  const [searchUserQuery, setSearchUserQuery] = useState('');

  const [openRolesModal, setOpenRolesModal] = React.useState(false);
  const handleOpenRolesModal = () => setOpenRolesModal(true);
  const handleCloseRolesModal = () => setOpenRolesModal(false);
  const [updateRoles, setUpdateRoles] = useState();
  const [searchRoleQuery, setSearchRoleQuery] = useState('');

  const [openEditUsersModal, setOpenEditUsersModal] = React.useState(false);
  const handleOpenEditUsersModal = () => setOpenEditUsersModal(true);
  const handleCloseEditUsersModal = () => setOpenEditUsersModal(false);

  const [openEditRolesModal, setOpenEditRolesModal] = React.useState(false);
  const handleOpenEditRolesModal = () => setOpenEditRolesModal(true);
  const handleCloseEditRolesModal = () => setOpenEditRolesModal(false);

  const [userToBeUpdated, setUserToBeUpdated] = useState();
  const [roleToBeUpdated, setRoleToBeUpdated] = useState();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const [usersLoader, setUsersLoader] = useState(true);

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  // F E T C H   L I S T   O F   U S E R S 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(userList_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setUsers(result);
      } catch (error) {
        console.log(error);
      } finally {
        setUsersLoader(false);
      }
    };
    fetchData();
  }, [updateUsers]);

  const updateUsersData = (value) => {
    setUpdateUsers(value);
  }

  // F E T C H   L I S T   O F   R O L E S 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(roleList_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setRoles(result);
      } catch (error) {
        console.log(error);
      } finally {
        // setRolesLoader(false);
      }
    };
    fetchData();
  }, [updateRoles, deleteRoleId]);

  const updateRolesData = (value) => {
    setUpdateRoles(value);
  }

  const setUpdatedUserDetails = (item) => {
    setUserToBeUpdated(item);
    handleOpenEditUsersModal();
  }

  const setUpdatedRoleDetails = (item) => {
    setRoleToBeUpdated(item);
    handleOpenEditRolesModal();
  }

  // H A N D L E   U S E R   S E A R C H
  const handleUserSearch = (e) => {
    setSearchUserQuery(e.target.value);
  };

  // S E A R C H   U S E R   D A T A 
  const filteredUserData = React.useMemo(() => {
    const filteredUserData = users.filter(item =>
      typeof item.contactPerson === 'string' &&
      item.contactPerson.toLowerCase().includes(searchUserQuery.toLowerCase())
    );

    return filteredUserData;
  }, [users, searchUserQuery]);


  // H A N D L E   R O L E   S E A R C H 
  const handleRoleSearch = (e) => {
    setSearchRoleQuery(e.target.value);
  };

  // S E A R C H   R O L E    D A T A 
  const filteredRoleData = React.useMemo(() => {
    const filteredRoleData = roles.filter(item =>
      typeof item.roleName === 'string' &&
      item.roleName.toLowerCase().includes(searchRoleQuery.toLowerCase())
    );

    return filteredRoleData;
  }, [roles, searchRoleQuery]);


  // D E L E T E   R O L E
  const deleteRole = async (id) => {
    console.log("Delete role id: ", id)
    try {
      const response = await fetch(`${deleteRole_URL}/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },

      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteRoleId(id);
      // setTemplateData(templateData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };


  return (
    <div className='usersMain_cont'>
      {/* Alert display */}
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      <div className="usersToolMain_cont">
        <div className="usersTool_cont1">
          <div
            className={`usersTab centerElm ${activeTab === 'users' ? 'userTabActive' : ''}`}
            onClick={() => handleTabClick('users')}
          >
            {t('users')}
          </div>
          <div
            className={`rolesTab centerElm ${activeTab === 'roles' ? 'userTabActive' : ''}`}
            onClick={() => handleTabClick('roles')}
          >
            {t('roles')}
          </div>
        </div>
        <div className="usersTool_cont2">
          <div className="userSearch_cont">

            {activeTab === 'users' && (
              <TextField
                className='searchUserInput'
                id="outlined-size-small"
                size="small"
                placeholder='Search User'
                onChange={handleUserSearch}
              />
            )}

            {activeTab === 'roles' && (
              <TextField
                className='searchUserInput'
                id="outlined-size-small"
                size="small"
                placeholder='Search Role'
                onChange={handleRoleSearch}
              />
            )}
          </div>
          <div className="addUserBtn_cont">
            {activeTab === 'users' && (
              <button className='btnFill addUserBtn' onClick={handleOpenUsersModal}>{t('addUser')}</button>
            )}

            {activeTab === 'roles' && (
              <button className='btnFill addUserBtn' onClick={handleOpenRolesModal}>{t('addRole')}</button>
            )}
            {/* M O D A L    F O R    A D D    U S E R */}
            <Modal
              className='templateStyleMobile'
              open={openUsersModal}
              onClose={handleCloseUsersModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <NewUser btnText="Submit" roles={roles} updateUsersData={updateUsersData} showAlert={showAlert} closeModal={handleCloseUsersModal}></NewUser>
              </Box>
            </Modal>
            {/* M O D A L   T O   E D I T   U S E R */}
            <Modal
              className='templateStyleMobile'
              open={openEditUsersModal}
              onClose={handleCloseEditUsersModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <EditUser btnText="Submit" roles={roles} userToBeUpdated={userToBeUpdated} updateUsersData={updateUsersData} showAlert={showAlert} closeModal={handleCloseEditUsersModal}></EditUser>
              </Box>
            </Modal>

            {/* M O D A L    F O R    A D D    R O L E */}
            <Modal
              className='templateStyleMobile'
              open={openRolesModal}
              onClose={handleCloseRolesModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <NewRole btnText="Submit" updateRolesData={updateRolesData} showAlert={showAlert} closeModal={handleCloseRolesModal}></NewRole>
              </Box>
            </Modal>

            {/* M O D A L    F O R    E D I T    R O L E */}
            <Modal
              className='templateStyleMobile'
              open={openEditRolesModal}
              onClose={handleCloseEditRolesModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <EditRole btnText="Submit" updateRolesData={updateRolesData} roleToBeUpdated={roleToBeUpdated} showAlert={showAlert} closeModal={handleCloseEditRolesModal}></EditRole>
              </Box>
            </Modal>
          </div>
        </div>
      </div>

      {/* U S E R S   T A B L E */}

      {activeTab === 'users' && usersLoader && (
        <div className="usersLoader_cont centerElm">
          <div className="dashboardLoader"></div>
        </div>
      )}

      {activeTab === 'users' && !usersLoader && users.length > 0 && (
        <div className="usersTable_cont">
          <div className="userHeadRow">
            <div className="userTableHeadCell userNameCont">{t('username')}</div>
            <div className="userTableHeadCell userStatusCont">{t('status')}</div>
            <div className="userTableHeadCell userEmailCont">{t('email')}</div>
            <div className="userTableHeadCell userRoleCont">{t('role')}</div>
            <div className="userTableHeadCell userDateCont">{t('createdDate')}</div>
            <div className="userTableHeadCell userDateCont">{t('lastLogin')}</div>
            <div className="userTableHeadCell userActionCont">{t('actions')}</div>
          </div>

          {filteredUserData.map((item, index) => {
            const createdDate = new Date(item.createdDateTime);
            const options1 = {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            };
            const formattedCreatedDate = createdDate.toLocaleString('en-US', options1);

            const loginDate = new Date(item.lastLogin);
            const options2 = {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              day: 'numeric',
            };
            const formattedLoginDate = loginDate.toLocaleString('en-US', options2);

            return (
              <div className="userRow" key={index}>
                <div className="userRowInner">
                  <div className="userTableCell userNameCont">{item.contactPerson}</div>
                  <div className="userTableCell userStatusCont">
                    <div
                      className="userStatus"
                      style={{
                        backgroundColor: item.status === 1 ? 'rgb(233, 246, 238)' : 'rgb(255, 231, 231)',
                        color: item.status === 1 ? 'rgb(35, 164, 85)' : 'rgb(255, 14, 14)',
                      }}
                    >
                      {item.status === 1 ? 'Active' : 'Inactive'}
                    </div>
                  </div>
                  <div className="userTableCell userEmailCont">{item.emailId}</div>
                  <div className="userTableCell userRoleCont">{item.roleList}</div>
                  <div className="userTableCell userDateCont">{formattedCreatedDate}</div>
                  <div className="userTableCell userLastLoginCont">{formattedLoginDate}</div>
                  <div className="userTableCell userActionCont">
                    <div className="userEditBtn centerElm" onClick={() => setUpdatedUserDetails(item)}>
                      <EditIcon />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {activeTab === 'users' && !usersLoader && users.length === 0 && (
        <div className="noUsers_cont">
          <div className="noMarketplaceHead_cont">
            <h2>No Users Found</h2>
          </div>
          <div className="noMarketImg_cont">
            <img src={questionSvg} alt="No marketplace found" />
          </div>
          <p>You haven't created any users yet. Start by adding one!</p>
        </div>
      )}


      {/* R O L E S   T A B L E */}
      {activeTab === 'roles' && roles.length > 0 && (
        <div className="rolesTable_cont">
          <div className="userHeadRow">
            <div className="userTableHeadCell roleNameCont">{t('roleName')}</div>
            <div className="userTableHeadCell roleDescCont">{t('roleDescription')}</div>
            <div className="userTableHeadCell roleDateCont">{t('date')}</div>
            <div className="userTableHeadCell roleActionsCont">{t('action')}</div>
          </div>

          {filteredRoleData.map((item, index) => {
            const date = new Date(item.createdDateTime);
            const options = {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            };
            const formattedDate = date.toLocaleString('en-US', options);
            return (
              <div className="rolesRow" key={index}>
                <div className="rolesRowInner">
                  <div className="userTableCell roleNameCont">{item.roleName}</div>
                  <div className="userTableCell roleDescCont">{item.roleDescription}</div>
                  <div className="userTableCell roleDateCont">{formattedDate}</div>
                  <div className="userTableCell roleActionCont">
                    <div className="userEditBtn centerElm" onClick={() => setUpdatedRoleDetails(item)}>
                      <EditIcon />
                    </div>
                    <div className="userDeleteBtn centerElm" onClick={() => deleteRole(item.roleId)}>
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {activeTab === 'roles' && roles.length === 0 && (
        <div className="noUsers_cont">
          <div className="noMarketplaceHead_cont">
            <h2>No Roles Found</h2>
          </div>
          <div className="noMarketImg_cont">
            <img src={questionSvg} alt="No marketplace found" />
          </div>
          <p>
            You haven't created any roles yet. Start by adding one!
          </p>
        </div>
      )}
    </div>
  );
}

export default Users;
