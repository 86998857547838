import React, { createContext, useState } from 'react';

export const TotalSalesContext = createContext();

export const TotalSalesProvider = ({ children }) => {
  const [totalSalesArray, setTotalSalesArray] = useState([]);

  return (
    <TotalSalesContext.Provider value={{ totalSalesArray, setTotalSalesArray }}>
      {children}
    </TotalSalesContext.Provider>
  );
};
