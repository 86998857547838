import React, { useState, useEffect, useContext } from 'react'
import './MessageHistory.css';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReplyIcon from '@mui/icons-material/Reply';
// import SendIcon from '@mui/icons-material/Send';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// import LineWeightIcon from '@mui/icons-material/LineWeight';

import DataTable from 'react-data-table-component';
import LocationSelect from '../../../Dashboard/LocationSelect/LocationSelect';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';


import ViewRecipients from './ViewRecipients/ViewRecipients';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';
import ContactTableLoader from '../../../Loaders/ContactTableLoader/ContactTableLoader';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 480,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const AfterBroadcast = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');

  const messageHistory_URL = `${baseUrl}/whatsapp/template-history?userID=${userId}`;
  const overview_URL = `${baseUrl}/whatsapp/whatsapp-dashboard?userID=${userId}`;

  const [recipientsOpen, setRecipientsOpen] = React.useState(false);
  const handleRecipientsOpen = () => setRecipientsOpen(true);
  const handleRecipientsClose = () => setRecipientsOpen(false);
  const [historyLoader, setHistoryLoader] = useState(true);

  const [segmentContacts, setSegmentContacts] = useState([])
  const [sentMessages, setSentMessages] = useState([]);
  const [overviewData, setOverviewData] = useState({});

  const [sortMessageDirection, setSortMessageDirection] = useState('desc'); // 'asc' or 'desc'

  const [activeTab, setActiveTab] = useState('history');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };


  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  const columns = [
    {
      name: t("template"),
      selector: row => row.templateName,
      width: "170px"
    },
    {
      name: t("segment"),
      selector: row => row.segmentName = <div className="sentSegName" onClick={() => fetchRecipients(row.segmentId)}>{row.segmentName}</div>,
    },
    {
      name: t("date"),
      selector: row => {
        const date = new Date(row.createdAt);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
      width: "200px"
    },
    // {
    //   name: "Recipients",
    //   selector: row => row.recipients = 23,
    //   style: { width: '60px' },
    // },
    {
      name: t("sent"),
      selector: row => row.sent,
      width: '100px',
    },
    {
      name: t("delivered"),
      selector: row => row.delivered,
      width: '100px',
    },
    {
      name: t("read"),
      selector: row => row.isRead,
      width: '100px',
    },
    {
      name: t("failed"),
      selector: row => row.failed,
      width: '100px',
    },
  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        color: "rgb(14, 113, 195)",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "24px",
      },
    },
    headCells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        fontSize: "0.9rem",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  // S O R T   M E S S A G E  H I S T O R Y   D A T A 

  const handleMessageSort = (column, sortMessageDirection) => {
    setSortMessageDirection(sortMessageDirection);
  };

  const sortedMessageData = React.useMemo(() => {
    return sentMessages.slice().sort((a, b) => {
      if (sortMessageDirection === 'asc') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
  }, [sentMessages, sortMessageDirection]);


  // F E T C H   R E C I P I E N T S

  const fetchRecipients = async (id) => {
    const recipients_URL = `${baseUrl}/zumi/api/getcontact/${id}`;
    try {
      const response = await fetch(recipients_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setSegmentContacts(result);
    } catch (error) {
      console.log(error);
    }
    handleRecipientsOpen();
  };

  // F E T C H   M E S S A G E   H I S T O R Y

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(messageHistory_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setSentMessages(result);
      } catch (error) {
        console.log(error);
      } finally {
        setHistoryLoader(false);
      }
    };

    fetchData();
  }, []);

  // F E T C H  O V E R V I E W  D A T A

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(overview_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setOverviewData(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  return (
    <div className='afterBroadcastMain_cont' >
      {/* <Breadcrumbs /> */}
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'broadcast' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> {t('broadcast')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'history' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> {t('messageHistory')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'automation' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/automations"
          >
            <SettingsSuggestIcon style={{ marginRight: "8px" }} /> {t('automations')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'knowledge' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> {t('knowledgeBase')}
          </Link>
        </div>
      </div>


      {/* D  O   N   T      D  E  L   E  T   E  */}

      {/* <div className="locationMain_cont" style={{ padding: "0px" }}>
        <div className="sortInput_cont">
          <Box sx={{ minWidth: 120, }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('sort')}</InputLabel>
              <Select
                style={{ borderRadius: "", border: 'none' }}
                className='sortSelect'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortMessageDirection}
                label="Sort"
                onChange={e => handleMessageSort(null, e.target.value)}
              >{
                  sortItem.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Box>
        </div>
      </div> */}


      <div className="afterBroadcastSub_cont">
        <h3>{t("overview")}</h3>
        <div className="overviewBroadMain_cont">
          <div className="overviewConts">
            <div className="overviewData_cont">
              <div className="overviewData">
                <h2>{overviewData.sent || 0}</h2>
              </div>
              <div className="overviewSvgCont">
                <div className="overviewSvg">
                  <DoneIcon style={{ width: "30px", height: "30px", color: "green" }} />
                </div>
              </div>
            </div>
            <div className="overviewDesc_cont">
              <p>{t("sent")}</p>
            </div>
          </div>


          <div className="overviewConts">
            <div className="overviewData_cont">
              <div className="overviewData">
                <h2>{overviewData.delivered || 0}</h2>
              </div>
              <div className="overviewSvgCont">
                <div className="overviewSvg">
                  <DoneAllIcon style={{ width: "30px", height: "30px", color: "blue" }} />                </div>
              </div>
            </div>
            <div className="overviewDesc_cont">
              <p>{t("delivered")}</p>
            </div>
          </div>


          <div className="overviewConts">
            <div className="overviewData_cont">
              <div className="overviewData">
                <h2>{overviewData.isRead || 0}</h2>
              </div>
              <div className="overviewSvgCont">
                <div className="overviewSvg">
                  <VisibilityIcon style={{ width: "30px", height: "30px", color: "purple" }} />                </div>
              </div>
            </div>
            <div className="overviewDesc_cont">
              <p>{t("read")}</p>
            </div>
          </div>


          <div className="overviewConts">
            <div className="overviewData_cont">
              <div className="overviewData">
                <h2>{overviewData.replied || 0}</h2>
              </div>
              <div className="overviewSvgCont">
                <div className="overviewSvg">
                  <ReplyIcon style={{ width: "30px", height: "30px", color: "teal" }} />                </div>
              </div>
            </div>
            <div className="overviewDesc_cont">
              <p>{t("replied")}</p>
            </div>
          </div>


          <div className="overviewConts" style={{ marginRight: "0px" }}>
            <div className="overviewData_cont">
              <div className="overviewData">
                <h2>{overviewData.failed || 0}</h2>
              </div>
              <div className="overviewSvgCont">
                <div className="overviewSvg">
                  <SmsFailedIcon style={{ width: "30px", height: "30px", color: "red" }} />                </div>
              </div>
            </div>
            <div className="overviewDesc_cont">
              <p>{t("failed")}</p>
            </div>
          </div>
        </div>


        <h3>{t("campaignHistory")}</h3>
        <Modal
          className='templateStyleMobile'
          open={recipientsOpen}
          onClose={handleRecipientsOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ViewRecipients segmentContacts={segmentContacts} closeModal={handleRecipientsClose} />
          </Box>
        </Modal>

        <div className='sentMessageTable_cont'>

          {historyLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={sentMessagesStyles}
              data={sortedMessageData}
              defaultSortField="createdAt"
              defaultSortAsc={sortMessageDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AfterBroadcast;