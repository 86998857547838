import React, { useState, useEffect } from 'react';
import './NewRole.css';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '@mui/material';


const NewRole = ({ updateRolesData, showAlert, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [updateRoles, setUpdateRoles] = useState(false);
  const authToken = localStorage.getItem('authToken');
  const listOfPrivileges_URL = `${baseUrl}/zumi/api/listofprivileges`;
  const createRole_URL = `${baseUrl}/zumi/api/roles/create`;
  const [listOfPrivileges, setListOfPrivileges] = useState([]);

  const [roleData, setRoleData] = useState({
    roleName: '',
    roleDescription: '',
    privilegesList: []
  });

  // F E T C H   L I S T   O F   P R I V I L E G E S 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(listOfPrivileges_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
            'Content-Type': 'application/json' // Set content type if necessary
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setListOfPrivileges(result);
      } catch (error) {
        console.log(error);
      } finally {
        // setTemplateLoader(false);
      }
    };
    fetchData();
  }, []);


  // H A N D L E  C H A N G E 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoleData(prev => ({ ...prev, [name]: value }));
  };

  //  H A N D L E    C H E C K B O X
  const handleCheckboxChange = (privilegeId) => {
    setRoleData(prev => {
      const updatedList = prev.privilegesList.includes(privilegeId)
        ? prev.privilegesList.filter(p => p !== privilegeId)
        : [...prev.privilegesList, privilegeId];
      return { ...prev, privilegesList: updatedList };
    });
  };

  // H A N D L E   N E W   R O L E
  const handleAddRole = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(createRole_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(roleData),
      });
      if (response.ok) {
        setUpdateRoles(!updateRoles);
        closeModal();
        showAlert("Role created successfully.", '#d4f4e6', '#77d98b');
      } else {
        console.error('Failed to add role');
        closeModal();
        showAlert("Role not created.", '#FFE4C0', '#FD5D5D');
      }
    } catch (error) {
      console.error('Error:', error);
      closeModal();
      showAlert("Role not created due to an error.", '#FFE4C0', '#FD5D5D');
    }
    finally {
      updateRolesData(updateRoles);
      setUpdateRoles(!updateRoles);
      setRoleData({
        roleName: '',
        roleDescription: '',
        privilegesList: []
      })
      closeModal();
    }
  };

  const mandatoryError = !roleData.roleName || !roleData.roleDescription || roleData.privilegesList.length === 0;

  return (
    <div className="newRoleMainCont">

      <div className="newRoleHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('addNewRole')}
          </span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http:www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="newRoleForm_cont">
        <div className="newRoleFormInput_cont">
          <label htmlFor="roleName" className="welcomeFormLabel">{t('roleName')}  <span className="asterisk">*</span></label>
          <TextField
            className='welcomeFormInput'
            InputLabelProps={{ style: { display: 'none' } }}
            inputProps={{ autoComplete: 'off' }}
            type='text'
            name="roleName"
            id="outlined-basic"
            placeholder="Enter role name"
            variant="outlined"
            value={roleData.roleName}
            onChange={handleInputChange}
          />
        </div>

        <div className="newRoleFormInput_cont">
          <label htmlFor="roleDescription" className="welcomeFormLabel">{t('roleDescription')}  <span className="asterisk">*</span></label>
          <TextField
            className='welcomeFormInput'
            InputLabelProps={{ style: { display: 'none' } }}
            inputProps={{ autoComplete: 'off' }}
            type='text'
            name="roleDescription"
            id="outlined-basic"
            placeholder="Enter role name"
            variant="outlined"
            value={roleData.roleDescription}
            onChange={handleInputChange}
          />
        </div>

        <div className="rolePrivilegeTable_cont">
          <div className="rolePrivilegeHeadRow">
            <div className="rolePrivilegeHeadCell centerElm">
              {t('privilege')}
            </div>
            <div className="rolePrivilegeHeadCell centerElm">
              {t('permission')}
            </div>
          </div>

          <div className="rolePrivilegeBody">
            {listOfPrivileges.map((item, index) => {
              return (
                <div className="rolePrivilegeRow" key={index}>
                  <div className="rolePrivilegeCell centerElm">
                    {item.privilegeName}
                  </div>
                  <div className="rolePrivilegeCell centerElm">
                    <Checkbox
                      checked={roleData.privilegesList.includes(item.privilegeId)}
                      onChange={() => handleCheckboxChange(item.privilegeId)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="addRoleBtn_cont">
          {mandatoryError && (
            <p style={{ color: 'red', fontSize: "12px", }}>Please fill the mandatory fields.</p>
          )}
          <button className='btnFill' disabled={mandatoryError} onClick={handleAddRole}>{t('addRole')}</button>
        </div>
      </div>

    </div>

  );
}

export default NewRole;