import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';

export const RoughEndDateContext = createContext();

export const RoughEndDateProvider = ({ children }) => {
  const [roughEndDateTime, setRoughEndDateTime] = useState(dayjs());

  return (
    <RoughEndDateContext.Provider value={{ roughEndDateTime, setRoughEndDateTime }}>
      {children}
    </RoughEndDateContext.Provider>
  );
};
