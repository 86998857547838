import React from 'react';
import './Welcome.css';
import WelcomeNavbar from './WelcomeNavbar/WelcomeNavbar';
import WelcomeMainContent from './WelcomeMainContent/WelcomeMainContent';
import WelcomeFooter from './WelcomeFooter/WelcomeFooter';
const Welcome = () => {

  return (
    <div className='welcomeMain_cont'>
      {/* W E L C O M E   N A V B A R */}
      <WelcomeNavbar />

      {/* W E L C O M E   M A I N   C O N T E N T */}
      <WelcomeMainContent />

      {/* W E L C O M E   F O O T E R */}
      {/* <WelcomeFooter /> */}
    </div >
  )
}

export default Welcome;