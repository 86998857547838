import React, { useState, useEffect, useContext } from 'react';
import './Dashboard.css';
import BasicSpeedDial from '../SpeedDial/SpeedDial';
import LocationSelect from './LocationSelect/LocationSelect';
import SalesChart from './Sales/SalesChart';
import UnitsChart from './UnitsSold/UnitsChart';
import ProfitChart from './Profit/ProfitChart';
import UnfulfilledChart from './Unfulfilled/UnfulfilledChart';
import AdSpendChart from './AdSpend/AdSpend';
import BuyerChart from './Buyer/BuyerChart';
import InventoryChart from './Inventory/InventoryChart';
import NoMarketplace from './NoMarketplace/NoMarketplace';

import Breadcrumbs from '../Toolbar/Breadcrumbs';

import { useTranslation } from 'react-i18next';

import { TotalSalesContext } from '../../Context/TotalSalesContext';
import { UnitsSoldContext } from '../../Context/UnitsSoldContext';
import { ProfitContext } from '../../Context/ProfitContext';
import { SelectedMarketplaceContext } from '../../Context/SelectedMarketplaceContext';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

import { RoughStartDateContext } from '../../Context/RoughStartDateContext';
import { RoughEndDateContext } from '../../Context/RoughEndDateContext';

import { MarketplaceContext } from '../../Context/MarketplaceContext';
import { MarketplaceLoaderContext } from '../../Context/MarketplaceLoaderContext';

const Dashboard = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');

  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);
  const { roughStartDateTime, setRoughStartDateTime } = useContext(RoughStartDateContext);
  const { roughEndDateTime, setRoughEndDateTime } = useContext(RoughEndDateContext);
  const [combinedDateTime, setCombinedDateTime] = useState(null);

  const { marketplaceArray } = useContext(MarketplaceContext);
  const { marketplaceLoader } = useContext(MarketplaceLoaderContext);

  const { totalSalesArray, setTotalSalesArray } = useContext(TotalSalesContext);
  const { unitsSoldArray, setUnitsSoldArray } = useContext(UnitsSoldContext);
  const { profitArray, setProfitArray } = useContext(ProfitContext);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [activeListing, setActiveListing] = useState([]);
  const [buyBoxListing, setBuyBoxListing] = useState([]);
  const [overpriceListing, setOverpriceListing] = useState([]);
  const [noSaleListing, setNoSaleListing] = useState([]);

  const [totalSales, setTotalSales] = useState();
  const [totalUnitsSold, setTotalUnitsSold] = useState();
  const [totalProfit, setTotalProfit] = useState();

  const [salesChartLoading, setSalesChartLoading] = useState(false);
  const [unitsChartLoading, setUnitsChartLoading] = useState(false);
  const [profitChartLoading, setProfitChartLoading] = useState(false);

  const [lastActiveListing, setLastActiveListing] = useState(0);
  const [lastBuyBoxListing, setLastBuyBoxListing] = useState(0);
  const [lastOverpriceListing, setLastOverpriceListing] = useState(0);
  const [lastNoSaleListing, setLastNoSaleListing] = useState(0);

  const [accountHealth, setAccountHealth] = useState();

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDateTime) {
      const formattedStartDate = roughStartDateTime.format('MMM D, YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDateTime) {
      const formattedEndDate = roughEndDateTime.format('MMM D, YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDateTime, roughStartDateTime]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDateTime(startDate + " - " + endDate);
    }
  }, [startDate, endDate]);

  const activeListing_URL = `${baseUrl}/zumi/api/dashboard/totallisting?dateRange=${combinedDateTime}`;
  const butBoxListing_URL = `${baseUrl}/zumi/api/dashboard/buyboxlisting?dateRange=${combinedDateTime}`;
  const overpriceListing_URL = `${baseUrl}/zumi/api/dashboard/overpricelisting?dateRange=${combinedDateTime}`;
  const noSaleListing_URL = `${baseUrl}/zumi/api/dashboard/nosalelisting?dateRange=${combinedDateTime}`;
  const salesData_URL = `${baseUrl}/zumi/api/dashboard/totalsales?dateRange=${combinedDateTime}`;
  const unitsSold_URL = `${baseUrl}/zumi/api/dashboard/unitsold?dateRange=${combinedDateTime}`;
  const profit_URL = `${baseUrl}/zumi/api/dashboard/profit?dateRange=${combinedDateTime}`;
  const accountHealth_URL = `${baseUrl}/zumi/api/dashboard/accounthealthrating`;


  //  F E T C H    A C T I V E   L I S T I N G S
  useEffect(() => {
    if (combinedDateTime) {
      fetch(activeListing_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setActiveListing(data);
          if (data.length > 0) {
            const lastObject = data[data.length - 1];
            setLastActiveListing(lastObject.count);
          }
          else {
            setLastActiveListing(0);
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);


  //  F E T C H    B U Y B O X   L I S T I N G S
  useEffect(() => {
    if (combinedDateTime) {
      fetch(butBoxListing_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setBuyBoxListing(data);
          if (data.length > 0) {
            const lastObject = data[data.length - 1];
            setLastBuyBoxListing(lastObject.count);
          }
          else {
            setLastBuyBoxListing(0);
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);


  //  F E T C H    O V E R P R I C E   L I S T I N G S
  useEffect(() => {
    if (combinedDateTime) {
      fetch(overpriceListing_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setOverpriceListing(data);
          if (data.length > 0) {
            const lastObject = data[data.length - 1];
            setLastOverpriceListing(lastObject.count);
          }
          else {
            setLastOverpriceListing(0);
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);


  //  F E T C H   N O S A L E   L I S T I N G S
  useEffect(() => {
    if (combinedDateTime) {
      fetch(noSaleListing_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setNoSaleListing(data);
          if (data.length > 0) {
            const lastObject = data[data.length - 1];
            setLastNoSaleListing(lastObject.count);
          }
          else {
            setLastNoSaleListing(0);
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);


  //  F E T C H   T O T A L   S A L E S
  useEffect(() => {
    setSalesChartLoading(true);
    if (combinedDateTime) {
      fetch(salesData_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setTotalSalesArray(data);
          setSalesChartLoading(false);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          setSalesChartLoading(false);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);

  useEffect(() => {
    if (totalSalesArray) {
      const sum = totalSalesArray.reduce((acc, item) => acc + (item.count || 0), 0);
      setTotalSales(sum);
    }
  }, [totalSalesArray]);


  //  F E T C H   U N I T S   S O L D 
  useEffect(() => {
    setUnitsChartLoading(true);
    if (combinedDateTime) {
      fetch(unitsSold_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setUnitsSoldArray(data);
          setUnitsChartLoading(false);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          setUnitsChartLoading(false);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);

  useEffect(() => {
    if (unitsSoldArray) {
      const sum = unitsSoldArray.reduce((acc, item) => acc + (item.count || 0), 0);
      setTotalUnitsSold(sum);
    }
  }, [unitsSoldArray]);


  //  F E T C H   P R O F I T
  // useEffect(() => {
  //   setProfitChartLoading(true);
  //   if (combinedDateTime) {
  //     fetch(profit_URL, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${authToken}`, // Add the Authorization header
  //         'Content-Type': 'application/json' // Set content type if necessary
  //       }
  //     })
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         setProfitArray(data);
  //         setProfitChartLoading(false);
  //       })
  //       .catch(error => {
  //         console.error('There was a problem with the fetch operation:', error);
  //         setProfitChartLoading(false);
  //       });
  //   }
  // }, [combinedDateTime, selectedMarketplace]);

  // useEffect(() => {
  //   if (profitArray) {
  //     const sum = profitArray.reduce((acc, item) => acc + (item.count || 0), 0);
  //     setTotalProfit(sum);
  //   }
  // }, [profitArray]);


  //  F E T C H   A C C O U N T   H E A L T H
  useEffect(() => {
    if (combinedDateTime) {
      fetch(accountHealth_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setAccountHealth(data.ahrStatus);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [combinedDateTime, selectedMarketplace]);


  return (
    <div className='dashboard_main'>
      <Breadcrumbs />

      {marketplaceLoader ? (
        <div className="dashboardLoader_cont centerElm">
          <div className="dashboardLoader"></div>
        </div>
      ) : (
        marketplaceArray.length === 0 && (
          <NoMarketplace />
        )
      )}

      {marketplaceLoader ? (
        <div className="dashboardLoader_cont centerElm">
          <div className="dashboardLoader"></div>
        </div>
      ) : marketplaceArray.length > 0 && (
        <>
          <div className="locationMain_cont">
            <LocationSelect />
            <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={roughStartDateTime}
                  onChange={setRoughStartDateTime}
                  maxDate={dayjs()}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={roughEndDateTime}
                  disabled={!roughStartDateTime}
                  onChange={setRoughEndDateTime}
                  maxDate={dayjs()}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="accountHealth_cont">

            <div className="dataInfo_cont">
              <p>Data shown on the dashboard is set to reflect the past seven days by default.</p>
            </div>

            <div className="accountInfo_cont">
              <p>
                Account Health: <span
                  style={{
                    color: accountHealth === "NORMAL" ? "green" : accountHealth === "POOR" ? "red" : accountHealth === "INTERMEDIATE" ? "orange" : "black",
                  }}
                >{accountHealth ? accountHealth : "Loading"}</span>
              </p>
            </div>
          </div>

          {/* F I R S T   R O W  */}

          <div className="dfirst_row">
            <div className="dfirstrowElm">
              <div className="data_cont">
                <div className="dataHeading_cont">
                  <span>{t('activeListings')}</span>
                </div>
                <h5 className="firstrowdata">
                  {lastActiveListing}
                </h5>
              </div>
            </div>
            <div className="dfirstrowElm">
              <div className="data_cont">
                <div className="dataHeading_cont">
                  <span>{t('buyBoxListing')}</span>
                </div>
                <h5 className="firstrowdata">
                  {lastBuyBoxListing}
                </h5>
              </div>
            </div>
            <div className="dfirstrowElm">
              <div className="data_cont">
                <div className="dataHeading_cont">
                  <span>{t('overpriceListing')}</span>
                </div>
                <h5 className="firstrowdata">
                  {lastOverpriceListing}
                </h5>
              </div>
            </div>
            <div className="dfirstrowElm dfirstrowfourthElm">
              <div className="data_cont">
                <div className="dataHeading_cont">
                  <span>{t('noSaleListings')}</span>
                </div>
                <h5 className="firstrowdata">
                  {lastNoSaleListing}
                </h5>
              </div>
            </div>
          </div>

          {/* S E C O N D   R O W */}

          <div className="dsecond_row">
            <div className=" dsecondrowElm dsecondFirstElm">
              <div className="dcont_heading">
                <span>{t('totalSales')}</span>
                <h5 className="firstrowdata">
                  {salesChartLoading ? (
                    <p>...</p>
                  ) : (
                    totalSales
                  )}
                </h5>
              </div>
              <div className="graph_cont">
                {salesChartLoading ? (
                  <p>Loading...</p>
                ) : (
                  <SalesChart />
                )}
              </div>
            </div>
            <div className="dsecondrowElm dsecondSecondElm">
              <div className="dcont_heading">
                <span>{t('unitsSold')}</span>
                <h5 className="firstrowdata">
                  {unitsChartLoading ? (
                    <p>...</p>
                  ) : (
                    totalUnitsSold
                  )}
                </h5>
              </div>
              <div className="graph_cont">
                {unitsChartLoading ? (
                  <p>Loading...</p>
                ) : (
                  <UnitsChart />
                )}
              </div>
            </div>
          </div>

          {/* T H I R D   R O W */}

          {/* <div className="dthird_row">
            <div className="dthirdrowElm dthirdrowfirstElm">
              <div className="dcont_heading">
                <span>{t('profit')}</span>
                <h5 className="firstrowdata">
                  {profitChartLoading ? (
                    <p>...</p>
                  ) : (
                    totalProfit
                  )}
                </h5>
              </div>
              <div className="graph_cont">
                {profitChartLoading ? (
                  <p>Loading...</p>
                ) : (
                  <ProfitChart />
                )}
              </div>
            </div>

            <div className="dthirdrowElm dthirdrowsecondElm">
              <div className="dcont_heading">
                <span>{t('unfulfilledOrders')}</span>
                <h5 className="firstrowdata">
                  Coming Soon
                </h5>
              </div>
              <div className="graph_cont">
                <UnfulfilledChart unfulfilledData={unfulfilledStyle.data} options={unfulfilledStyle.options} />
              </div>
            </div>
          </div> */}

          {/* F O U R T H   R O W  */}

          {/* <div className="dfourth_row">
        <div className="dfourthrowElm dfourthrowfirstElm">
          <div className="dcont_heading">
            <span>{t('adSpend')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            <AdSpendChart adSpendData={adSpendStyle.data} options={adSpendStyle.options} />
          </div>
        </div>
        <div className="dfourthrowElm dfourthrowsecondElm">
          <div className="dcont_heading">
            <span>{t('buyerMessages')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            <BuyerChart buyerData={buyerStyle.data} options={buyerStyle.options} />
          </div>
        </div>
      </div> */}

          {/* F I F T H    R O W */}

          {/* <div className="dfifth_row">
        <div className="dfifthrowElm dfifthrowfirstElm">
          <div className="dcont_heading">
            <span>{t('inventoryPerformance')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            <InventoryChart inventoryData={inventoryStyle.data} options={inventoryStyle.options} />
          </div>
        </div>

        <div className="dfifthrowElm dfifthrowsecondElm">
          <div className="dcont_heading">
            <span>{t('accounthealth')}</span>
          </div>
          <h5 className='firstrowdata'>
            {accountHealth ? accountHealth : "Loading"}
          </h5>
        </div>
      </div> */}
        </>
      )}
    </div>
  )
}

export default Dashboard;