import React, { useEffect, useState } from 'react';
import './AmazonAuthSuccess.css';
import check from '../../../../assets/greenCheck.jpg';
import cross from '../../../../assets/redCross.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AmazonAuthSuccess = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [authStatus, setAuthStatus] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const amazonUserAuth_URL = `${baseUrl}/zumi/api/userauthsuccess`;
    const spapi_oauth_code = params.get('spapi_oauth_code');
    const selling_partner_id = params.get('selling_partner_id');
    const marketRegion = localStorage.getItem("marketRegion");
    const amazonAccountName = localStorage.getItem("amazonAccountName");
    const amazonSellerEmail = localStorage.getItem("amazonSellerEmail");

    if (spapi_oauth_code && selling_partner_id) {
      const authInfo = {
        spapi_oauth_code,
        selling_partner_id,
        region: marketRegion,
        nickname: amazonAccountName,
        email: amazonSellerEmail,
      };

      setLoader(true);

      const authenticateUser = async () => {
        try {
          const response = await fetch(amazonUserAuth_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(authInfo),
          });

          if (response.ok) {
            console.log('Amazon authentication successful.');
            setAuthStatus(true);
          } else {
            console.error('Failed to authorize. Status:', response.status);
            setAuthStatus(false);
          }
        } catch (error) {
          console.error('Error:', error);
          setAuthStatus(false);
        } finally {
          setLoader(false);
          localStorage.removeItem("marketRegion");
          localStorage.removeItem("amazonAccountName");
          localStorage.removeItem("amazonSellerEmail");
        }
      };
      authenticateUser();
    }
  }, []);


  return (
    <div className='amazonAuthMain_cont'>
      {loader ? (
        <div className="marketIntLoad_cont centerElm">
          <div className="dashboardLoader"></div>
        </div>
      ) : authStatus === true ? (
        <div className="amazonAuthSub_cont1">
          <div className="amazonAuthImg_cont centerElm show">
            <img src={check} alt="Success" />
          </div>
          <p>Amazon Authorization Successful</p>
          <Link className='btnNotFill amazonAuthBtn' to="/home/view-marketplace">View Marketplace</Link>
        </div>
      ) : authStatus === false ? (
        <div className="amazonAuthSub_cont2">
          <div className="amazonAuthImg_cont centerElm show">
            <img src={cross} alt="Failure" />
          </div>
          <p>Amazon Authorization Failed</p>
          <Link className='btnNotFill amazonAuthBtn' to="/home/new-marketplace">Try Again</Link>
        </div>
      ) : null}
    </div>
  );
};

export default AmazonAuthSuccess;