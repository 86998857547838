import React, { useState } from 'react';
import './CreateDataset.css';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CreateDataset = ({ closeModal, showAlert }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');

  const createDataset_URL = `https://3pvwejju4h.execute-api.us-east-1.amazonaws.com/zumi/createaudience`;

  const [loading, setLoading] = useState(false);

  const [datasetDetails, setDatasetDetails] = useState({
    datasetName: "",
    description: "",
    suburb: "",
    state: "",
    postcode: "",
    ageRangeFrom: "",
    ageRangeTo: "",
    gender: "",
    voters: "True",
    createdByUserId: userId
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDatasetDetails(prevState => ({ ...prevState, [name]: value }));
  }

  const handleCreateDataset = async () => {
    setLoading(true);
    const datasetJson = JSON.stringify(datasetDetails);


    try {
      const response = await fetch(createDataset_URL, {
        method: 'POST',
        body: datasetJson,
      });
      if (!response.ok) {
        const errorData = await response.json();
        showAlert("Audience not created.", '#FFE4C0', '#FD5D5D');
        console.error('Error:', errorData.message);
      }
      else {
        const data = await response.json();
        showAlert("Audience created successfully.", '#d4f4e6', '#77d98b');
      }
    } catch (err) {
      showAlert("User not created.", '#FFE4C0', '#FD5D5D');
      console.error('Error posting data:', err);
    }
    finally {
      setLoading(false);
      setDatasetDetails()
      closeModal();
    }
  };

  const isSaveDisabld = !datasetDetails.datasetName || !datasetDetails.description;

  return (
    <div className='datasetFromMain'>
      <div className="newDatasetHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('newDataset')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="createDatasetMain_cont">
        <div className="datasetNameInput_cont">
          <div className="datasetHeadingInputCont">
            <div className="uploadHeading_cont datasetNameHeading">
              {t('datasetName')} <span className="asterisk">*</span>
            </div>
            <Box
              className='datasetNameBox'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                autoFocus
                variant="outlined"
                name="datasetName"
                placeholder="Enter dataset name"
                value={datasetDetails.datasetName}
                onChange={handleChange}
                id="outlined-basic"
              />
            </Box>
          </div>
        </div>

        <div className="datasetDesc_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('description')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="description"
                id="outlined-basic"
                value={datasetDetails.description}
                onChange={handleChange}
                placeholder="Write description..."
                variant="outlined"
              />
            </Box>
          </div>
        </div>

        <div className="datasetInputsMain_cont">
          {/* ROW 1 */}
          <div className="newDatasetRow">
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('suburb')}  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='text'
                name="suburb"
                id="outlined-basic"
                placeholder="Enter Suburb"
                variant="outlined"
                value={datasetDetails.suburb}
                onChange={handleChange}
              />
            </div>
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('state')}  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='text'
                name="state"
                id="outlined-basic"
                placeholder="Enter state "
                variant="outlined"
                value={datasetDetails.state}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* ROW 2 */}
          <div className="newDatasetRow">
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('postCode')}  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='text'
                name="postcode"
                id="outlined-basic"
                placeholder="Enter postcode"
                variant="outlined"
                value={datasetDetails.postcode}
                onChange={handleChange}
              />
            </div>
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('ageRange')}  <span className="asterisk">*</span></label>
              <div className="ageRangeCont">
                <TextField
                  className='welcomeFormInput'
                  style={{ marginRight: "10px" }}
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='number'
                  name="ageRangeFrom"
                  id="outlined-basic"
                  placeholder="From"
                  variant="outlined"
                  value={datasetDetails.ageRangeFrom}
                  onChange={handleChange}
                />

                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='number'
                  name="ageRangeTo"
                  id="outlined-basic"
                  placeholder="To"
                  variant="outlined"
                  value={datasetDetails.ageRangeTo}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* ROW 3 */}
          <div className="newDatasetRow">
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('gender')}  <span className="asterisk">*</span></label>
              <Select
                className='regionSelect'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="gender"
                value={datasetDetails.gender}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select gender</em>;
                  }
                  return selected
                }}
              >
                <MenuItem key={1} value="Male">Male</MenuItem>
                <MenuItem key={2} value="Female">Female</MenuItem>
                <MenuItem key={3} value="Both">Both</MenuItem>
              </Select>
            </div>
            <div className="newDatasetFormInput_cont" style={{ marginRight: "15px" }}>
              <label htmlFor="firstName" className="welcomeFormLabel">{t('voter')}  <span className="asterisk">*</span></label>
              <Select
                className='regionSelect'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="voters"
                value={datasetDetails.voters}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem key={1} value="True">Yes</MenuItem>
                <MenuItem key={2} value="False">No</MenuItem>
              </Select>
            </div>

          </div>

          <div className="createSegmentBtn_cont">
            <button
              className='btnFill'
              onClick={handleCreateDataset}
              disabled={isSaveDisabld}
              style={{
                border: isSaveDisabld ? "1px solid #EEEEEE" : "#17c1e8",
                backgroundColor: isSaveDisabld ? '#EEEEEE' : '#17c1e8',
                color: isSaveDisabld ? 'gray' : '#fff',
              }}
            >
              {loading ? <div className="loaderWhite"></div> : "Save"}


            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDataset;