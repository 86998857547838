import React, { useState, useEffect } from 'react';
import './ForgotPass.css';
import { TextField } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import WelcomeNavbar from '../WelcomeNavbar/WelcomeNavbar';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: "10px",
    },
    '&:hover fieldset': {
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      borderRadius: "10px",
    },
  },
};

const ForgotPass = () => {
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [otpSentLoader, setOtpSentLoader] = useState(false);
  const [otpVerifyLoader, setOtpVerifyLoader] = useState(false);
  const [passChangeLoader, setPassChangeLoader] = useState(false);

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const sendOtp_URL = `${baseUrl}/zumi/api/auth/request-otp?username=`;
  const verifyOtp_URL = `${baseUrl}/zumi/api/auth/validate-otp?username=`;
  const changePass_URL = `${baseUrl}/zumi/api/auth/reset-password`;

  const [userNotExistAlert, setUserNotExistAlert] = useState(false);
  const [otpFailedAlert, setOtpFailedAlert] = useState(false);
  const [invalidOtpAlert, setInvalidOtpAlert] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passAlert, setPassAlert] = useState(false);
  const [confirmPassAlert, setConfirmPassAlert] = useState(false);

  const [forgotDetails, setForgotDetails] = useState({
    username: "",
    verificationCode: "",
    newPassword: "",
  });
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForgotDetails((prev) => ({ ...prev, [name]: value }));
    if (name === 'newPassword' && value.length < 8) {
      setPassAlert(true);
    }
    if (name === 'newPassword' && value.length >= 8) {
      setPassAlert(false);
    }
  }

  // H A N D L E   C O N F I R M    P A S S W O R D
  const handleConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  // H A N D L E   P A S S W O R D   V I S I B I L I T Y
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  // H A N D L E   C O N F I R M   P A S S W O R D   V I S I B I L I T Y
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  // S E N D    O T P
  const handleSendOTP = async () => {
    setOtpSentLoader(true);
    try {
      // Send POST request
      const response = await fetch(`${sendOtp_URL}${forgotDetails.username}`, {
        method: 'POST',
      });

      // Check if response is OK
      if (response.ok) {
        setIsOtpSent(true);
        console.log("Data: ", response)
      }
      else {
        if (response.status === 400) {
          setUserNotExistAlert(true);
          setTimeout(() => setUserNotExistAlert(false), 5000);
        }
        if (response.status === 500) {
          setOtpFailedAlert(true);
          setTimeout(() => setOtpFailedAlert(false), 5000);
        }
        else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (err) {
      console.error('Error posting data:', err);
    } finally {
      setOtpSentLoader(false);
    }
  };

  // V E R I F Y    O T P
  const handleVerifyOtp = async () => {
    setOtpVerifyLoader(true);
    try {
      // Send POST request
      const response = await fetch(`${verifyOtp_URL}${forgotDetails.username}&otpEntered=${forgotDetails.verificationCode}`, {
        method: 'POST',
      });

      // Check if response is OK
      if (response.ok) {
        setIsOtpVerified(true);
      } else {
        if (response.status === 400) {
          setInvalidOtpAlert(true);
          setTimeout(() => setInvalidOtpAlert(false), 5000);
        }
        // if (response.status === 500) {
        //   setOtpFailedAlert(true);
        // }
        else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (err) {
      console.error('Error posting data:', err);
    } finally {
      setOtpVerifyLoader(false);
    }
  };

  // C H A N G E   P A S S W O R D
  const handlePassChange = async () => {
    setPassChangeLoader(true);
    if (forgotDetails.newPassword !== confirmPassword) {
      setConfirmPassAlert(true);
      setPassChangeLoader(false);
      return;
    }
    const changePassData = { username: forgotDetails.username, password: forgotDetails.newPassword }
    try {
      // Send POST request
      const response = await fetch(changePass_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(changePassData),
      });

      // Check if response is OK
      if (response.ok) {
        setIsPasswordChanged(true);
        console.log("Data: ", response)
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.error('Error posting data:', err);
    } finally {
      setPassChangeLoader(false);
    }
  };


  useEffect(() => {
    if (forgotDetails.newPassword === confirmPassword) {
      setConfirmPassAlert(false);
    }
  }, [forgotDetails, confirmPassword])


  return (
    <div className="forgotMain_cont">
      <WelcomeNavbar />
      {
        !isPasswordChanged &&
        <div className="loginCardMain_cont">
          <div className="loginWelcomeHead_cont">
            <h2>Forgot Password!</h2>
            <p>Please enter your details.</p>
          </div>

          <div className="loginForm_cont">
            {/* Email Input */}
            <div className="formInput_cont">
              <label htmlFor="email" className="welcomeFormLabel">
                Email <span className="asterisk">*</span>
              </label>
              <TextField
                type="email"
                className="welcomeFormInput"
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                name="username"
                id="username"
                placeholder="Enter your email"
                variant="outlined"
                sx={textFieldStyle}
                value={forgotDetails.username}
                onChange={handleChange}
                disabled={isOtpSent} // Disable once OTP is sent
              />
            </div>

            {/* OTP Input */}
            {isOtpSent && (
              <div className="otpSendSuccuss">
                <p className='otpSuccessPara'>An OTP has been sent to your email address.</p>
                <p className='otpSuccessPara'>Please check your inbox to proceed.</p>
                <div className="formInput_cont">
                  <label htmlFor="verificationCode" className="welcomeFormLabel">
                    Verification Code <span className="asterisk">*</span>
                  </label>
                  <TextField
                    type="text"
                    className="welcomeFormInput"
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }}
                    name="verificationCode"
                    id="verificationCode"
                    placeholder="Enter OTP"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={forgotDetails.verificationCode}
                    onChange={handleChange}
                    disabled={isOtpVerified} // Disable once OTP is verified
                  />
                </div>
              </div>
            )}

            {/* New Password and Confirm Password */}
            {isOtpVerified && (
              <div className="otpVerifiedSuccess">
                <p className='otpSuccessPara'>Your OTP has been verified.</p>
                <p className='otpSuccessPara'>Please create a new password to secure your account.</p>
                <div className="formInput_cont">
                  <label htmlFor="newPassword" className="welcomeFormLabel">
                    New Password <span className="asterisk">*</span>
                  </label>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    className="welcomeFormInput"
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }}
                    name="newPassword"
                    id="newPassword"
                    placeholder="Enter new password"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={forgotDetails.newPassword}
                    onChange={handleChange}
                    disabled={isPasswordChanged} // Disable once password is changed
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="formInput_cont">
                  <label htmlFor="confirmPassword" className="welcomeFormLabel">
                    Confirm Password <span className="asterisk">*</span>
                  </label>
                  <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="welcomeFormInput"
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={confirmPassword}
                    onChange={handleConfirmPassChange}
                    disabled={isPasswordChanged} // Disable once password is changed
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleConfirmPasswordVisibility}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

            )}

            {/* Buttons */}
            <div className="formInput_cont loginBtn_cont">
              {/* Send OTP Button */}
              {!isOtpSent && (
                <button className="btnFill loginBtn" onClick={handleSendOTP}>
                  Send OTP
                </button>
              )}
              {otpSentLoader && <div className="loader"></div>}

              {/* Verify OTP Button */}
              {isOtpSent && !isOtpVerified && (
                <button className="btnFill loginBtn" onClick={handleVerifyOtp}>
                  Verify OTP
                </button>
              )}
              {otpVerifyLoader && <div className="loader"></div>}

              {/* Change Password Button */}
              {isOtpVerified && (
                <button className="btnFill loginBtn" onClick={handlePassChange}>
                  Change Password
                </button>
              )}
              {passChangeLoader && <div className="loader"></div>}
            </div>

            {/* Signup Link */}
            <div className="loginSignupPara_cont">
              <p>
                Don't have an account yet?{' '}
                <Link to="/signup">
                  <strong>Sign Up</strong>
                </Link>
              </p>
            </div>
            <div className="loginAlert_cont">
              {userNotExistAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>User doesn't exist.</Alert>
              }
              {otpFailedAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>Failed to send OTP. Please try again.</Alert>
              }
              {invalidOtpAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>OTP is invalid or expired. Please try again.</Alert>
              }
              {confirmPassAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>Passwords didn't match.</Alert>
              }
              {passAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>Password must have atleast 8 characters.</Alert>
              }
            </div>
          </div>
        </div>
      }


      {
        isPasswordChanged &&
        <div className="passChangeSuccess">
          <div className="loginWelcomeHead_cont">
            <h2 className='passChangedHead'>Password Changed Successfully!</h2>
            {/* <p>Please enter your details.</p> */}
          </div>

          <div className="formInput_cont loginBtn_cont">
            <Link className="btnFill loginBtn" to="/signin">
              Back to Login
            </Link>
          </div>
        </div>
      }
    </div>
  );
};

export default ForgotPass;
