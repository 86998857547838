import React, { useState, useEffect, useContext } from 'react';
import './BuyBox.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../Revenue/TableStyles';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';

import { RoughStartDateContext } from '../../../Context/RoughStartDateContext';
import { RoughEndDateContext } from '../../../Context/RoughEndDateContext';
import { MarketplaceContext } from '../../../Context/MarketplaceContext';
import { SelectedMarketplaceContext } from '../../../Context/SelectedMarketplaceContext';

const BuyBox = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');

  const { marketplaceArray } = useContext(MarketplaceContext);
  const { selectedMarketplace, setSelectedMarketplace } = useContext(SelectedMarketplaceContext);
  const changeMarketplace_URL = `${baseUrl}/zumi/api/users/updateuser`;

  const { roughStartDateTime, setRoughStartDateTime } = useContext(RoughStartDateContext);
  const { roughEndDateTime, setRoughEndDateTime } = useContext(RoughEndDateContext);
  const [combinedDateTime, setCombinedDateTime] = useState(null);

  const [buyboxData, setBuyboxData] = useState([]);
  const [buyboxLoader, setBuyboxLoader] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchSkuQuery, setSearchSkuQuery] = useState('');

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "130px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "130px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "250px",
    },
    {
      name: t("buyboxPrice"),
      selector: row => row.buyboxPrice,
      width: "140px",
      sortable: true,
    },
    {
      name: t("currentPrice"),
      selector: row => row.amount,
      width: "140px",
      sortable: true,
    },
    {
      name: t("lowestPriceAmazon"),
      selector: row => row.lowestPrice,
      width: "140px",
      sortable: true,
    },
  ];

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDateTime) {
      const formattedStartDate = roughStartDateTime.format('MMM D YYYY');
      setStartDate(formattedStartDate);
    }
    if (roughEndDateTime) {
      const formattedEndDate = roughEndDateTime.format('MMM D YYYY');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDateTime, roughStartDateTime]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDateTime(startDate + " - " + endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (combinedDateTime) {
      console.log("Combined date: ", combinedDateTime)
    }
  }, [combinedDateTime]);

  const buyboxTable_URL = `${baseUrl}/zumi/api/salesbuyboxproduct?dateRange=null`;

  // F E T C H    B U Y B O X    T A B L E   D A T A
  useEffect(() => {
    setBuyboxLoader(true);
    if (combinedDateTime) {
      const fetchData = async () => {
        try {
          const response = await fetch(buyboxTable_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            setBuyboxLoader(false);
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setBuyboxLoader(false);
          setBuyboxData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setBuyboxLoader(false);
        }
      };
      fetchData();
    }
  }, [combinedDateTime, selectedMarketplace]);

  // H A N D L E   M A R K E T P L A C E    C H A N G E
  const handleChange = (event) => {
    setSelectedMarketplace(event.target.value);
    // Additional logic to handle selection change, e.g., update localStorage
    localStorage.setItem('activeMarketplaceId', event.target.value);
  };

  useEffect(() => {
    const storedMarketplace = localStorage.getItem('activeMarketplaceId');
    if (storedMarketplace != 5) {
      setSelectedMarketplace(5);
    }
  }, []);

  // F U N C T I O N    T O   P O S T   S E L E C T E D    M A R K E T P L A C E   O N    A P I
  useEffect(() => {
    const channelIdJson = JSON.stringify({ channelId: selectedMarketplace });
    const updateMarketplace = async () => {
      try {
        const response = await fetch(changeMarketplace_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: channelIdJson,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (err) {
        console.error('Error posting data:', err);
      }
    };

    if (selectedMarketplace === 5) {
      updateMarketplace();
    }
  }, [selectedMarketplace]);


  // H A N D L E   S E A R C H   B U Y B O X
  const handleSearchBuyBox = (e) => {
    setSearchSkuQuery(e.target.value);
  };

  // S E A R C H   B U Y B O X   D A T A 
  const filteredBuyBoxData = React.useMemo(() => {
    const filteredBuyBoxData = buyboxData.filter(item =>
      typeof item.sku === 'string' &&
      item.sku.toLowerCase().includes(searchSkuQuery.toLowerCase())
    );

    return filteredBuyBoxData;
  }, [buyboxData, searchSkuQuery]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <div className="locationSelectMain_cont">
          <Select
            style={{ width: "100%" }}
            size='small'
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedMarketplace || ''}
            onChange={handleChange}
          >
            {
              marketplaceArray.filter(item => item.channelId === 5).map((item, index) => (
                <MenuItem key={index} value={item.channelId}>{item.nickname}</MenuItem>
              ))
            }
          </Select>
        </div>
        <div className="buyBoxSearch_cont" >
          <TextField
            size='small'
            type='text'
            style={{ width: "100%" }}
            inputProps={{ autoComplete: 'off' }}
            id="outlined-basic"
            label="Search SKU"
            variant="outlined"
            name="max_price"
            value={searchSkuQuery}
            onChange={handleSearchBuyBox}
          />
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {buyboxLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={filteredBuyBoxData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default BuyBox;