import React, { useEffect, useState } from 'react';
import './Signup.css';
import SignUpSvg from '../../../assets/signupSvg.svg';

import { useNavigate, Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WelcomeNavbar from '../WelcomeNavbar/WelcomeNavbar';
import WelcomeFooter from '../WelcomeFooter/WelcomeFooter';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

const Signup = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [emptyUserPassAlert, setEmptyUserPassAlert] = useState(false);
  const [confirmPassAlert, setConfirmPassAlert] = useState(false);
  const [userExistAlert, setUserExistAlert] = useState(false);
  const [passAlert, setPassAlert] = useState(false);
  const [signupLoader, setSignupLoader] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [signupDetails, setSignUpDetails] = useState({
    emailId: "",
    password: "",
    contactNumber: "",
    contactPerson: "",
    companyName: ""
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSignUpDetails({ ...signupDetails, [name]: value });
    if (name === 'password' && value.length < 8) {
      setPassAlert(true);
    }
    if (name === 'password' && value.length >= 8) {
      setPassAlert(false);
    }
  }

  const handleConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  // H A N D L E   P A S S W O R D   V I S I B I L I T Y

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  // H A N D L E   C O N F I R M   P A S S W O R D   V I S I B I L I T Y

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  // F U N C T I O N   F O R   S I G N U P

  const handleSignup = async (e) => {
    e.preventDefault();
    const signup_URL = `${baseUrl}/zumi/api/users/register`;

    // Show loader
    setSignupLoader(true);
    setConfirmPassAlert(false);

    if (signupDetails.emailId === "" ||
      signupDetails.password === "" ||
      signupDetails.contactNumber === "" ||
      signupDetails.contactPerson === "" ||
      signupDetails.companyName === ""
    ) {
      setEmptyUserPassAlert(true);
      setConfirmPassAlert(false);
      setUserExistAlert(false);
      // Hide loader
      setSignupLoader(false);
      return;
    }

    if (signupDetails.password !== confirmPassword) {
      setConfirmPassAlert(true);
      setEmptyUserPassAlert(false);
      setUserExistAlert(false);
      // Hide loader
      setSignupLoader(false);
      return;
    }

    const signupDetailsJson = JSON.stringify(signupDetails);

    try {
      // Send POST request
      const response = await fetch(signup_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: signupDetailsJson,
      });

      // Check if response is not OK
      if (!response.ok) {
        if (response.status === 409) {
          const errorData = await response.json();
          if (errorData.result === 'fail' && errorData.message === 'User already exists') {
            setUserExistAlert(true);
          } else {
            // Handle other types of errors if needed
            console.error('Error:', errorData.message);
          }
        } else {
          // For other HTTP errors
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } else {
        // Successful response
        const data = await response.json();
        navigate('/signin');
        setUserExistAlert(false);
      }
    } catch (err) {
      console.error('Error posting data:', err);
    } finally {
      // Hide loader
      setSignupLoader(false);
    }
  };

  useEffect(() => {
    if (signupDetails.emailId &&
      signupDetails.companyName &&
      signupDetails.contactNumber &&
      signupDetails.contactPerson &&
      signupDetails.password &&
      confirmPassword) {
      setEmptyUserPassAlert(false);
    }
  }, [signupDetails, confirmPassword]);


  return (
    <div className="signupMain_cont">
      <WelcomeNavbar />
      <div className="signupCardMain_cont">
        <div className="signupWelcomeHead_cont">
          <h3>Create Account</h3>
          <p>Enter your details to start with Zumi.</p>
        </div>

        <div className="signupForm_cont">
          <div className="signupRow1">
            <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
              <label htmlFor="contactPerson" className="welcomeFormLabel">Your Name  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='text'
                name="contactPerson"
                id="outlined-basic"
                placeholder="Enter your name"
                variant="outlined"
                sx={textFieldStyle}
                value={signupDetails.contactPerson}
                onChange={handleChange}
              />
            </div>

            <div className="signupFormInput_cont">
              <label htmlFor="companyName" className="welcomeFormLabel">Company Name  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='text'
                name="companyName"
                id="outlined-basic"
                placeholder="Enter your company name"
                variant="outlined"
                sx={textFieldStyle}
                value={signupDetails.companyName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="signupRow2">
            <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
              <label htmlFor="emailId" className="welcomeFormLabel">Email  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='email'
                name="emailId"
                id="outlined-basic"
                placeholder="Enter your email"
                variant="outlined"
                sx={textFieldStyle}
                value={signupDetails.emailId}
                onChange={handleChange}
              />
            </div>

            <div className="signupFormInput_cont">
              <label htmlFor="contactNumber" className="welcomeFormLabel">Mobile  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type='number'
                name="contactNumber"
                id="outlined-basic"
                placeholder="Enter mobile number"
                variant="outlined"
                sx={textFieldStyle}
                value={signupDetails.contactNumber}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="signupRow3">
            <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
              <label htmlFor="password" className="welcomeFormLabel">Password  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Enter Password"
                variant="outlined"
                sx={textFieldStyle}
                value={signupDetails.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="signupFormInput_cont">
              <label htmlFor="confirmPassword" className="welcomeFormLabel">Confirm Password  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }}
                // type='password'
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                id="confirm-password"
                placeholder="Confirm Password"
                variant="outlined"
                disabled={!signupDetails.password}
                sx={textFieldStyle}
                value={confirmPassword}
                onChange={handleConfirmPassChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="formInput_cont signupBtn_cont">
            <button className='btnFill loginBtn' onClick={handleSignup}>Signup</button>
            {signupLoader && <div className="loader"></div>}
          </div>
          <div className="loginSignupPara_cont">
            <p>Already have an account? <Link to="/signin"><strong>Sign In</strong></Link></p>
          </div>
          <div className="loginAlert_cont">
            {emptyUserPassAlert &&
              <Alert severity="warning" className='loginAlert' id='incorrectAlert'>Please fill mandatory fields.</Alert>
            }
            {confirmPassAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>Passwords didn't match.</Alert>
            }
            {/* {loginSuccessAlert &&
                     <Alert severity="success" className='loginAlert' id='incorrectAlert'>Incorrect username or password.</Alert>
                   } */}
            {userExistAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>User already exists.</Alert>
            }
            {passAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>Password must have atleast 8 characters.</Alert>
            }
          </div>
        </div>
      </div>
      <WelcomeFooter />

    </div>
  )
}

export default Signup;