import React, { useState, useEffect, useContext } from 'react';
import './CreateSms.css';

import { useTranslation } from 'react-i18next';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import InfoIcon from '@mui/icons-material/Info';
import { SegmentContext } from '../../../Context/SegmentContext';

const CreateSms = ({ cli, smsc, updateSmsData, showAlert, closeModal }) => {
  const { t } = useTranslation();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const { segmentArray } = useContext(SegmentContext);

  const createSms_URL = `${baseUrl}/airkommWebPortal/api/createSMSCampaign`;
  const createTask_URL = `${baseUrl}/airkommWebPortal/api/createSMSTask`;

  const [loading, setloading] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [roughStartDate, setRoughStartDate] = useState(null);
  const [roughEndDate, setRoughEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [roughStartDateTime, setRoughStartDateTime] = useState(null);
  const [roughEndDateTime, setRoughEndDateTime] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isCustomInfoHovered, setIsCustomInfoHovered] = useState(false);
  const [isSmsInfoHovered, setIsSmsInfoHovered] = useState(false);

  const [updateSmsCamp, setUpdateSmsCamp] = useState(false);

  const [createSmsData1, setCreateSmsData1] = useState({
    campaignName: "",
    scheduleStartDate: null,
    scheduleEndDate: null,
    isTextCustomization: "0",
    smsText: ""
  })

  const [createSmsData2, setCreateSmsData2] = useState({
    campaignId: "",
    scheduleStartDate: null,
    scheduleEndDate: null,
    taskName: "",
    modeId: "",
    cli: "",
    smsc: "",
  })


  const steps = [
    'Campaign Info',
    'Task Info',
  ];

  const handleStartChange = (date) => {
    setRoughStartDate(date);
  }

  const handleEndChange = (date) => {
    setRoughEndDate(date);
  }

  const handleStartDateTimeChange = (date) => {
    setRoughStartDateTime(date)
  }

  const handleEndDateTimeChange = (date) => {
    setRoughEndDateTime(date);
  }

  const handleSmsData1Change = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Apply transformations if the field is campaignName
    if (name === 'campaignName') {
      // Replace hyphens and spaces with underscores
      value = value.replace(/[-\s]+/g, '_');
      // Convert to lowercase
      value = value.toLowerCase();
    }
    setCreateSmsData1(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSmsData2Change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateSmsData2(prevState => ({ ...prevState, [name]: value }));
  }

  // H A N D L I N G   F I L E   S E L E C T I O N 
  const selectedSmsFileHandler = (event) => {
    const file = event.target.files[0]
    // setSelectedFile(file);

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension

      // Check if the file extension is valid
      if (['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        setFileError(''); // Clear error message
        setSelectedFile(file);
        // Proceed with processing the file
      } else {
        setFileError(t("invalidFileError"));
        event.target.value = ''; // Clear the input
      }
    }
  }

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDate) {
      // const formattedStartDate = roughStartDate.format('D MMMM YYYY');
      const formattedStartDate = roughStartDate.format('YYYY-MM-DD');
      setStartDate(formattedStartDate);
    }
    if (roughEndDate) {
      // const formattedEndDate = roughEndDate.format('D MMMM YYYY');
      const formattedEndDate = roughEndDate.format('YYYY-MM-DD');
      setEndDate(formattedEndDate);
    }
  }, [roughEndDate, roughStartDate]);

  // F U N T I O N   T O  S E T   R O U G H   D A T E T I M E   I N T O   F O R M A T T E D   D A T E T I M E
  useEffect(() => {
    if (roughStartDateTime) {
      // const formattedStartDateTime = roughStartDateTime.format('D MMMM YYYY h:mm a');
      const formattedStartDateTime = roughStartDateTime.format('YYYY-MM-DD HH:mm:ss');
      setStartDateTime(formattedStartDateTime);
    }
    if (roughEndDateTime) {
      // const formattedEndDateTime = roughEndDateTime.format('D MMMM YYYY h:mm a');
      const formattedEndDateTime = roughEndDateTime.format('YYYY-MM-DD HH:mm:ss');
      setEndDateTime(formattedEndDateTime);
    }
  }, [roughEndDateTime, roughStartDateTime]);


  // S E T   D A T E S    F O R    S M S    D A T A 1
  useEffect(() => {
    setCreateSmsData1(prevState => ({ ...prevState, scheduleStartDate: startDate, scheduleEndDate: endDate }));
  }, [startDate, endDate]);


  // S E T   D A T E S    F O R    S M S    D A T A 2
  useEffect(() => {
    setCreateSmsData2(prevState => ({ ...prevState, scheduleStartDate: startDateTime, scheduleEndDate: endDateTime }));
  }, [startDateTime, endDateTime]);


  // H A N A L E    N E X T
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  }

  //  H A N D L E    B A C K
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }


  //  S A V E    S M S   C A M P A I G N
  const saveSmsCampaign = async () => {
    setloading(true);
    const smsDataJson = JSON.stringify(createSmsData1)
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('campaignDetails', smsDataJson);

    try {
      const response = await fetch(createSms_URL, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData.message);
        if (response.status === 302) {
          const location = response.headers.get('Location');
          console.log('Redirected to:', location);
          // Handle the redirect manually if needed
        }
        setloading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else {
        const data = await response.json();
        if (response.status === 302) {
          const location = response.headers.get('Location');
          console.log('Redirected to:', location);
        }
        if (data.id) {
          createTask(data.id);
        }
      }
    } catch (err) {
      console.error('Error posting data:', err);
    }
  };

  //  C R E A T E    T A S K
  const createTask = async (campaignId) => {
    setloading(true);
    const smsDataJson = JSON.stringify({ ...createSmsData2, campaignId: `${campaignId}`, cli: `${createSmsData2.cli}`, smsc: `${createSmsData2.smsc}`, segmentId: `${createSmsData2.segmentId}` });
    const formData = new FormData();
    formData.append('userId', userId);
    if (selectedFile) {
      formData.append('msisdnFile ', selectedFile);
    }
    formData.append('taskDetails', smsDataJson);
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(key, value.name);
      } else {
        console.log(key, value);
      }
    }
    try {
      const response = await fetch(createTask_URL, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData.message);
        setloading(false);
        showAlert("SMS Campaign is created but task not created.", '#FFE4C0', '#FD5D5D');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else {
        const data = await response.json();
        setUpdateSmsCamp(!updateSmsCamp);
        updateSmsData(updateSmsCamp);
        setloading(false);
        showAlert("Campaign and Task created successfully.", '#d4f4e6', '#77d98b');
      }
    } catch (err) {
      console.error('Error posting data:', err);
      setloading(false);
      closeModal();
      showAlert("SMS Campaign is created but task not created.", '#FFE4C0', '#FD5D5D');
    }
  }

  const isNextDisabled = !createSmsData1.campaignName || !createSmsData1.scheduleStartDate || !createSmsData1.scheduleEndDate || !createSmsData1.smsText;
  const isSaveDisabld = !createSmsData2.scheduleStartDate || !createSmsData2.scheduleEndDate || !createSmsData2.cli || !createSmsData2.taskName || !createSmsData2.smsc || !createSmsData2.modeId;

  return (
    <div className='createSmsMain_cont' style={{ width: "100%", height: "100%" }}>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('smsCampaignCreation')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div className="createSmsMain">

        <div className="createSmsStepsCont">
          <Stepper className='marketStepper' activeStep={activeStep} alternativeLabel >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === 1 && (
          <div className="createSmsCont1">
            {/* R O W   1 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
                <label htmlFor="firstName" className="payLabel">{t('campaignName')}  <span className="asterisk">*</span></label>
                <TextField
                  autoComplete='off'
                  className='smsInputField'
                  InputLabelProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  name="campaignName"
                  id="outlined-basic"
                  placeholder={t('campaignName')}
                  value={createSmsData1.campaignName}
                  onChange={handleSmsData1Change}
                />
              </div>
              <div className="createSmsInput_cont">
                <label htmlFor="lastName" className="payLabel">{t('startDate')} & {t('endDate')}  <span className="asterisk">*</span></label>
                <div className="" style={{ width: "100%", display: 'flex', gap: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="scheduleStartDate"
                      value={roughStartDate}
                      onChange={handleStartChange}
                      minDate={dayjs()} // Disable past dates
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!createSmsData1.scheduleStartDate}
                      name="scheduleEndDate"
                      value={roughEndDate}
                      onChange={handleEndChange}
                      minDate={dayjs()} // Disable past dates
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            {/* R O W   2 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
                <label htmlFor="textCustimization" className="payLabel" style={{ display: "flex", alignItems: "center" }}>{t('smsTextCustom')}  <span className="asterisk">*</span>
                  <div className="info-container">
                    <button
                      className="info-button"
                      onMouseEnter={() => setIsCustomInfoHovered(true)}
                      onMouseLeave={() => setIsCustomInfoHovered(false)}
                    >
                      <InfoIcon style={{ height: "18px" }}></InfoIcon>
                    </button>
                    {isCustomInfoHovered && (
                      <div className="tooltip">
                        Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info
                      </div>
                    )}
                  </div>
                </label>
                <Select
                  autoComplete='off'
                  className='filterSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="isTextCustomization"
                  value={createSmsData1.isTextCustomization}
                  onChange={handleSmsData1Change}
                  displayEmpty
                  renderValue={(selected) => {

                    if (selected === "1") {
                      return "Yes"
                    }
                    if (selected === "0") {
                      return "No"
                    }
                  }}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </div>
              <div className="createSmsInput_cont" style={{ marginRight: "" }}>
                {/* Empty container */}
              </div>
            </div>

            {/* R O W   3 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont">
                <label htmlFor="firstName" className="payLabel" style={{ display: "flex", alignItems: "center" }}>{t('smsText')}  <span className="asterisk">*</span>

                  <div className="info-container">
                    <button
                      className="info-button"
                      onMouseEnter={() => setIsSmsInfoHovered(true)}
                      onMouseLeave={() => setIsSmsInfoHovered(false)}
                    >
                      <InfoIcon style={{ height: "18px" }}></InfoIcon>
                    </button>
                    {isSmsInfoHovered && (
                      <div className="tooltip">
                        Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info Customization info
                      </div>
                    )}
                  </div>
                </label>
                <TextField
                  className='smsInputField'
                  InputLabelProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  autoComplete='off'
                  multiline
                  rows={3}
                  name="smsText"
                  id="outlined-basic"
                  placeholder="Dear {param1}, ..."
                  value={createSmsData1.smsText}
                  onChange={handleSmsData1Change}
                />
              </div>
            </div>

            <div className="createSmsNextCont">
              <button
                className='btnFill'
                onClick={handleNext}
                disabled={isNextDisabled}
                style={{
                  border: isNextDisabled ? "1px solid #EEEEEE" : "#17c1e8",
                  backgroundColor: isNextDisabled ? '#EEEEEE' : '#17c1e8',
                  color: isNextDisabled ? 'gray' : '#fff',
                }}
              >{t('next')}
              </button>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div className="createSmsCont2">
            {/* R O W   1 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont " style={{ marginRight: "10px" }}>
                <label htmlFor="startDateTime" className="payLabel">{t('startDateTime')}  <span className="asterisk">*</span></label>
                <div className="" style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        name="scheduleStartDate"
                        minDate={dayjs()} // Disable past dates
                        value={roughStartDateTime}
                        onChange={handleStartDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="createSmsInput_cont">
                <label htmlFor="endDateTime" className="payLabel">{t('endDateTime')}  <span className="asterisk">*</span></label>
                <div className="" style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        disabled={!createSmsData2.scheduleStartDate}
                        name="scheduleEndDate"
                        minDate={dayjs()} // Disable past dates
                        value={roughEndDateTime}
                        onChange={handleEndDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            {/* R O W   2 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
                <label htmlFor="taskName" className="payLabel">{t('taskName')}  <span className="asterisk">*</span></label>
                <TextField
                  autoComplete='off'
                  className='smsInputField'
                  InputLabelProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  name="taskName"
                  id="outlined-basic"
                  placeholder="Enter a task name"
                  value={createSmsData2.taskName}
                  onChange={handleSmsData2Change}
                />
              </div>
              <div className="createSmsInput_cont">
                <label htmlFor="smsFile" className="payLabel">{t('uploadFile')} or {t('selectSegment')}  <span className="asterisk">*</span></label>
                <div className="demoInputMUI" style={{ marginTop: "5px" }}>
                  <input
                    type='file'
                    accept=".csv, .xlsx, .xls"
                    className='uploadFileBtn smsInputField'
                    name="smsContactFile"
                    id="uploadBtn"
                    onChange={selectedSmsFileHandler}
                    disabled={createSmsData2.segmentId}
                    style={{
                      border: createSmsData2.segmentId ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: createSmsData2.segmentId ? '#EEEEEE' : '#17c1e8',
                      color: createSmsData2.segmentId ? 'gray' : '#fff',
                    }}
                  />
                  <label
                    htmlFor="uploadBtn"
                    className='uploadSmsFileLabel'
                    style={{
                      border: createSmsData2.segmentId ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: createSmsData2.segmentId ? '#EEEEEE' : '#17c1e8',
                      color: createSmsData2.segmentId ? 'gray' : '#fff',
                    }}
                  >
                    Choose File
                  </label>
                  <div className="fileName_cont" id="fileName_cont">
                    {selectedFile ? (
                      <p><span className='fileName' style={{ fontSize: "12px" }}>{selectedFile.name}</span> </p>
                    ) : <p className='nofile' style={{ fontSize: "12px" }}>{t("noFileSelected")}</p>}
                  </div>
                  <div className="orContainer centerElm">or</div>
                  <Select
                    disabled={selectedFile}
                    className="selectSegmentSms"
                    name='segmentId'
                    size='small'
                    value={createSmsData2.segmentId}
                    onChange={handleSmsData2Change}
                    displayEmpty
                    renderValue={(selected) => {
                      const selectedSegment = segmentArray.find(item => item.segmentId === selected);
                      return selectedSegment ? selectedSegment.segmentId : <em>{t("selectSegmentPlaceholder")}</em>;
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 230, // Set your desired height
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    {
                      segmentArray.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.segmentId}>{item.segmentName}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </div>

              </div>
            </div>


            {/* R O W   3 */}
            <div className="createSmsRow">
              <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
                <label htmlFor="cli" className="payLabel">{t('cli')}  <span className="asterisk">*</span></label>
                <Select
                  className='filterSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="cli"
                  value={createSmsData2.cli}
                  onChange={handleSmsData2Change}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select CLI</em>; // handle case when nothing is selected
                    }
                    return selected;
                  }}
                >
                  {cli.map((item) => {
                    return <MenuItem key={item.cliId} value={item.cliDetails}>{item.cliDetails}</MenuItem>
                  })}
                </Select>
              </div>
              <div className="createSmsInput_cont">
                <label htmlFor="smsc" className="payLabel">{t('smsc')}  <span className="asterisk">*</span></label>
                <Select
                  className='filterSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="smsc"
                  value={createSmsData2.smsc}
                  onChange={handleSmsData2Change}
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>Select SMSC</em>;
                  //   }
                  //   return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                  // }}

                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select SMSC</em>;
                    }

                    const selectedSmsc = smsc.find(item => item.smscId === selected);
                    return selectedSmsc ? selectedSmsc.displayName : <em>Select SMSC</em>;
                  }}
                >
                  {smsc.map((item) => {
                    return <MenuItem key={item.smscId} value={item.smscId}>{item.displayName}</MenuItem>
                  })}
                </Select>
              </div>
            </div>
            <div className="createSmsRow">
              <div className="createSmsInput_cont" style={{ marginRight: "10px" }}>
                <label htmlFor="mode" className="payLabel">{t('modeType')}  <span className="asterisk">*</span></label>
                <Select
                  className='filterSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="modeId"
                  value={createSmsData2.modeId}
                  onChange={handleSmsData2Change}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0)
                      return <em>Select a mode type</em>;
                    if (selected === "1")
                      return "Promotional"
                    if (selected === "2")
                      return "Transational"
                  }}
                >
                  <MenuItem value="1">Promotional</MenuItem>
                  <MenuItem value="2">Transational</MenuItem>
                </Select>
              </div>

              <div className="createSmsInput_cont">
                {/* Empty */}
              </div>
            </div>


            <div className="createSmsSaveCont">
              <button className='btnFill' onClick={handleBack}>{t('back')}</button>
              <button
                className='btnFill'
                onClick={saveSmsCampaign}
                disabled={isSaveDisabld}
                style={{
                  border: isSaveDisabld ? "1px solid #EEEEEE" : "#17c1e8",
                  backgroundColor: isSaveDisabld ? '#EEEEEE' : '#17c1e8',
                  color: isSaveDisabld ? 'gray' : '#fff',
                }}
              >
                {loading ? <div className="loaderWhite"></div> : "Save"}


              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default CreateSms;