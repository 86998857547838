import React, { useEffect, useState } from 'react';
import './ShopifyAuthSuccess.css';
import check from '../../../../assets/greenCheck.jpg';
import cross from '../../../../assets/redCross.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ShopifyAuthSuccess = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [authStatus, setAuthStatus] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shopifyUserAuth_URL = `${baseUrl}/zumi/api/shopify-auth-code`;
    const shopifyAuthCode = params.get('code');
    const marketRegion = localStorage.getItem("marketRegion");
    const shopifyAccountName = localStorage.getItem("shopifyAccountName");
    const shopifySellerEmail = localStorage.getItem("shopifySellerEmail");
    const shopifyStoreName = localStorage.getItem("shopifyStoreName");

    if (shopifyAccountName && shopifyAuthCode && shopifySellerEmail && marketRegion && shopifyStoreName) {
      const authInfo = {
        accountName: shopifyAccountName,
        code: shopifyAuthCode,
        email: shopifySellerEmail,
        region: marketRegion,
        storeName: shopifyStoreName,
      };

      setLoader(true);

      const authenticateUser = async () => {
        try {
          const response = await fetch(shopifyUserAuth_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(authInfo),
          });

          if (response.ok) {
            console.log('Shopify authentication successful.');
            setAuthStatus(true);
          } else {
            console.error('Failed to authorize. Status:', response.status);
            setAuthStatus(false);
          }
        } catch (error) {
          console.error('Error:', error);
          setAuthStatus(false);
        } finally {
          setLoader(false);
          localStorage.removeItem("marketRegion");
          localStorage.removeItem("shopifyAccountName");
          localStorage.removeItem("shopifySellerEmail");
          localStorage.removeItem("shopifyStoreName");
        }
      };
      authenticateUser();
    }
  }, []);


  return (
    <div className='shopifyAuthMain_cont'>
      {loader ? (
        <div className="loadingMessage">Loading...</div>
      ) : authStatus === true ? (
        <div className="shopifyAuthSub_cont1">
          <div className="shopifyAuthImg_cont centerElm show">
            <img src={check} alt="Success" />
          </div>
          <p>Shopify Authorization Successful</p>
          <Link className='btnNotFill shopifyAuthBtn' to="/home/view-marketplace">View Marketplace</Link>
        </div>
      ) : authStatus === false ? (
        <div className="shopifyAuthSub_cont2">
          <div className="shopifyAuthImg_cont centerElm show">
            <img src={cross} alt="Failure" />
          </div>
          <p>Shopify Authorization Failed</p>
          <Link className='btnNotFill shopifyAuthBtn' to="/home/new-marketplace">Try Again</Link>
        </div>
      ) : null}
    </div>
  );
};

export default ShopifyAuthSuccess;