import React, { useContext } from 'react';
import './Profile.css';
import UserProfile from '../../../src/assets/user-profile.jpg';
import { UserDetailsContext } from '../../Context/UserDetailsContext';

const Profile = () => {
  const { userDetails } = useContext(UserDetailsContext);

  return (
    <div className='profileMain_cont'>
      <div className="profileSub_cont">
        <div className="profileHeader_cont">
          <div className="profilePhoto_cont">
            <div className="profilePhoto">
              <img className='userProfilePhoto' src={UserProfile} alt="" />
            </div>
          </div>
          <div className="profileName_cont">
            <div className="editProfileBtn_cont">
              <button className='btnNotFill'>Edit Profile</button>
            </div>
            <p className='profileUserName'>{userDetails.contactPerson}</p>
            <p className='profileUserDesc'>More Details about {userDetails.contactPerson}.</p>
          </div>
        </div>
        <hr className='profileHr' />
        <div className="profileData_cont">
          <div className="profileDataHeader_cont">
            <p>Personal Info</p>
          </div>
          <div className="profileDataSub_cont">
            <div className="profileData1">
              <p className='profileDataParaHeader'>Full Name</p>
              <p>{userDetails.contactPerson}</p>
            </div>
            <div className="profileData2">
              <p className='profileDataParaHeader'>Email</p>
              <p>{userDetails.username}</p>
            </div>
            <div className="profileData3">
              <p className='profileDataParaHeader'>Phone</p>
              <p>{userDetails.contactNumber}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;