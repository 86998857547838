import React from 'react';
import './LoginRequired.css';

import { useNavigate, Link } from 'react-router-dom';

const LoginRequired = ({ closeModal }) => {

  const navigate = useNavigate();

  const hanldeLoginRequired = () => {
    localStorage.setItem("fromTodaysDeals", true);
    navigate('/signin');
  }

  return (
    <div className='loginReqMain'>
      <div className="loginReqClose_cont">
        <div className="loginReqHead_cont">
          <h3>Login Required</h3>
        </div>
        <div className='loginReqClose centerElm' role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="loginReqContent_cont">
        <p>Access to this feature requires you to be logged in.</p>
        <div className="goToLoginBtn_cont">
          <Link className="goToLoginBtn" onClick={hanldeLoginRequired}>Go to Login Page</Link>
        </div>
      </div>

    </div>
  )
}

export default LoginRequired;