import React, { useContext, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

import { ProfitChartContext } from '../../../../Context/ProfitChartContext';
const ProfitChart = () => {
  const { profitChartArray } = useContext(ProfitChartContext);

  const profitData = {
    data: {
      labels: profitChartArray.map((data) => data.date || "Label"),
      datasets: [
        {
          label: "Percentage",
          data: profitChartArray.map((data) => data.percentage),
          type: 'line',
          borderColor: 'rgb(14, 113, 195)',
          yAxisID: 'y2',
          fill: false,
          tension: 0.3 // Line tension
        },
        {
          label: 'Value',
          data: profitChartArray.map((data) => data.value),
          type: 'bar',
          backgroundColor: '#D1E9F6',
          borderColor: 'black',
          borderWidth: 0,
          borderRadius: 20, // Rounded corners
          // barThickness: 60, // Custom bar thickness
          yAxisID: 'y1',
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y2: {
          id: 'y2',
          position: 'right',
          ticks: {
            stepSize: 5,
          },
          grid: {
            drawOnChartArea: false
          }
        },
        x: {
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      elements: {
        line: {
          tension: 0.3
        }
      }
    }
  }

  return (
    <Line data={profitData.data} options={profitData.options} />
  )
}

export default ProfitChart;