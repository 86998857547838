import React, { useState, useEffect, useContext } from 'react';
import './Catalogs.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import { utils, writeFile } from 'xlsx'; // Correct import for XLSX


import { SelectedMarketplaceContext } from '../../../Context/SelectedMarketplaceContext';
const Catalogs = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;


  const authToken = localStorage.getItem('authToken');
  const catalogs_URL = `${baseUrl}/zumi/api/existingcatalog`;
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);


  const [catalogsLoader, setCatalogsLoader] = useState(true);
  const [catalogsData, setCatalogsData] = useState([]);

  const [searchSkuQuery, setSearchSkuQuery] = useState('');

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "160px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "160px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "320px",
    },
    {
      name: t("quantity"),
      selector: row => row.quantity,
      width: "120px",
      sortable: true,
    },
    {
      name: t("price"),
      selector: row => row.amount,
      width: "90px",
      sortable: true,
    },


  ];

  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        setCatalogsLoader(true);
        try {
          const response = await fetch(catalogs_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setCatalogsData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setCatalogsLoader(false);
        }

      };
      fetchData();
    }
  }, [selectedMarketplace]);


  // H A N D L E   S E A R C H   C A T A L O G S   D A T A
  const handleSearchCatalogs = (e) => {
    setSearchSkuQuery(e.target.value);
  };

  // S E A R C H   C A T A L O G S   D A T A 
  const filteredCatalogsData = React.useMemo(() => {
    const filteredCatalogsData = catalogsData.filter(item =>
      typeof item.sku === 'string' &&
      item.sku.toLowerCase().includes(searchSkuQuery.toLowerCase())
    );

    return filteredCatalogsData;
  }, [catalogsData, searchSkuQuery]);

  // D O W N L O A D   C A T A L O G S   F I L E
  const downloadCatalogs = () => {
    const ws = utils.json_to_sheet(filteredCatalogsData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Catalogs');
    writeFile(wb, 'Catalogs.xlsx');
  };

  // Handle XLS download with selected columns
  // const handleDownloadCatalogs = () => {
  //   // Define the columns you want to include in the Excel file
  //   const selectedColumns = ['title', 'sku', 'asin', 'itemName', 'quantity', 'amount']; // Customize this list as needed

  //   // Map over filtered data and select only the desired columns
  //   const filteredDataForDownload = filteredCatalogsData.map(item => {
  //     let selectedItem = {};
  //     selectedColumns.forEach(column => {
  //       selectedItem[column] = item[column];
  //     });
  //     return selectedItem;
  //   });

  //   // Convert the filtered data to a worksheet
  //   const ws = utils.json_to_sheet(filteredDataForDownload);
  //   const wb = utils.book_new(); // Create a new workbook
  //   utils.book_append_sheet(wb, ws, 'Catalogs'); // Append the sheet to the workbook
  //   writeFile(wb, 'Catalogs.xlsx'); // Save as an Excel file
  // };

  // Handle XLS download with selected columns and renaming column headers
  const handleDownloadCatalogs = () => {
    // Define the columns you want to include in the Excel file and the new column names
    const selectedColumns = [
      { original: 'title', renamed: 'Marketplace' },
      { original: 'sku', renamed: 'SKU' },
      { original: 'asin', renamed: 'ASIN' },
      { original: 'itemName', renamed: 'Product Name' },
      { original: 'quantity', renamed: 'Quantity' },
      { original: 'amount', renamed: 'Price' },
    ];

    // Map over filtered data and create a new object with the renamed properties
    const filteredDataForDownload = filteredCatalogsData.map(item => {
      let selectedItem = {};
      selectedColumns.forEach(col => {
        selectedItem[col.renamed] = item[col.original];
      });
      return selectedItem;
    });

    // Convert the filtered data to a worksheet
    const ws = utils.json_to_sheet(filteredDataForDownload);
    const wb = utils.book_new(); // Create a new workbook
    utils.book_append_sheet(wb, ws, 'Catalogs'); // Append the sheet to the workbook
    writeFile(wb, 'Catalogs.xlsx'); // Save as an Excel file
  };



  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />

        <div className="buyBoxSearch_cont" >
          <TextField
            size="small"
            type='text'
            style={{ width: "100%" }}
            inputProps={{ autoComplete: 'off' }}
            id="outlined-basic"
            label="Search SKU"
            variant="outlined"
            name="max_price"
            value={searchSkuQuery}
            onChange={handleSearchCatalogs}
          />
        </div>
      </div>

      <div className="revenueTableMain_cont">

        <div className='revenueTable_cont'>
          <div className="downloadCatalogs_cont">
            <button className='downloadCatalogsBtn' onClick={handleDownloadCatalogs}>Download Catalogs</button>
          </div>
          {catalogsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={filteredCatalogsData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default Catalogs;